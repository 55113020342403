import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import Label from "@base/Label";
import Card from "@base/Card";
import store from "@store/index";
import { loading, ready, showPopup } from "@store/actions/global";
import NFTService from "@core/services/NFTService";
import { NTFsNavigatorScreens } from "@navigation/NFTsNavigator";
import BotCard from "@base/BotCard";
import { NTFNavigatorScreens } from "@navigation/NFTNavigator";

interface Props {
    navigation: NavigationType;
    route: any;
}

interface State {
    tokenId: number;
    contractAddress: string;
    fee: string;
    sendingTo: string;
    sendingFrom: string;
    skeleton: any;
}

const { t } = i18n;

export class _ApproveNFTScreen extends Component<Props, State> {
    private currency: Currency;
    constructor(props: Props) {
        super(props);
        this.onApprove = this.onApprove.bind(this);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.route.params?.currency);

        this.state = {
            tokenId: parseInt(this.props.route.params?.tokenId) || 0,
            contractAddress: this.props.route.params?.contractAddress || "",
            sendingTo: "",
            sendingFrom: "",
            fee: "",
            skeleton: "",
        };
    }

    async componentDidMount() {
        const resp = await NFTService.getInstance().requestApproveNFT(
            this.currency,
            this.state.tokenId,
            this.state.contractAddress,
        );

        const skeleton = this.currency.getImplementation().parseSkeleton(resp);
        this.setState({
            skeleton: resp,
            sendingTo: skeleton.sendingTo,
            sendingFrom: skeleton.sendingFrom,
            fee: skeleton.fee,
        });
    }

    async onApprove() {
        store.dispatch(loading());
        try {
            const res = await this.currency.sendTransaction(this.state.skeleton);
            store.dispatch(showPopup({ type: "SUCCESS" }));
            this.props.navigation.navigate(NTFNavigatorScreens.OfferForSale.routeName);
        } catch (e) {
            console.warn(e);
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
        store.dispatch(ready());
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("approve_NFT")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between", paddingVertical: 20 }}>
                    <View>
                        <BotCard message={t("info_card_approve")}></BotCard>

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("sending_from")}
                            </RegularText>
                            <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                {this.state.sendingFrom}
                            </BoldText>
                        </Card>
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("sending_to")}
                            </RegularText>
                            <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                {this.state.sendingTo}
                            </BoldText>
                        </Card>

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("fee")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.state.fee} {this.currency.getPName()}
                            </BoldText>
                            <Label
                                text={`$ ${this.currency.toFiat(Number(this.state.fee))}`}
                                align="center"
                                fontSize={12}
                            />
                        </Card>
                    </View>
                    <InlineButton onPress={this.onApprove} title={t("confirm_transaction")} />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

const ApproveNFTScreen = connect(mapStateToProps, mapDispatchToProps)(_ApproveNFTScreen);

export default ApproveNFTScreen;

const styles = StyleSheet.create({
    card: {},
    swipContainer: {
        flex: 1,
        height: 58,
    },
});
