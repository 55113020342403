const nunitoBold = require("./Exo-Bold.ttf")
const nunitoLight = require("./Exo-ExtraLight.ttf")
const nunitoRegular = require("./Exo-Regular.ttf")
const nunitoSemiBold = require("./Exo-SemiBold.ttf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
