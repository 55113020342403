import React, { Component } from "react";
import { View, Text, StyleSheet, FlatList, RefreshControl, ScrollView } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";

import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import SeedHistoryList from "@components/history/SeedHistoryList";


interface Props {
    navigation: any;
}

interface State {}

const { t } = i18n;

export default class HistorySeedScreen extends Component<Props, State> {
    focusListener: any;

    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("history")} type={HeaderType.Light} {...this.props} />
                <ScrollView>
                    <Container style={{ flex: 1 }}>
                        <SeedHistoryList contentContainerStyle={{ flex: 1 }} navigation={this.props.navigation} />
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}
