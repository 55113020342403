import { PathNode } from "@core/utils/PathNode";
import TransactionType from "@custom-types/TransactionType";
import Currency from "./Currency";
import BigNumber from "bignumber.js";
import moment from "moment";

export interface ISkeleton {
    amount: number;
    sendingTo: string;
    sendingFrom: string;
    fee: any;
    swapAmount?: string | number;
}


export interface ITransaction {
    type: number;
    to: string;
    amount: any;
    from: string;
    id: string;
    confirmations: number;
    fee: any;
    date: string;
}

export default abstract class CurrencyImplementation {
    protected currency: Currency;
    constructor(currency: Currency) {
        this.currency = currency;
    }

    getCurrency() {
        return this.currency;
    }

    getFormats(): Array<string> {
        return [];
    }

    getKeys(addressNode: PathNode): { publicKey: string; privateKey: string } {
        return {
            publicKey: "",
            privateKey: "",
        };
    }

    signMessage(addressNode: PathNode, hexMessage: string) {
        return null;
    }

    signTypedData(addressNode: PathNode, JsonData: string) {
        return null;
    }

    // parseTransaction(tx): ITransaction {
    //     return {
    //         digitalCurrencyId: tx.digitalCurrencyId,
    //         txId: tx?.txId,
    //         from: tx?.from,
    //         to: tx?.to,
    //         date: this.parseDate(tx.date),
    //         fee: this.currency.getUnderlyingCurrency().format(new BigNumber(tx?.fee).toNumber()),
    //         direction: tx?.direction?.toLowerCase(),
    //         value: this.currency.format(
    //             new BigNumber(this.currency.getKind() == "NATIVE" ? tx?.value : tx.transferValue).toNumber(),
    //         ),
    //         confirmations: new BigNumber(tx?.confirmations).toNumber() || 0,
    //         block: new BigNumber(tx?.block).toNumber() || 0,
    //     };
    // }

    // parseDate(time) {
    //     const m = moment(time);
    //     return `${m.format("LL")} (${m.format("LT")})`;
    // }

    abstract generateAddress(node: PathNode, options: { chainId?: number; owner?: boolean; format?: string }): string;
    abstract signTransaction(addressNode: any, skeleton: any): Promise<any>;
    abstract parseTransaction(tx: Object): ITransaction;
    abstract parseDate(time: number): string;
    abstract parseSkeleton(skeleton: any): ISkeleton;
    abstract isValidAddress(address: string): boolean;
}
