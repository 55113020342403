import BoldText from "@base/BoldText";
import Container from "@base/Container";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import { Header } from "@components/header/Header";
import SeedHistoryList from "@components/history/SeedHistoryList";
import Icon from "@components/icons";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

interface Props {
    navigation: NavigationType;
}

interface State {}

const { t } = i18n;

export default class IncognitoScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressImport = this.onPressImport.bind(this);
        this.onPressCreate = this.onPressCreate.bind(this);
    }

    componentDidMount() {}

    onPressImport() {
        this.props.navigation.navigate(LoginNavigatorScreens.ImportSeed.routeName);
    }

    onPressCreate() {
        this.props.navigation.navigate(LoginNavigatorScreens.NewSeed.routeName);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header type={HeaderType.Light} {...this.props} />
                <ScrollView style={{ height: 200 }}>
                    <Container style={styles.container}>
                        <View style={styles.wrapper}>
                            <BoldText fontSize={22} style={{ marginBottom: 10 }}>
                                {t("incognito_with_seed_phrase")}
                            </BoldText>
                            <RegularText align="center" fontSize={16}>
                                {t("seed_phrase_detail")}
                            </RegularText>
                        </View>
                        <View style={styles.buttonsWrapper}>
                            <PressableBase onPress={this.onPressCreate} vibrate={true}>
                                <Row style={[styles.button, { backgroundColor: colors.secondary }]}>
                                    <Icon
                                        style={{ paddingLeft: 5 }}
                                        size={20}
                                        color={colors.white}
                                        name="person-plus"
                                    ></Icon>
                                    <View style={{ flex: 1 }}>
                                        <RegularText fontSize={17} align="center" color={colors.white}>
                                            {t("create")}{" "}
                                            <SemiBoldText color={colors.white}>{t("new_user")}</SemiBoldText>
                                        </RegularText>
                                    </View>
                                </Row>
                              
                            </PressableBase>
                            <PressableBase onPress={this.onPressImport} vibrate={true}>
                                <Row style={[styles.button, { backgroundColor: colors.secondary }]}>
                                    <Icon
                                        style={{ paddingLeft: 5 }}
                                        size={20}
                                        color={colors.white}
                                        name="person-check"
                                    ></Icon>
                                    <View style={{ flex: 1 }}>
                                        <RegularText fontSize={17} align="center" color={colors.white}>
                                            {t("import")}{" "}
                                            <SemiBoldText color={colors.white}>{t("seed_phrase")}</SemiBoldText>
                                        </RegularText>
                                    </View>
                                </Row>
                            </PressableBase>
                        </View>
                        <View style={{ width: "100%", paddingTop: 20 }}>
                            <SeedHistoryList navigation={this.props.navigation} />
                        </View>
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    wrapper: {
        marginTop: 50,
        alignItems: "center",
        width: "100%",
        paddingBottom: 50,
    },
    buttonsWrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        minWidth: 280,
        padding: 12,
        alignItems: "center",
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderRadius: settings.mainButtonsRadius || 25,
        overflow: "hidden",
        marginVertical: 5,
    },
});
