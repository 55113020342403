import React, { Component } from "react";
import { View, StyleSheet, Linking, ScrollView } from "react-native";
import Constants from "expo-constants";
import { colors } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import i18n from "@i18n/i18n";
import SimpleCard from "@components/cards/SimpleCard";
import Icon from "@components/icons";
import store from "@store/index";
import { ready } from "@store/actions/global";
import { NavigationType } from "@custom-types/NavigationType";
import { AuthNavigatorScreens } from "@navigation/AuthNavigator";
import { Route } from "@react-navigation/native";
import BotCard from "@base/BotCard";

const { t } = i18n;

interface Props {
    navigation: NavigationType;
    route: Route<string, { message?: string }>;
}

interface State {}

export default class ControlServiceScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToInit = this.navigateToInit.bind(this)
        this.state = {};
    }

    componentDidMount() {
        store.dispatch(ready());
    }

    navigateToInit () {
        this.props.navigation.navigate(AuthNavigatorScreens.Initializer.routeName);
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <View style={styles.wrapper && styles.background}></View>
                <View style={{ flex: 1, paddingTop: 50, alignContent: "center", alignItems: "center" }}>
                    <View
                        style={{
                            backgroundColor: colors.shadow,
                            height: 150,
                            width: 150,
                            borderRadius: 75,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Icon color={colors.text} size={90} name="wifi-off"></Icon>
                    </View>
                    <View style={{ flex: 1, paddingHorizontal: 20, paddingTop: 60, maxWidth: 600 }}>
                        <BoldText align="center" fontSize={22}>
                            {t("out_of_service")}
                        </BoldText>
                        <RegularText style={{ paddingTop: 20, paddingBottom: 30 }} fontSize={16} align="justify">
                            {t("network_error")}
                        </RegularText>
                        <SimpleCard
                            title={t("network_refresh")}
                            icon="exchange"
                            onPress={this.navigateToInit}
                        />
                        <SimpleCard
                            title={t("support")}
                            icon="life-preserver"
                            onPress={() => {
                                Linking.openURL(Constants.expoConfig?.extra?.support || "mailto:support@beexo.com");
                            }}
                        />
                        {this.props.route?.params?.message?.length > 5 && (
                            <View style={{ paddingTop: 20 }}>
                                <BotCard
                                    title={t("error_description")}
                                    message={this.props.route?.params?.message}
                                ></BotCard>
                            </View>
                        )}
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999999,
    },
    background: {
        position: "absolute",
        backgroundColor: Constants.expoConfig?.extra?.backgroundColor || colors.primary,
        opacity: 0.9,
        height: "100%",
        width: "100%",
    },
    icon: {
        fontSize: 80,
        zIndex: 999,
    },
});
