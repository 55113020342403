import React, { Component } from "react";
import { View, Text, StyleSheet, Platform, ScrollView } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { Storage } from "../../../utils/storage/Storage";
import InlineButton from "@base/InlineButton";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import BotCard from "@base/BotCard";
import Initializer, { AppStatus } from "../../initializer/Initializer";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import Wallet from "@core/wallet/Wallet";
import { OAuthHistoryService } from "@core/services/oauth/OAuthHistoryService";
import SeedHistorySecureStorage from "@utils/storage/storages/SeedHistorySecureStorage";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import CircleButton from "@base/CircleButton";
import store from "@store/index";
import { loading, ready } from "@store/actions/global";
import Row from "@base/Row";
import RNSwipeVerify from "react-native-swipe-verify";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import { SeedNavigatorScreens } from "@navigation/SeedNavigator";

interface Props {
    navigation: NavigationType;
    client: Client;
}

interface State {
    words: string[];
    seeds: string[];
    isVerified: boolean;
}

const { t } = i18n;

export class _RestoreAppScreen extends Component<Props, State> {
    private swipeVerify;
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
        this.onPressCard = this.onPressCard.bind(this);
        this.state = {
            words: Wallet.getInstance().getMnemonic(),
            seeds: [],
            isVerified: false,
        };
    }

    componentDidMount() {
        this.getSeedStored();
    }

    async getSeedStored() {
        try {
            store.dispatch(loading());
            let seeds: string[] = [];
            // GET NEW SEEDS STORED
            const storageHistory = (await OAuthHistoryService.getSeedHistory(this.props.client)) || [];
            let newSeeds = storageHistory.map((s) => {
                return s?.mnemonic;
            });
            seeds = newSeeds?.length ? seeds.concat(newSeeds) : seeds;

            // SEED STORED
            const seedStored = Wallet.getInstance().getMnemonic().join(" ");
            seeds = seedStored ? seeds.concat(seedStored) : seeds;

            // GET OLD SEEDS STORED
            const seedHistory = (await SeedHistorySecureStorage.get()) || [];
            let oldSeeds = seedHistory.map((s) => {
                return s?.mnemonic;
            });
            seeds = oldSeeds?.length ? seeds.concat(oldSeeds) : seeds;

            // OLD SEED STORED
            const oldSeedStored = await Storage.getInstance().getItem("auth4.0", {
                isSecure: true,
                oldKey: "auth4.0",
            });
            seeds = oldSeedStored ? seeds.concat(oldSeedStored) : seeds;

            seeds = seeds.filter((seed, index) => seeds.indexOf(seed) === index);
      
            this.setState({ seeds: seeds });
            store.dispatch(ready());
        } catch (e) {
            store.dispatch(ready());
        }
    }

    async onPressCard(words: string) {
        this.props.navigation.navigate(SeedNavigatorScreens.Backup.routeName, { words: words.split(" ") });
    }

    async onPress() {
        this.setState({ isVerified: true });
        await OAuthService.getInstance().restoreApp();
        Initializer.redirect(this.props.navigation);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("restore_app")} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1, paddingBottom: 20 }}>
                        <BotCard title={t("warning")} message={t("restore_app_warning")}></BotCard>
                        {this.state.seeds.map((s) => {
                            return (
                                <Card
                                    onPress={() => this.onPressCard(s)}
                                    key={s}
                                    style={{ marginTop: 15, alignContent: "center" }}
                                    left={<BoldText numberOfLines={1}>{s}</BoldText>}
                                    right={
                                        <CircleButton
                                            onPress={() => this.onPressCard(s)}
                                            style={{ width: 35, height: 35 }}
                                            iconSize={18}
                                            icon={"right"}
                                        ></CircleButton>
                                    }
                                ></Card>
                            );
                        })}
                    </ScrollView>
                    <Row>
                        <View style={styles.swipContainer}>
                            {Platform.OS !== "web" ? (
                                <RNSwipeVerify
                                    ref={(ref) => (this.swipeVerify = ref)}
                                    width="100%"
                                    buttonSize={50}
                                    borderColor={colors.secondary}
                                    buttonColor={this.state.isVerified ? colors.secondary : colors.secondary}
                                    backgroundColor={colors.background}
                                    textColor={colors.white}
                                    borderRadius={50}
                                    okButton={{ visible: true, duration: 400 }}
                                    onVerified={this.onPress}
                                    icon={
                                        <Icon
                                            name={this.state.isVerified ? "check" : "right"}
                                            size={28}
                                            color={colors.white}
                                            align="center"
                                            style={{}}
                                        />
                                    }
                                >
                                    <RegularText color={colors.text}>
                                        {" "}
                                        {this.state.isVerified ? t("confirmed") : t("slide_to_confirm")}
                                    </RegularText>
                                </RNSwipeVerify>
                            ) : (
                                <InlineButton
                                    style={{ marginHorizontal: 0 }}
                                    onPress={this.onPress}
                                    title={t("confirm")}
                                />
                            )}
                        </View>
                    </Row>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: 25,
    },
    word: {
        paddingHorizontal: 20,
    },
    swipContainer: {
        flex: 1,
        height: 58,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const RestoreAppScreen = connect(mapStateToProps, mapDispatchToProps)(_RestoreAppScreen);

export default RestoreAppScreen;
