import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { IStorage, IStorageOpt } from "./IStorage";

const config = {};

export default class NativeStorage implements IStorage {
    async getItem(key: string, options: IStorageOpt): Promise<string> {
        try {
            const item = await SecureStore.getItemAsync(key, config);
            /*const item = await (options?.isSecure
                ? SecureStore.getItemAsync(key, config) 
                : AsyncStorage.getItem(key)
            );*/
            return JSON.parse(item);
        } catch (err) {
            //alert(err);
        }
    }

    async setItem(key: string, object: Object, options: IStorageOpt): Promise<void> {
        try{ 
            const value = JSON.stringify(object);
            const res = await SecureStore.setItemAsync(key, value, config);
            const storedValue = await this.getItem(key, options);
            if (value != JSON.stringify(storedValue)){
                throw new Error("There is a problem with your storage")
            }
            return res
        }catch(err){
            //alert(err);
        }

        /*return await (options?.isSecure
            ? SecureStore.setItemAsync(key, value, config)
            : AsyncStorage.setItem(key, value));*/
    }

    async removeItem(key: string, options: IStorageOpt): Promise<any> {
        try {
            return await SecureStore.deleteItemAsync(key, config);
            /*return await (options?.isSecure
                ? SecureStore.deleteItemAsync(key, config) 
                : AsyncStorage.removeItem(key)
            );*/
        } catch (err) {
            //console.warn(err);
        }
    }
}
