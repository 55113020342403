import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import Post from "@custom-types/Post";
import FlatListBase from "@base/FlatListBase";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import PostSmallCard from "@screens/social/components/PostSmallCard";
import store from "@store/index";
import { showModalBottom } from "@store/actions/global";
import PostCard from "@screens/social/components/PostCard";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    posts: Array<Post>;
}

interface State { }

const { t } = i18n;

export class _PostsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToSection = this.navigateToSection.bind(this);
        this.renderItem = this.renderItem.bind(this);

        this.state = {};
    }

    navigateToSection() {
        this.props.navigation.navigate(TabsNavigatorScreens.SocialNetwork.routeName);
    }

    openPost = (post) => {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <PostCard
                        isLikeable={true}
                        post={post}
                        navigation={this.props.navigation}
                        modalView={true}
                    />
                ),
            }),
        );
    };

    renderItem = ({ item }) => {
        return (
            <PostSmallCard
                autoSizeWidth={true}
                heightImage={200}
                isLikeable={true}
                highlightedHashtag={false}
                openPost={(post) => this.openPost(post)}
                openHashtag={(hashtag) => null}
                post={item}
                smallAvatar={true}
                navigation={this.props.navigation}
            />
        );
    };

    render() {
        return (
            <View>
                {this.props?.posts?.length > 0 && (
                    <View style={this.props.containerStyle}>
                        <View style={{paddingRight: 20}}>
                        <SectionTitle
                            onPress={this.navigateToSection}
                            title={t("discover").toUpperCase()}
                            icon={"🧭"}
                            // counter={t("social")}
                            seeMore={this.props.posts?.length > 2}
                            {...this.props}
                        ></SectionTitle>
                        </View>
                        <FlatListBase
                            data={this.props.posts}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                            horizontal={true}
                            initialNumToRender={2}
                            maxToRenderPerBatch={2}
                        ></FlatListBase>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        posts: state.social.discoverPosts,
        loading: state.global.loading,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const PostsSection = connect(mapStateToProps, mapDispatchToProps)(_PostsSection);

export default PostsSection;
