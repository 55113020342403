import React, { Component } from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { colors } from "@styles/globalStyles";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import Balance from "@components/wallet/Balance";
import HeaderButtons, { IHeaderAvatar, IHeaderBtn, IHeaderTitleBtn } from "@components/header/HeaderButtons";
import Currency from "@core/currencies/Currency";

interface Props {
    title?: string;
    label?: React.JSX.Element;
    titleBtn?: IHeaderTitleBtn;
    amount?: string;
    unconfirmedAmount?: string;
    connected?: boolean;
    type?: HeaderType;
    symbol?: string;
    navigation: NavigationType;
    rightBtn?: IHeaderBtn[] | IHeaderBtn;
    notificationsBtn?: IHeaderBtn;
    leftBtn?: IHeaderBtn;
    rigthAvatar?: IHeaderAvatar;
    leftAvatar?: IHeaderAvatar;
    titleAvatar?: IHeaderAvatar;
    showVersion?: boolean;
    subtitle?: string;
    actions?: IHeaderBtn[];
    hideReturn?: boolean;
    currency?: Currency;
    onPressCurrency?: () => void;
    backTo?: string;
    hideActionsCallback?: () => void;
    children?: any;
}

export class Header extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={styles.container}>
                <HeaderButtons type={this.props.type} {...this.props} />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        zIndex: 9999,
        height: 65,
        paddingTop: 0,
        flexDirection: "row",
        //marginBottom: Platform.OS === 'web'?0:20,
    },
});

export default Header;
