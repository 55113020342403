import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";

import i18n from "@i18n/i18n";
import { MembershipModel, ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { FlatList, Platform, StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import BoldText from "@base/BoldText";
import Row from "@base/Row";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import AvatarBase from "@components/avatar/AvatarBase";
import ImageBase from "@components/image/ImageBase";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import Icon from "@components/icons";
import { updateMembership } from "@store/actions/experiences.actions";
import store from "@store/index";
import ExperienceService from "@core/services/ExperienceService";
import FlatListBase from "@base/FlatListBase";
import RadiusButton from "@base/RadiusButton";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;

    client: Client;
    experiences: ExperiencesRedux;
}

interface State { }

const { t } = i18n;

export class _MembershipsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.navigateToSection = this.navigateToSection.bind(this);
        this.navigateToMembership = this.navigateToMembership.bind(this);
        this.filterAndSortData = this.filterAndSortData.bind(this);
        this.setFavorite = this.setFavorite.bind(this);
        this.state = {};
    }

    renderItem = ({ item }) => {
        return (
            <PressableBase
                onPress={() => this.navigateToMembership(item)}
                style={{
                    borderRadius: settings.cardRadius,
                    marginRight: 10,
                    overflow: "hidden",
                    backgroundColor: colors.shadow,
                    maxWidth: 300,
                    minWidth: 130,
                }}
            >
                <ImageBase
                    style={{ height: 180 }}
                    uri={item?.membershipOption?.image?.carousel || item?.membershipOption?.image?.cover || ""}
                    autoSizeWidth={true}
                ></ImageBase>
                <View style={{ position: "absolute", left: 10, top: 10 }}>
                    <AvatarBase
                        size={30}
                        uri={item?.merchant?.image?.thumbnail}
                        alias={item?.merchant?.name}
                    ></AvatarBase>
                </View>
                <PressableBase
                    onPress={() => this.setFavorite(item)}
                    style={{ position: "absolute", top: 10, right: 10 }}
                >
                    {item?.favorite ? (
                        <Icon color={colors.yellow} size={20} name="star-fill" />
                    ) : (
                        <Icon color={colors.grey} size={20} name="star" />
                    )}
                </PressableBase>
            </PressableBase>
        );
    };

    async setFavorite(item) {
        ExperienceService.getInstance().setMembershipFavorite(item);
        let membership: MembershipModel = item;
        membership.favorite = !item.favorite;

        store.dispatch(updateMembership(membership));
    }

    navigateToMembership(membership) {
        this.props.navigation.navigate(HomeNavigatorScreens.Experience.routeName, {
            screen: ExperienceNavigatorScreens.Membership.routeName,
            params: { membership: membership },
        });
    }

    navigateToSection() {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.SectionMembership.routeName,
        });
    }

    filterAndSortData() {
        return this.props.experiences?.membership?.docs.filter((m) => {
            return !m.archivedAt;
        });
    }

    render() {
        return (
            <View>
                {this.props.experiences?.membership.paginator.totalDocs > 0 && (
                    <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                        <SectionTitle
                            onPress={this.navigateToSection}
                            title={t("membership")}
                            icon={"💎"}
                            counter={this.props.experiences?.membership?.paginator.totalDocs}
                            seeMore={true}
                            {...this.props}
                        ></SectionTitle>

                        {this.filterAndSortData().length == 0 && (
                            <View style={{ paddingRight: 20 }}>
                                <View
                                    style={{
                                        backgroundColor: colors.tertiaryShadow,
                                        borderRadius: settings.cardRadius,
                                        marginTop: 10,
                                        paddingHorizontal: 10,
                                        paddingVertical: 10,
                                    }}
                                >
                                    <View style={{ paddingBottom: 20 }}>
                                        <RegularText align="justify" fontSize={14}>
                                            {t("memberships_warning")}
                                        </RegularText>
                                    </View>
                                    <View style={{ position: "absolute", right: 10, bottom: 10 }}>
                                        <RadiusButton
                                            title={t("see_more")}
                                            style={{ paddingVertical: 2 }}
                                            fontSize={12}
                                            onPress={this.navigateToSection}
                                        />
                                    </View>
                                </View>
                            </View>
                        )}
                        <FlatListBase
                            data={this.filterAndSortData() || []}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                            initialNumToRender={2}
                            horizontal={true}
                            maxToRenderPerBatch={10}
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const MembershipsSection = connect(mapStateToProps, mapDispatchToProps)(_MembershipsSection);

export default MembershipsSection;
