import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { FlatList, StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { Blog } from "@custom-types/BlogModel";
import BlogCard from "@screens/blog/components/BlogCard";
import FlatListBase from "@base/FlatListBase";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    blogs: {
        docs: Array<Blog>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
}

interface State {}

const { t } = i18n;

export class _BlogSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToSection = this.navigateToSection.bind(this);
        this.renderItem = this.renderItem.bind(this);

        this.state = {};
    }

  
    navigateToSection() {
        // this.props.navigation.navigate(HomeNavigatorScreens.Notifications.routeName);
    }

    renderItem = ({ item }) => {
        return <BlogCard navigation={this.props.navigation} blog={item}></BlogCard>;
    };

    render() {
        return (
            <View>
                {this.props.blogs?.paginator?.totalDocs > 0 && (
                    <View style={this.props.containerStyle}>
                        <SectionTitle
                            onPress={this.navigateToSection}
                            title={"NEWs"}
                            icon={"🗞️"}
                            counter={this.props.blogs?.paginator?.totalDocs || []}
                            {...this.props}
                        ></SectionTitle>

                        <FlatListBase
                            data={this.props.blogs?.docs}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                            initialNumToRender={2}
                            maxToRenderPerBatch={2}
                            horizontal={true}
                        ></FlatListBase>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        blogs: state.blogs?.blogs,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const BlogSection = connect(mapStateToProps, mapDispatchToProps)(_BlogSection);

export default BlogSection;
