import React, { Component } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import CurrencyCard from "@components/cards/CurrencyCard";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import i18n from "@i18n/i18n";
import SwitchCard from "@components/cards/SwitchCard";
import BoldText from "@base/BoldText";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import Row from "@base/Row";
import RegularText from "@base/RegularText";
import { WalletService } from "@core/services/WalletService";

interface Props {
    currencies: Array<Currency>;
    enabledCurrencies?: Array<Currency>;
    fiatCurrency: FiatCurrency;
    onPress: (item) => void;
}

interface State {}

const { t } = i18n;

export default class SelectCurrencyComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    async switchSecurity(currency) {
        WalletService.getInstance().updateCurrencyEnabled({
            currency: currency,
            enabled: currency.enabled == null ? true : !currency.enabled,
        });
    }

    renderItem = ({ item }) =>
        item.isEnabled() ? (
            <CurrencyCard
                onPress={() => this.props.onPress(item)}
                currency={item}
                fiatCurrency={this.props.fiatCurrency}
            />
        ) : (
            <SwitchCard
                title={item.getPName()}
                left={
                    <Row style={{ alignItems: "center" }}>
                        <CurrencyIcon styles={{ marginRight: 15 }} currency={item} />

                        <View>
                            <BoldText fontSize={20}>{item.getSymbol()}</BoldText>
                            <RegularText>{item.getPName()}</RegularText>
                        </View>
                        <NetworkCurrencySymbol currency={item}></NetworkCurrencySymbol>
                    </Row>
                }
                onToggle={() => this.switchSecurity(item)}
                isOn={item.isEnabled()}
            />
        );

    render() {
        return (
            <FlatList
                data={this.props.currencies}
                renderItem={this.renderItem}
                keyExtractor={(item) => item.getId()}
                contentContainerStyle={{ paddingBottom: 20 }}
                initialNumToRender={this.props.currencies.length}
                maxToRenderPerBatch={10}
                extraData={this.props.enabledCurrencies}
            />
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

        overflow: "hidden",
    },
});
