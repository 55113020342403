import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { IWalletConnectStore } from "@core/services/WalletConnectDataService";
import { colors, settings } from "@styles/globalStyles";
import AvatarBase from "@components/avatar/AvatarBase";
import RegularText from "@base/RegularText";
import CircleButton from "@base/CircleButton";
import BoldText from "@base/BoldText";
import Row from "@base/Row";

interface Props {
    navigation: NavigationType;
    route: any;
}

interface State {
    connection: IWalletConnectStore;
}

const { t } = i18n;

export class _WalletConnectDetailScreen extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);

        this.disconnectSession = this.disconnectSession.bind(this);

        this.state = { connection: this.props.route.params.connection || null };
    }

    componentDidMount() {}

    async disconnectSession() {}

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("wallet_connect")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    <View
                        style={{
                            justifyContent: "flex-end",
                            alignContent: "flex-end",
                            alignItems: "flex-end",
                        }}
                    >
                        <BoldText
                            style={{
                                backgroundColor: colors.tertiaryShadow,
                                paddingHorizontal: 10,
                                paddingVertical: 4,
                                borderRadius: settings.cardRadius,
                                overflow: "hidden",
                            }}
                            align="center"
                            fontSize={12}
                        >
                            {this.state.connection.network}
                        </BoldText>
                    </View>
                    <View
                        style={{
                            width: 85,
                            alignSelf: "center",
                            marginTop: 50,
                        }}
                    >
                        <View
                            style={[
                                styles.indicator,
                                {
                                    backgroundColor: this.state.connection.session.connected
                                        ? colors.green
                                        : colors.yellow,
                                },
                            ]}
                        ></View>
                        <AvatarBase
                            size={85}
                            uri={this.state.connection.session.peerMeta.icons[0]}
                            alias={this.state.connection.session.peerMeta.name}
                            overlayContainerStyle={"transparent"}
                        ></AvatarBase>
                    </View>
                    <BoldText style={{ marginTop: 10 }} align="center" fontSize={24}>
                        {this.state.connection.session.peerMeta.name}
                    </BoldText>
                    <RegularText align="center">{this.state.connection.session.peerMeta.description}</RegularText>
                    <Row
                        style={{
                            marginTop: 25,
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                        }}
                    >
                        <CircleButton
                            style={{ marginHorizontal: 10 }}
                            icon="password"
                            title={t("disconnect")}
                            onPress={this.disconnectSession}
                        ></CircleButton>
                    </Row>

                    <View style={{ marginTop: 40 }}>
                        <BoldText>{t("address")}</BoldText>
                        {this.state.connection.session.accounts.map((a) => {
                            return (
                                <RegularText fixedWidth={400} key={a}>
                                    {a}
                                </RegularText>
                            );
                        })}
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

const WalletConnectDetailScreen = connect(mapStateToProps, mapDispatchToProps)(_WalletConnectDetailScreen);

export default WalletConnectDetailScreen;

const styles = StyleSheet.create({
    indicator: {
        position: "absolute",
        top: 5,
        right: 5,
        borderRadius: 8,
        width: 16,
        height: 16,
        zIndex: 9999,
    },
});
