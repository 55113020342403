import { NavigationType } from "@custom-types/NavigationType";
import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { ModuleControlService } from "@core/services/ModuleControlService";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { AuthType } from "@custom-types/Client";
import { AuthNavigatorScreens } from "@navigation/AuthNavigator";
import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { Route } from "@react-navigation/native";
import { reload, setAuthentication, setPriorityLoading, webSplash } from "@store/actions/global";
import store from "@store/index";
import AppStorage from "@utils/storage";
import * as Notifications from "expo-notifications";
import { addNotificationResponseReceivedListener } from "expo-notifications";
import * as SplashScreen from "expo-splash-screen";
import Initializer from "./Initializer";

interface Props {
    navigation: NavigationType;
    initWallet: () => void;
    unread: any;
    messages: any;
    readyToRender: boolean;
    loading: boolean;
    webSplash: boolean;
    activeAuthentication: boolean;
    route: Route<string, { redirectToProfile: boolean }>;
}

interface State {}

export class _InitializerScreen extends Component<Props, State> {
    focusListener: any;
    oAuthService: OAuthService;
    moduleControlService: ModuleControlService;

    constructor(props: Props) {
        super(props);
        this.hideSplash = this.hideSplash.bind(this);
        this.initialize = this.initialize.bind(this);
        this.initApp = this.initApp.bind(this);
        this.state = {};
    }

    async initialize() {
        this.moduleControlService = ModuleControlService.getInstance();

        if (!(await this.moduleControlService.init())) {
            this.props.navigation.navigate(AuthNavigatorScreens.ControlService.routeName, { message: this.moduleControlService.getErrorMessage() });
            return;
        }

        await OAuthService.initFromStorage();
        this.oAuthService = OAuthService.getInstance();
        this.moduleControlService.setEnabledCurrencies();
        if (ModuleControlService.getInstance().appInMaintenance()) {
            return;
        }

        if (await this.oAuthService.isAuth()) {
            if (this.oAuthService.getClient()) {
                return this.initApp();
            }

            try {
                const userData = this.oAuthService.getClientData();
                const data = await AppStorage.getItem("initializer");
                if (data?.redirectToProfile) {
                    await AppStorage.removeItem("initializer");
                    return this.props.navigation.navigate(AuthNavigatorScreens.Login.routeName, {
                        screen: LoginNavigatorScreens.SetProfile.routeName,
                        params: {
                            name: userData.name,
                            avatar: userData.avatar,
                            alias: userData.alias,
                            email: userData.email,
                        },
                    });
                }
            } catch (e) {
                await OAuthService.getInstance().signout();
            }

            if (this.oAuthService.getAuthType() !== AuthType.ANON) {
                return this.props.navigation.navigate(AuthNavigatorScreens.Login.routeName, {
                    screen: LoginNavigatorScreens.UserOAuth.routeName,
                });
            }
        }
        return this.props.navigation.navigate(AuthNavigatorScreens.Login.routeName);
    }

    componentDidMount() {
        setTimeout(() => {
            this.hideSplash();
        }, 3500);
        this.focusListener = this.props.navigation.addListener("focus", async () => {
            store.dispatch(setPriorityLoading(true));
            await this.initialize();
            store.dispatch(setPriorityLoading(false));
        });

        addNotificationResponseReceivedListener(() => {
            // this.props.navigation.navigate(TabsNavigatorScreens.Messages.routeName);
        });

        Notifications.addNotificationReceivedListener((data) => {
            if (data) {
            }
        });
    }

    async hideSplash() {
        await SplashScreen.hideAsync();
        if (this.props.webSplash) {
            store.dispatch(webSplash());
        }
    }

    initApp = async () => {
        if (!(await Initializer.getInstance().areTermsAccepted())) {
            store.dispatch(setPriorityLoading(false));
            this.props.navigation.navigate(AuthNavigatorScreens.Login.routeName, {
                screen: LoginNavigatorScreens.Terms.routeName,
            });

            return;
        }

        await this.oAuthService.verifyClientFromSeed();
        this.oAuthService.setNotificationToken();

        const authentication = await AppStorage.getItem("authentication");

        if (!this.props.activeAuthentication && authentication) {
            store.dispatch(setAuthentication(authentication));
        }
       
        store.dispatch(
            reload(() => {
               this.props.navigation.navigate(AuthNavigatorScreens.Main.routeName);
            }),
        );

        return;
    };

    render() {
        return <ScreenWrapper></ScreenWrapper>;
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        ...state,
        unread: state.chat.unread,
        readyToRender: state.global.readyToRender,
        loading: state.global.loading,
        webSplash: state.global.webSplash,
        activeAuthentication: state.global.activeAuthentication,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const InitializerScreen = connect(mapStateToProps, mapDispatchToProps)(_InitializerScreen);

export default InitializerScreen;
