import React, { Component } from "react";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { Route } from "@react-navigation/native";
import { AssetModel } from "@custom-types/ExpercienceModel";
import ExperienceService from "@core/services/ExperienceService";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import ImageBase from "@components/image/ImageBase";
import { colors, settings } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Card from "@base/Card";
import Icon from "@components/icons";
import store from "@store/index";
import { setSelectedRedeemable, updateAsset } from "@store/actions/experiences.actions";
import Row from "@base/Row";
import CircleButton from "@base/CircleButton";
import AvatarBase from "@components/avatar/AvatarBase";
import { hideModalBottom, loading, ready, showModalBottom } from "@store/actions/global";
import { getColorOpacity } from "@utils/helpers/global/global";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import QRBase from "@base/QRBase";
import AssetHistoryCard from "./components/AssetHistoryCard";
import PressableBase from "@base/PressableBase";
import InlineButton from "@base/InlineButton";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<string, { asset: AssetModel }>;
}

interface State {
    asset: AssetModel;
    showDescription: boolean;
    isOwner: boolean;
}

const { t } = i18n;

export class _AssetScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setFavorite = this.setFavorite.bind(this);
        this.showArchiveModal = this.showArchiveModal.bind(this);
        this.archiveAsset = this.archiveAsset.bind(this);
        this.setUnarchive = this.setUnarchive.bind(this);

        this.state = {
            asset: this.props.route?.params?.asset || null,
            showDescription: false,
            isOwner: this.props.route?.params?.asset?.owner?._id == this.props.client?._id,
        };
    }

    async componentDidMount() {
        const asset: AssetModel = await ExperienceService.getInstance().getAsset(this.props.route.params?.asset?._id);
        if (asset) {
            this.setState({ asset: asset, isOwner: asset?.owner?._id == this.props.client?._id });
        }
    }

    componentWillUnmount() {
        store.dispatch(setSelectedRedeemable(null));
    }

    async setFavorite() {
        this.setState(
            (prevState) => ({
                asset: {
                    ...prevState.asset,
                    favorite: !this.state.asset.favorite,
                },
            }),
            () => store.dispatch(updateAsset(this.state.asset))
        );
        const resp = await ExperienceService.getInstance().setAssetFavorite(this.state.asset);
    }

    showArchiveModal() {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <View style={{ paddingHorizontal: 30 }}>
                        <RegularText fontSize={18} align={"center"} style={{ paddingBottom: 30 }}>
                            {t("gate_message", { type: "Asset" })}
                        </RegularText>
                        <BoldText fontSize={18} align={"center"}>
                            {t("wallet_connect_request_question")}
                        </BoldText>
                        <InlineButton
                            style={{ marginTop: 25 }}
                            title={t("archive")}
                            onPress={this.archiveAsset}
                        ></InlineButton>
                    </View>
                ),
            })
        );
    }

    async archiveAsset() {
        store.dispatch(hideModalBottom());
        store.dispatch(loading());
        const resp = await ExperienceService.getInstance().archiveAsset(this.state.asset._id);

        if (resp) {
            ExperienceService.getInstance().getAssets();
            this.props.navigation.navigate("Home", {
                screen: "HomeMain",
            });
        }
        store.dispatch(ready());
    }

    async setUnarchive() {
        store.dispatch(loading());
        const resp = await ExperienceService.getInstance().unarchiveAsset(this.state.asset._id);

        if (resp) {
            ExperienceService.getInstance().getAssets();
            this.props.navigation.navigate("Home", {
                screen: "HomeMain",
            });
        }
        store.dispatch(ready());
    }

    onPressQR = (qrCode) => {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <View
                        style={{
                            paddingVertical: 50,
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <QRBase value={`?asset=${this.state.asset._id}`} size={180} />
                    </View>
                ),
            })
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.asset?.assetOption?.name, 15)}
                    type={HeaderType.Light}
                    {...this.props}
                />

                <Container style={{ flex: 1 }}>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <Card
                            style={{
                                width: "100%",
                                paddingHorizontal: 0,
                                paddingVertical: 0,
                            }}
                        >
                            <ImageBase
                                autoSize={true}
                                uri={
                                    this.state.asset.assetOption?.image?.carousel ||
                                    this.state.asset.assetOption?.image?.cover ||
                                    ""
                                }
                            ></ImageBase>

                            {this.state.isOwner && (
                                <PressableBase onPress={this.setFavorite} style={styles.favorite}>
                                    {this.state.asset.favorite ? (
                                        <Icon color={colors.yellow} size={20} name="star-fill" />
                                    ) : (
                                        <Icon color={colors.grey} size={20} name="star" />
                                    )}
                                </PressableBase>
                            )}

                            <View style={styles.circleWrapper}>
                                <View style={styles.circle}>
                                    <RegularText style={{ marginTop: Platform.OS == "web" ? -2 : 2 }}>🧩</RegularText>
                                </View>
                            </View>
                        </Card>
                        <Card style={styles.wrapper}>
                            {this.state.asset.archivedAt && (
                                <View style={{ height: 25, width: "100%" }}>
                                    <View style={{ position: "absolute", top: 15, right: 15 }}>
                                        <View
                                            style={{
                                                borderRadius: 15,
                                                paddingHorizontal: 12,
                                                paddingVertical: 4,
                                                backgroundColor: getColorOpacity(colors.grey, 0.8),
                                            }}
                                        >
                                            <Row style={{ alignItems: "center" }}>
                                                <Icon
                                                    size={14}
                                                    style={{ paddingRight: 5 }}
                                                    color={colors.labelText}
                                                    name={"archive"}
                                                ></Icon>

                                                <RegularText color={colors.labelText} fontSize={12}>
                                                    {t("archived").toUpperCase()}
                                                </RegularText>
                                            </Row>
                                        </View>
                                    </View>
                                </View>
                            )}
                            <View style={styles.details}>
                                <Row
                                    style={{
                                        width: "100%",
                                        justifyContent: "space-between",
                                        alignItems: "flex-end",
                                    }}
                                ></Row>

                                <BoldText style={{ position: "relative" }} fontSize={20}>
                                    {this.state.asset.assetOption.name}
                                </BoldText>

                                {!this.state.showDescription &&
                                    this.state.asset.assetOption.description?.length > 139 ? (
                                    <PressableBase style={{}} onPress={() => this.setState({ showDescription: true })}>
                                        <RegularText align={"justify"} style={{ paddingTop: 10 }}>
                                            {trimHelper(this.state.asset.assetOption.description, 140)}

                                            <RegularText align="left" style={{ color: "#4796fb" }}>
                                                {" "}
                                                {t("see_more")}
                                            </RegularText>
                                        </RegularText>
                                    </PressableBase>
                                ) : (
                                    <View>
                                        <RegularText align={"justify"} style={{ paddingTop: 10 }}>
                                            {this.state.asset.assetOption.description}
                                        </RegularText>
                                    </View>
                                )}
                                {this.state.asset?.merchant && (
                                    <Row style={{ justifyContent: "center", alignItems: "center", paddingTop: 10 }}>
                                        <AvatarBase
                                            size={30}
                                            uri={
                                                this.state.asset?.merchant?.image?.thumbnail ||
                                                this.state.asset?.merchant?.image?.cover ||
                                                ""
                                            }
                                            alias={this.state.asset?.merchant?.name}
                                            overlayContainerStyle={colors.secondaryShadow}
                                        ></AvatarBase>

                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {"By"}
                                            <BoldText fontSize={14}>
                                                {" "}
                                                {trimHelper(this.state.asset?.merchant?.name, 20)}
                                            </BoldText>
                                        </RegularText>
                                    </Row>
                                )}
                                {this.state.asset?.owner && (
                                    <Row style={{ alignItems: "center", paddingTop: 10 }}>
                                        <AvatarBase
                                            size={30}
                                            uri={
                                                this.state.asset?.owner?.profileImagePath?.thumbnail
                                                    ? this.state.asset?.owner?.profileImagePath.thumbnail
                                                    : ""
                                            }
                                            alias={this.state.asset?.owner?.alias}
                                            overlayContainerStyle={colors.secondaryShadow}
                                        ></AvatarBase>

                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {t("owned_by")}
                                            <BoldText fontSize={14}>
                                                {" "}
                                                {trimHelper(this.state.asset?.owner?.alias, 20)}
                                            </BoldText>
                                        </RegularText>
                                    </Row>
                                )}

                                {this.state.asset?.assetOption?.properties?.length > 0 && (
                                    <View style={styles.attributes}>
                                        {this.state.asset?.assetOption?.properties?.map((p) => {
                                            return (
                                                <View
                                                    key={`${p?.property?._id}${p?.value?._id}`}
                                                    style={styles.attribute}
                                                >
                                                    <BoldText>{p?.property?.name}</BoldText>
                                                    <RegularText fontSize={14}>{p.value?.name}</RegularText>
                                                </View>
                                            );
                                        })}
                                    </View>
                                )}
                            </View>
                        </Card>

                        {this.state.isOwner && (
                            <Card
                                style={{ flex: 1, width: "100%", flexDirection: "row", justifyContent: "space-around" }}
                            >
                                {this.state.asset.assetOption.transferable && (
                                    <CircleButton
                                        icon={"lightning-charge-1"}
                                        title={t("send")}
                                        onPress={() =>
                                            this.props.navigation.navigate(
                                                ExperienceNavigatorScreens.TransferAsset.routeName,
                                                { asset: this.state.asset }
                                            )
                                        }
                                    ></CircleButton>
                                )}

                                {!this.state.asset.archivedAt && (
                                    <CircleButton
                                        icon={"archive"}
                                        title={t("archive")}
                                        iconSize={26}
                                        onPress={this.showArchiveModal}
                                    ></CircleButton>
                                )}

                                {this.state.asset.archivedAt && (
                                    <CircleButton
                                        icon={"archive"}
                                        title={t("unarchive")}
                                        iconSize={26}
                                        onPress={this.setUnarchive}
                                    ></CircleButton>
                                )}

                                <PressableBase
                                    onPress={() => this.onPressQR(`?asset=${this.state.asset._id}`)}
                                    style={styles.qrWrapper}
                                >
                                    <QRBase value={`?asset=${this.state.asset._id}`} size={70} />
                                </PressableBase>
                            </Card>
                        )}

                        {/* {this.state.isOwner && ( */}
                        <View style={{ paddingTop: 10 }}>
                            <View>
                                <BoldText fontSize={18}>{t("history")}</BoldText>
                            </View>
                            {this.state.asset.history
                                ?.map((h) => {
                                    return (
                                        <AssetHistoryCard
                                            asset={this.state.asset}
                                            client={this.props.client}
                                            key={h._id}
                                            history={h}
                                            {...this.props}
                                        ></AssetHistoryCard>
                                    );
                                })
                                .reverse()}
                        </View>
                        {/* )} */}
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    attributes: {
        flex: 1,
        width: "100%",
        paddingTop: 20,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginBottom: -10,
    },
    attribute: {
        width: "48%",
        backgroundColor: getColorOpacity(colors.secondary, 0.6),
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: settings.cardRadius,
        marginBottom: 15,
    },
    details: {
        width: "100%",
        paddingTop: 20,
        paddingBottom: 25,
        paddingHorizontal: 15,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
    },
    qrWrapper: {
        backgroundColor: colors.gradientFrom,
        borderRadius: 5,
        overflow: "hidden",
    },
    circleWrapper: {
        position: "absolute",
        top: 15,
        left: 15,
    },
    circle: {
        height: 32,
        width: 32,
        borderRadius: 16,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    favorite: {
        position: "absolute",
        top: 15,
        right: 15,
    },
    icon: {
        backgroundColor: colors.tertiaryShadow,
        height: 30,
        width: 30,
        borderRadius: 15,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    bubble: {
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        borderRadius: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const AssetScreen = connect(mapStateToProps, mapDispatchToProps)(_AssetScreen);

export default AssetScreen;
