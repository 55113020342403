import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import ImageBase from "@components/image/ImageBase";
import { colors } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Icon from "@components/icons";
import Row from "@base/Row";
import moment from "moment";
import Card from "@base/Card";
import SemiBoldText from "@base/SemiBold";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import { RedeemableOptionModel } from "@custom-types/ExpercienceModel";

interface Props {
    navigation: NavigationType;
    redeemable: RedeemableOptionModel;
    selectedCurrency: { currency: Currency | FiatCurrency, gatewayId: string };
}

interface State {
    redeemable: RedeemableOptionModel;
    price: string
}

const { t } = i18n;

export class _RedeemableDetailModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            redeemable: this.props.redeemable,
            price: ''
        };
    }

    componentDidMount(): void {
        this.setState({
            price: this.getPrice(this.state.redeemable.prices, this.props.selectedCurrency.currency)
        })
    }

    getDate = () => {
        const startDate = moment(this.state.redeemable?.startDate);
        const endDate = moment(this.state.redeemable?.endDate);

        if (startDate.isSame(endDate, "day")) {
            return startDate.format("LL");
        }
        if (startDate.isSame(endDate, "month")) {
            return `${startDate.format("MMMM D")} ${t("to").toLowerCase()} ${endDate.format("D")}, ${startDate.format("YYYY")}`;
        }
        if (startDate.isSame(endDate, "year")) {
            return `${startDate.format("MMMM D")} ${t("to").toLowerCase()} ${endDate.format("MMMM D")}, ${startDate.format("YYYY")}`;
        }
        return `${startDate.format("LL")} ${t("to").toLowerCase()} ${endDate.format("LL")}`;
    };

    getPrice(prices: any, currency: Currency | FiatCurrency) {
        try {
            return Number(prices[currency instanceof Currency ? 'digitalCurrency' : 'fiatCurrency'][currency.getId()]).toString()
        } catch (e) {
            console.warn(e);
        }
    }

    render() {
        return (

            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.wrapper}>

                    <Card style={{ marginVertical: 0, paddingTop: 0 }}>
                        <ImageBase
                            autoSize={true}
                            uri={this.state.redeemable?.image?.carousel || this.state.redeemable?.image?.cover || ""}
                        ></ImageBase>
                        <View style={styles.detailsContainer}>
                            <View style={{ paddingVertical: 20 }}>
                                {this.state.redeemable?.name && (
                                    <BoldText style={{ marginBottom: 15 }} fontSize={26}>
                                        {this.state.redeemable?.name}
                                    </BoldText>
                                )}

                                {this.state.redeemable?.description.length && (
                                    <RegularText color={colors.grey} style={{ marginBottom: 25 }}>
                                        {this.state.redeemable?.description}
                                    </RegularText>
                                )}

                                {this.state?.redeemable?.startDate && (
                                    <Row style={styles.section}>
                                        <View style={styles.icon}>
                                            <Icon color={colors.text} size={16} name="ticket" />
                                        </View>
                                        <RegularText style={{ textTransform: "capitalize" }}>
                                            {this.props.redeemable.type}
                                        </RegularText>
                                    </Row>
                                )}

                                {this.state?.redeemable?.startDate && (
                                    <Row style={styles.section}>
                                        <View style={styles.icon}>
                                            <Icon color={colors.text} size={14} name="calendar-minus" />
                                        </View>
                                        <RegularText>
                                            {this.getDate()}
                                        </RegularText>
                                    </Row>
                                )}

                                {this.state?.redeemable?.startDate && (
                                    <Row style={styles.section}>
                                        <View style={styles.icon}>
                                            <Icon color={colors.text} size={16} name="currency-dollar" />
                                        </View>
                                        <Row>
                                            {this.state.price == '0' && <View style={styles.bubble}>
                                                <SemiBoldText
                                                    align="center"
                                                    fontSize={10}
                                                    color={colors.labelText || colors.white}
                                                    style={{ textTransform: "uppercase" }}
                                                >
                                                    {t("free")}
                                                </SemiBoldText>
                                            </View>}
                                            {
                                                this.props.selectedCurrency.currency instanceof Currency ?
                                                    <CurrencyBalanceIcon
                                                        amount={this.state.price}
                                                        currency={this.props.selectedCurrency.currency}
                                                        fontWeight="bold"
                                                        fontSize={16}
                                                        iconSize={16}
                                                        style={{ alignItems: "center" }}
                                                    /> :
                                                    <CurrencyBalanceIcon
                                                        amount={this.state.price}
                                                        fiatCurrency={this.props.selectedCurrency.currency}
                                                        fontWeight="bold"
                                                        fontSize={16}
                                                        iconSize={16}
                                                        style={{ alignItems: "center" }}
                                                    />
                                            }
                                        </Row>
                                    </Row>
                                )}
                            </View>
                        </View>
                    </Card>
                </View>
            </ScrollView>

        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        marginBottom: 20
    },
    detailsContainer: {
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
    },
    icon: {
        backgroundColor: colors.tertiaryShadow,
        height: 30,
        width: 30,
        borderRadius: 15,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginRight: 10
    },
    bubble: {
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 8,
        paddingVertical: 3,
        borderRadius: 4,
        marginRight: 10
    },
    section: {
        marginBottom: 15,
        alignItems: "center"
    }
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        selectedCurrency: state.experiences.selectedCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const RedeemableDetailModal = connect(mapStateToProps, mapDispatchToProps)(_RedeemableDetailModal);

export default RedeemableDetailModal;
