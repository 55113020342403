import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { View, StyleSheet } from "react-native";
import Row from "@base/Row";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import BoldText from "@base/BoldText";
import { importer } from "@custom/helpers/importer";
import Icon from "@components/icons";
import PressableBase from "@base/PressableBase";
import SemiBoldText from "@base/SemiBold";

interface Props {
    navigation: NavigationType;
    onPress: () => void;
    icon?: string;
    title?: string;
    counter?: string | number;
    seeMore?: boolean;
}

interface State { }

const { t } = i18n;

class DefaultSectionTitle extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <PressableBase onPress={this.props.onPress}>
                <Row style={{ justifyContent: "space-between", width: "100%" }}>
                    <Row>
                        {this.props.icon?.length > 0 && (
                            <View style={styles.circle}>
                                <RegularText fontSize={14}>
                                    {this.props.icon}
                                </RegularText>
                            </View>
                        )}
                        <BoldText fontSize={16} style={{ position: "relative" }}>
                            {this.props.title}
                            <View style={{ zIndex: 9999, position: "relative", width: 120 }}>
                                {this.props.counter && (
                                    <View style={styles.bubble}>
                                        <SemiBoldText align="center" fontSize={10} color={colors.labelText || colors.white}>
                                            {this.props.counter}
                                        </SemiBoldText>
                                    </View>
                                )}
                            </View>
                        </BoldText>
                    </Row>
                    {this.props.seeMore && <Row style={{ justifyContent: 'center', alignItems: "center", paddingHorizontal: 8, paddingVertical: 4, backgroundColor: colors.shadow, borderRadius: 10, marginRight: 10 }}>
                        <RegularText style={{ marginRight: 2 }} fontSize={10} >{t('see_more')}</RegularText>
                        <Icon name="right" color={colors.text} size={10} />
                    </Row>}
                </Row>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const SectionTitle = importer(DefaultSectionTitle, "SectionTitle");

export { SectionTitle };
