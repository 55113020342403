import BoldText from "@base/BoldText";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import Icon from "@components/icons";
import ImageBase from "@components/image/ImageBase";
import Currency from "@core/currencies/Currency";
import { DappModel } from "@custom-types/DappModel";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { DappsNavigatorScreens } from "@navigation/DappsNavigator";
import { colors, settings } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import { LinearGradient } from "expo-linear-gradient";
import React, { Component } from "react";
import { Linking, Platform, StyleSheet, View } from "react-native";
import { BlurView } from "expo-blur";

interface Props {
    navigation: NavigationType;
    dapp: DappModel;
    onPress?: (dapp) => void;
    onLongPress?: () => void;
}

const { t } = i18n;

interface State {
    dapp: DappModel;
}

export default class DappCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToDapp = this.navigateToDapp.bind(this);
        this.state = {
            dapp: this.props.dapp || null,
        };
    }

    async navigateToDapp() {
        if (Platform.OS == "web") {
            await Linking.openURL(this.state.dapp?.url);
            return;
        }
        const partialDapp: Partial<DappModel> = {
            _id: this.state.dapp._id || "",
            name: this.state.dapp.name || "",
            image: { square: this.state.dapp.image?.square || "", thumbnail: this.state.dapp.image?.thumbnail || "" },
            url: this.state.dapp?.url,
        };
        this.props.navigation.navigate("Dapps", {
            screen: DappsNavigatorScreens.DaapsWebView.routeName,
            params: { dapp: partialDapp },
        });
    }

    render() {
        return (
            <>
                {this.state.dapp && (
                    <PressableBase onPress={this.navigateToDapp} style={styles.wrapper}>
                        <View style={{ position: "absolute" }}>
                            <ImageBase
                                style={{
                                    width: 600,
                                    height: 600,
                                }}
                                blurRadius={50}
                                contentFit={"cover"}
                                uri={this.state.dapp?.image?.thumbnail}
                            />
                        </View>

                        <View style={{ width: "100%", flex: 1, paddingHorizontal: 6, paddingVertical: 6 }}>
                            <View style={{ justifyContent: "space-between", flex: 1, width: "100%" }}>
                                <Row
                                    style={{ justifyContent: "space-between", alignItems: "center", paddingBottom: 8 }}
                                >
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        {this.state.dapp?.digitalCurrencies?.map((c: Currency) => {
                                            return (
                                                <CurrencyIcon
                                                    key={c.getId()}
                                                    size={18}
                                                    styles={{ marginLeft: 3 }}
                                                    currency={c}
                                                />
                                            );
                                        })}
                                    </Row>
                                </Row>
                                <View style={{}}>
                                    <AvatarBase
                                        size={100}
                                        alias={this.state.dapp?.name}
                                        uri={this.state.dapp?.image?.thumbnail}
                                    ></AvatarBase>
                                    <BoldText
                                        style={{
                                            paddingTop: 5,
                                            textShadowColor: getColorOpacity(colors.grey, 0.75),
                                            textShadowRadius: 8,
                                            overflow: "visible",
                                        }}
                                        align="center"
                                        numberOfLines={1}
                                    >
                                        {this.state.dapp?.name}
                                    </BoldText>
                                </View>
                            </View>
                            <View
                                style={{ height: 30, width: "100%", justifyContent: "flex-end", paddingHorizontal: 4 }}
                            >
                                {this.state?.dapp?.type == "xo" && (
                                    <Row
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            backgroundColor: getColorOpacity(colors.secondary, 0.7),
                                            alignSelf: "flex-start",
                                            paddingLeft: 2,
                                            paddingRight: 5,
                                            paddingVertical: 1,
                                            borderRadius: 10,
                                        }}
                                    >
                                        <Icon name={"uniE924"} color={colors.text} />
                                        <RegularText fontSize={12}> {t("verified")}</RegularText>
                                    </Row>
                                )}

                                {this.state?.dapp?.type == "walletConnect" && (
                                    <Row
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: getColorOpacity(colors.grey, 1),
                                            alignSelf: "flex-start",
                                            height: 18,
                                            width: 18,
                                            borderRadius: 9,
                                        }}
                                    >
                                        <Icon size={12} name={"WalletConnect-icon"} color={colors.text} />
                                    </Row>
                                )}
                            </View>
                        </View>
                    </PressableBase>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        marginHorizontal: 5,
        marginVertical: 5,
        width: 160,
        height: 200,
        borderRadius: settings.cardRadius,

        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
});
