import React, { Component } from "react";
import { BackHandler, Platform, StyleSheet, View } from "react-native";

import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import Container from "@base/Container";
import { Route } from "@react-navigation/native";
import { hideScanner } from "@store/actions/global";
import store from "@store/index";
import { isExperience } from "./implementations/ExperiencesImplementation";
import { isPOAP } from "./implementations/POAPImplementation";
import { isProfile } from "./implementations/ProfileImplementation";
import { isWalletConnect } from "./implementations/WalletConnectImplementation";
import { isCryptoAddres } from "./implementations/WalletImplementation";
import ScannerBase from "./ScannerBase";
5;

interface Props {
    navigation?: NavigationType;
    route?: Route<string, {}>;
    showScanner: boolean;
}

interface State {}

const { t } = i18n;

export class _ScannerMain extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.executeBarCodeScanned = this.executeBarCodeScanned.bind(this);
        this.state = {};

        if (Platform.OS != "web")
            BackHandler.addEventListener("hardwareBackPress", () => this.props.navigation.isFocused());
    }

    executeBarCodeScanned(qrCode) {
        isCryptoAddres(this.props.navigation, qrCode);
        isWalletConnect(qrCode);
        isPOAP(this.props.navigation, qrCode);
        isExperience(this.props.navigation, qrCode);
        isProfile(this.props.navigation, qrCode);
    }

    render() {
        return (
            <>
                {this.props.showScanner && (
                    <View style={styles.wrapper}>
                        <Container style={{ flex: 1, paddingHorizontal: 0 }}>
                            <ScannerBase
                                returnBarCodeScanned={this.executeBarCodeScanned}
                                navigation={this.props.navigation}
                                goBack={() => store.dispatch(hideScanner())}
                            ></ScannerBase>
                        </Container>
                    </View>
                )}
                <View style={styles.hidde}></View>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { showScanner: state.global.showScanner };
};

const mapDispatchToProps = (dispatch) => ({});

const ScannerMain = connect(mapStateToProps, mapDispatchToProps)(_ScannerMain);

export default ScannerMain;

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
        width: "100%",
        height: "100%",
    },
    hidde: {
        display: "none",
    },
});
