import React, { Component } from "react";
import { View, StyleSheet, TextStyle, ScrollView, StyleProp, ViewStyle, Dimensions } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { LinearGradient } from "expo-linear-gradient";

interface Props {
    style?: StyleProp<ViewStyle>;
    type?: "normal" | "scroll";
    children?: any;
}

interface State {
    type: "normal" | "scroll";
    style: StyleProp<ViewStyle>;
}

export default class ScreenWrapper extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            type: this.props.type || "normal",
            style: [styles.wrapper, this.props.style],
        };
    }

  
    render() {
        return (
            <LinearGradient
                // start={settings.gradientStart}
                // end={settings.gradientEnd} 
                locations={settings.gradientLocations}
                colors={[colors.gradientFrom, colors.gradientTo]}
                style={this.state.style}
            >
                {this.state.type == "normal" && <View style={{ flex: 1 }}>{this.props.children}</View>}
                {this.state.type == "scroll" && <ScrollView style={{ flex: 1 }}>{this.props.children}</ScrollView>}
            </LinearGradient>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: colors.background,
        
    },
});
