import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { Platform, View } from "react-native";
import { Avatar } from "react-native-elements";
import Icon from "@components/icons";
import RegularText from "@base/RegularText";
import BoldText from "@base/BoldText";
import { getColorOpacity } from "@utils/helpers/global/global";
import PressableBase from "@base/PressableBase";
import ImageBase from "@components/image/ImageBase";

interface Props {
    uri?: string;
    alias?: string;
    size?: number;
    accesory?: boolean;
    accesoryIconSize?: number;
    accesoryIiconName?: string;
    isOwner?: boolean;
    selected?: boolean;
    overlayContainerStyle?: string;
    onPress?: () => void;
    onLongPress?: () => void;
    onBlur?: () => void;
}

interface State {
    showAvatar: boolean;
}

export class AvatarBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showAvatar: this.props.uri && this.props.uri !== undefined,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps?.uri !== this.props?.uri) {
            this.setState({ showAvatar: this.props.uri && this.props.uri !== undefined });
        }
    }
    render() {
        return (
            <View style={{ alignSelf: "center" }}>
                <View style={{ position: "relative" }}>
                    <PressableBase
                        onPress={this.props.onPress || null}
                        disabled={!this.props?.onPress}
                        disableOpacity={!this.props?.onPress}
                        style={{
                            backgroundColor: this.state.showAvatar ? "transparent" : colors.avatarBase,
                            height: this.props.size,
                            width: this.props.size,
                            borderRadius: Math.round(this.props.size / 2),
                            justifyContent: "center",
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                            overflow: "hidden",
                            zIndex: 9999,
                        }}
                        accessibilityLabel="profile-button"
                    >
                        {this.state.showAvatar ? (
                            <ImageBase
                                style={{ height: this.props.size, width: this.props.size }}
                                uri={this.props.uri}
                                onError={() => this.setState({ showAvatar: false })}
                            ></ImageBase>
                        ) : (
                            <BoldText
                                color={colors.floatButtonText}
                                align="center"
                                fontSize={this.props.size ? this.props.size / 2.2 : 16}
                            >
                                {this.props.alias?.substring(0, 1) !== "1"
                                    ? this.props.alias?.substring(0, 1).toUpperCase()
                                    : ""}
                            </BoldText>
                        )}
                    </PressableBase>
                    {this.props.accesory && (
                        <PressableBase
                            onPress={this.props.onPress || null}
                            style={{
                                position: "absolute",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 9999,
                                bottom: 0,
                                right: 1,
                                height: 36,
                                width: 36,
                                overflow: "hidden",
                                borderRadius: 18,
                                backgroundColor: getColorOpacity(colors.floatButtonBackground, 0.8),
                            }}
                            accessibilityLabel="profile-button"
                        >
                            <Icon name={this.props.accesoryIiconName} size={15} color={colors.floatButtonText} />
                        </PressableBase>
                    )}
                </View>
            </View>
        );
    }
}

export default AvatarBase;
