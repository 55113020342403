import Card from "@base/Card";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import ImageBase from "@components/image/ImageBase";
import { ActivityModel, ActivityTypes, AssetActivityData } from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { getAsset } from "@screens/scanner/implementations/ExperiencesImplementation";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    activity: ActivityModel;
    client: Client;
}

interface State {
    activityData: AssetActivityData;
    isOwner: boolean;
}

const { t } = i18n;

export class _ActivityCardAsset extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.openAsset = this.openAsset.bind(this);
        this.state = {
            activityData: this.props?.activity?.data,
            isOwner: this.props?.client?._id == this.props.activity?.data?.clientFrom?._id,
        };
    }

    openAsset() {
        getAsset(this.props.navigation, this.state.activityData?.asset?._id);
    }

    render() {
        return (
            <>
                {this.state.activityData && (
                    <>
                    {this.props.activity?.type == ActivityTypes.AssetClaim && 
                    <Card
                    onPress={this.openAsset}
                    icon={
                        <View>
                            <AvatarBase
                                size={45}
                                uri={this.state.activityData?.asset?.merchant?.image?.thumbnail || ""}
                                alias={this.state.activityData?.asset?.merchant?.name || ""}
                            ></AvatarBase>
                            <View style={styles.secondaryAvatar}>
                                <AvatarBase
                                    size={20}
                                    uri={this.props.client?.profileImagePath?.thumbnail}
                                    alias={this.props.client?.alias || ""}
                                ></AvatarBase>
                            </View>
                        </View>
                    }
                    left={
                        <View style={{ marginLeft: 10, justifyContent: "center" }}>
                            <RegularText numberOfLines={2} fontSize={14} style={{ marginBottom: 2 }}>
                                {/* {t("congratulations")}{" "} */}
                                <SemiBoldText fontSize={14}>
                                    {trimHelper(this.state.activityData?.asset?.merchant?.name, 10)}
                                </SemiBoldText>{" "}
                                {t("granted_you")}{" "}
                                <SemiBoldText fontSize={14}>
                                    {trimHelper(this.state.activityData?.asset?.assetOption?.name, 12)}
                                </SemiBoldText>
                                <RegularText fontSize={11}>
                                                <View style={{ width: 25, height: 15 }}>
                                                    <View style={{ position: "absolute", top: -4, left: 1 }}>
                                                        <LottieBase
                                                            height={22}
                                                            width={22}
                                                            loop={moment().diff(moment(this.props.activity?.createdAt), 'minutes') < 5}
                                                            source={require("../../../assets/animations/asset-2.json")}
                                                        />
                                                    </View>
                                                </View>
                                                {"ASSET"}
                                            </RegularText>
                                <RegularText fontSize={11} color={colors.grey}>
                                    {"  ("}
                                    {moment(this.props.activity?.createdAt).fromNow()}
                                    {")"}
                                </RegularText>
                            </RegularText>
                        </View>
                    }
                    right={
                        <View>
                            <ImageBase
                                style={{ height: 50, width: 50, borderRadius: 10 }}
                                uri={this.state.activityData?.asset?.assetOption?.image?.thumbnail}
                            ></ImageBase>
                        </View>
                    }
                >
                </Card>
                    }
                        {this.props.activity?.type == ActivityTypes.AssetDrop && (
                            <Card
                                onPress={this.openAsset}
                                icon={
                                    <View>
                                        <AvatarBase
                                            size={45}
                                            uri={this.state.activityData?.asset?.merchant?.image?.thumbnail || ""}
                                            alias={this.state.activityData?.asset?.merchant?.name || ""}
                                        ></AvatarBase>
                                        <View style={styles.secondaryAvatar}>
                                            <AvatarBase
                                                size={20}
                                                uri={this.props.client?.profileImagePath?.thumbnail}
                                                alias={this.props.client?.alias || ""}
                                            ></AvatarBase>
                                        </View>
                                    </View>
                                }
                                left={
                                    <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                        <RegularText numberOfLines={2} fontSize={14} style={{ marginBottom: 2 }}>
                                            {t("incredible")}{" "}
                                            <SemiBoldText fontSize={14}>
                                                {trimHelper(this.state.activityData?.asset?.merchant?.name, 10)}
                                            </SemiBoldText>{" "}
                                            {t("asigned_you")}{" "}
                                            <SemiBoldText fontSize={14}>
                                                {trimHelper(this.state.activityData?.asset?.assetOption?.name, 16)}{" "}
                                            </SemiBoldText>
                                            <RegularText fontSize={11}>
                                                <View style={{ width: 25, height: 15 }}>
                                                    <View style={{ position: "absolute", top: -4, left: 1 }}>
                                                        <LottieBase
                                                            height={22}
                                                            width={22}
                                                            loop={moment().diff(moment(this.props.activity?.createdAt), 'minutes') < 5}
                                                            source={require("../../../assets/animations/asset-2.json")}
                                                        />
                                                    </View>
                                                </View>
                                                {"ASSET"}
                                            </RegularText>
                                            <RegularText fontSize={11} color={colors.grey}>
                                                {"  ("}
                                                {moment(this.props.activity?.createdAt).fromNow()}
                                                {")"}
                                            </RegularText>
                                        </RegularText>
                                    </View>
                                }
                                right={
                                    <View>
                                        <ImageBase
                                            style={{ height: 50, width: 50, borderRadius: 10 }}
                                            uri={this.state.activityData?.asset?.assetOption?.image?.carousel}
                                        ></ImageBase>
                                    </View>
                                }
                            ></Card>
                        )}

                        {this.props.activity?.type == ActivityTypes.AssetTransfer && (
                            <Card
                                onPress={this.openAsset}
                                icon={
                                    <View>
                                        <AvatarBase
                                            size={45}
                                            uri={this.state.activityData.clientFrom?.profileImagePath?.thumbnail || ""}
                                            alias={this.state.activityData.clientFrom?.alias || ""}
                                        ></AvatarBase>
                                        <View style={styles.secondaryAvatar}>
                                            <AvatarBase
                                                size={20}
                                                uri={
                                                    this.state.activityData.clientTo?.profileImagePath?.thumbnail || ""
                                                }
                                                alias={this.state.activityData.clientTo?.alias || ""}
                                            ></AvatarBase>
                                        </View>
                                    </View>
                                }
                                left={
                                    <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                        {this.state.isOwner ? (
                                            <RegularText numberOfLines={1} fontSize={14} style={{ marginBottom: 2 }}>
                                                {t("you_send")}
                                                <SemiBoldText fontSize={14}>
                                                    {trimHelper(" @" + this.state.activityData?.clientTo?.alias, 15)}
                                                </SemiBoldText>{" "}
                                            </RegularText>
                                        ) : (
                                            <RegularText numberOfLines={1} fontSize={14} style={{ marginBottom: 2 }}>
                                                {t("you_receive")}
                                                <SemiBoldText fontSize={14}>
                                                    {trimHelper(" @" + this.state.activityData?.clientFrom?.alias, 15)}
                                                </SemiBoldText>{" "}
                                            </RegularText>
                                        )}

                                        <SemiBoldText numberOfLines={1} fontSize={14}>
                                            {trimHelper(this.state.activityData?.asset?.assetOption?.name, 12)}
                                            <RegularText fontSize={11}>
                                            <View style={{ width: 25, height: 15 }}>
                                                    <View style={{ position: "absolute", top: -4, left: 1 }}>
                                                        <LottieBase
                                                            height={22}
                                                            width={22}
                                                            loop={moment().diff(moment(this.props.activity?.createdAt), 'minutes') < 5}
                                                            source={require("../../../assets/animations/asset-2.json")}
                                                        />
                                                    </View>
                                                </View>
                                                {"ASSET"}
                                            </RegularText>
                                            <RegularText fontSize={11} color={colors.grey}>
                                                {"  ("}
                                                {moment(this.props.activity?.createdAt).fromNow()}
                                                {")"}
                                            </RegularText>
                                        </SemiBoldText>
                                    </View>
                                }
                                right={
                                    <View>
                                        <ImageBase
                                            style={{ height: 50, width: 50, borderRadius: 10 }}
                                            uri={this.state.activityData?.asset?.assetOption?.image?.carousel}
                                        ></ImageBase>
                                    </View>
                                }
                            ></Card>
                        )}
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    secondaryAvatar: {
        position: "absolute",
        bottom: -2,
        right: -4,
        padding: 2,
        borderRadius: 20,
        backgroundColor: colors.gradientFrom,
        zIndex: 999999
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityCardAsset = connect(mapStateToProps, mapDispatchToProps)(_ActivityCardAsset);

export default ActivityCardAsset;
