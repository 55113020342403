import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";
import { colors } from "@styles/globalStyles";
import Row from "@base/Row";
import RegularNumberInput from "@base/RegularNumberInput";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import PressableBase from "@base/PressableBase";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";

interface Props {
    amount?: number;
    currency: Currency;
    showSelector?: boolean;
    onPress?: () => void;
    onAmountChange?: (amount: number) => void;
}

interface State {
    amount: string;
    formatAmount: string;
    fiatAmount: number;
    onPress: () => void;
    onAmountChange?: (amount: number) => void;
    prev: string;
}

export default class ExchangeCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const amount = this.props.amount ? this.props.amount : 0;
        this.state = {
            onPress: this.props.onPress ? this.props.onPress : () => {},
            onAmountChange: this.props.onAmountChange ? this.props.onAmountChange : () => {},
            amount: amount.toString(),
            formatAmount: "",
            fiatAmount: 0,
            prev: "",
        };
    }

    componentDidMount = () => {
        this.updateAmount(this.state.amount);
    };

    componentDidUpdate(prevProps: Props) {
        let amount = this.props.amount.toString();
        if (prevProps.amount !== this.props.amount) {
            if (!this.props.amount && this.state.prev.includes(".")) {
                //Fix backspace change
                amount = this.state.prev;
            }
            this.updateAmount(amount);
        }
    }

    updateAmount = (value: string) => {
        if (!this.props.currency) {
            return;
        }
        const amount = parseFloat(value) || 0;
        const fiat = amount * this.props.currency.getFiat() || 0;
        this.setState({
            amount: value,
            formatAmount: this.props.currency.format(this.props.currency.toDecimals(amount)),
            fiatAmount: fiat,
            prev: value.toString(),
        });
        this.state.onAmountChange(amount);
    };

    render() {
        return (
            <>
                {this.props.currency && (
                    <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                        <View style={styles.left}>
                            <PressableBase onPress={this.state.onPress}>
                                <Row style={{ alignItems: "center" }}>
                                    <CurrencyIcon
                                        size={45}
                                        currency={this.props.currency}
                                        styles={{ marginRight: 15 }}
                                    ></CurrencyIcon>

                                    <Row
                                        style={{
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <View>
                                            <Row
                                                style={{
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                    justifyContent: "flex-start",
                                                }}
                                            >
                                                <BoldText fontSize={20}>{this.props.currency.getSymbol()}</BoldText>
                                                <NetworkCurrencySymbol
                                                    currency={this.props.currency}
                                                ></NetworkCurrencySymbol>
                                            </Row>

                                            <RegularText style={{ paddingTop: 5 }} color={colors.grey} fontSize={12}>
                                                {this.props.currency.getFormatBalance()}{" "}
                                            </RegularText>
                                        </View>

                                        {/* {this.props.showSelector && (
                                            <CircleButton
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                    backgroundColor: colors.tertiaryShadow,
                                                    marginLeft: 5,

                                                    marginBottom: 0,
                                                }}
                                                iconSize={15}
                                                icon={"arrow-down"}
                                                iconColor={colors.text}
                                                onPress={this.state.onPress}
                                            ></CircleButton>
                                        )} */}
                                    </Row>
                                </Row>
                            </PressableBase>
                        </View>
                        <View style={styles.right}>
                            <View>
                                <CurrencyBalanceIcon
                                    style={styles.balanceIcon}
                                    fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                                    amount={this.state.fiatAmount.toFixed(2)}
                                ></CurrencyBalanceIcon>
                            </View>
                            {this.props.onAmountChange && (
                                <RegularNumberInput
                                    fontSize={25}
                                    onChangeText={(v: string) => {
                                        this.updateAmount(v);
                                    }}
                                    value={this.state.amount}
                                    align="right"
                                    maxLength={8}
                                    fontWeight="bold"
                                    style={{ paddingRight: 5 }}
                                />
                            )}
                            {!this.props.onAmountChange && (
                                <BoldText
                                    fontSize={25}
                                    align="right"
                                    style={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        justifyContent: "flex-end",
                                        paddingRight: 5,
                                    }}
                                >
                                    {this.state.formatAmount}
                                </BoldText>
                            )}
                        </View>
                    </Row>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    left: {
        flex: 0.5,
    },
    right: {
        flex: 0.5,
        justifyContent: "flex-end",
        alignContent: "flex-end",
        alignItems: "flex-end",
    },
  
    leftDesc: {
        lineHeight: 20,
        paddingLeft: 2,
    },
    rightDesc: {
        textAlign: "right",
    },
    balanceIcon: {
        backgroundColor: colors.tertiaryShadow,
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 16,
        overflow: "hidden",
    },
});
