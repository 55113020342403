import React, { Component } from "react";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import SimpleCard from "@components/cards/SimpleCard";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { SeedNavigatorScreens } from "@navigation/SeedNavigator";
import { AuthNavigatorScreens } from "@navigation/AuthNavigator";
import { OAuthService } from "@core/services/oauth/OAuthService";
import Initializer from "@screens/initializer/Initializer";

interface Props {
    navigation: any;
}

interface State {}

const { t } = i18n;

export default class SeedScreen extends Component<Props, State> {
    focusListener: any;
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("accounts")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    {/* <SimpleCard
                        title={t("import")}
                        icon="paper-clip"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.Import.routeName);
                        }}
                    /> */}
                    <SimpleCard
                        title={t("seed_phrase")}
                        icon="key"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.Backup.routeName);
                        }}
                    />
                    {/* <SimpleCard
                        title={t("new_seed")}
                        icon="exchange"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.ResetSeed.routeName);
                        }}
                    /> */}
                    <SimpleCard
                        title={t("history")}
                        icon="clock-history"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.HistorySeed.routeName);
                        }}
                    />
                    <SimpleCard
                        title={t("remove_account")}
                        icon="person-x"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.RemoveSeed.routeName);
                        }}
                    />
                    <SimpleCard
                        title={t("restore_app")}
                        icon="trash"
                        onPress={() => {
                            this.props.navigation.navigate(SeedNavigatorScreens.RestoreApp.routeName);
                        }}
                    />

                    <SimpleCard
                        title={t("signout")}
                        icon="log-out"
                        onPress={async () => {
                            await OAuthService.getInstance().signout();
                            Initializer.redirect(this.props.navigation);
                        }}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}
