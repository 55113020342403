import Currency from "@core/currencies/Currency";
import store from "@store/index";
import { getEnv } from "@utils/helpers/global/global";
import axios from "axios";
import { Platform } from "expo-modules-core";
import { AuthService } from "./AuthService";
import Web3 from "web3";
import Wallet from "@core/wallet/Wallet";
const web3 = new Web3();
import { v4 as uuidv4 } from "uuid";
import { ApiService } from "./ApiService";

export class DigitalCurrenciesService {
    static url(endpoint: string, currency?: Currency) {
        if (!currency) {
            return `${getEnv("API_URL")}digital-currencies/v2/${endpoint}`;
        }
        let addresses = "";
      
        if (currency.isMultiFormat()) {
            try {
                let a = currency?.getFormats()?.map((f) => currency.getAddress({ format: f }));
                addresses = a.join();
            } catch {}
        } else {
            addresses = currency.getAddress();
        }
        return `${getEnv("API_URL")}digital-currencies/v2/${endpoint}?network=${Wallet.getInstance()
            .getNetworkType()
            .toLocaleLowerCase()}&blockchain=${currency.getBlockchain()}&symbol=${currency.getSymbol()}&address=${addresses}`;
    }

    static headers() {
        const headers = {
            wallet: getEnv("id"),
            platform: Platform.OS,
        };
        return headers;
    }

    static async post(endpoint: string, body: any, options?: any) {
        const url = this.url(endpoint);
        return axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
    }

    static async get(currency: Currency, endpoint: string, options?: any) {
        const url = this.url(endpoint, currency);

        const headers = await ApiService.getAuthHeaders();
        return axios.get(url, { headers: headers, ...options });
    }

    static async setEnabledCurrency(currency: Currency, enabled: boolean) {
        try {
            const body = { digitalCurrencyId: currency.getId(), enabled: enabled };

            const resp = await axios.post(`${getEnv("API_URL")}v3/client/digital-currency`, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            if (resp && resp?.data) {
                return resp?.data?.enabled;
            }
        } catch (e) {
            console.warn(e);
        }
    }
}
