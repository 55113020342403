import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { RedeemableModel, ExperienceStatus, RedeemableType, ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import RedeemableCard from "./RedeemableCard";
import { getRedeemableIcon } from "./helpers/redeemable.helper";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import FlatListBase from "@base/FlatListBase";
import RadiusButton from "@base/RadiusButton";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import ExperienceService from "@core/services/ExperienceService";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    type: RedeemableType;
    client: Client;
    experiences: ExperiencesRedux;
}

interface State { }

const { t } = i18n;

export class _RedeemableTypeSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.navigateToSection = this.navigateToSection.bind(this);
        this.filterAndSortData = this.filterAndSortData.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.state = {};
    }

    renderItem = ({ item }) => {
        return <RedeemableCard redeemable={item} navigation={this.props.navigation} />;
    };

    navigateToSection() {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.SectionRedeemable.routeName,
            params: { sectionType: this.props.type },
        });
    }

    filterAndSortData() {
        return this.props.experiences[this.props.type].docs?.filter((r: RedeemableModel) => {
            return !r.archivedAt && r.status !== ExperienceStatus.EXPIRED && r.status !== ExperienceStatus.USED;
        });
    }
    async loadMore() {
        await ExperienceService.getInstance().getRedeemables(this.props.type, true);
    }

    render() {
        return (
            <View>
                {this.props.experiences[this.props.type].paginator.totalDocs > 0 && (
                    <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                        <SectionTitle
                            onPress={this.navigateToSection}
                            title={t(this.props.type)}
                            icon={getRedeemableIcon(this.props.type)}
                            counter={this.props.experiences[this.props.type].paginator.totalDocs}
                            seeMore={true}
                            {...this.props}
                        ></SectionTitle>

                        {this.filterAndSortData().length == 0 && (
                            <View style={{ paddingRight: 20 }}>
                                <View
                                    style={{
                                        backgroundColor: colors.tertiaryShadow,
                                        borderRadius: settings.cardRadius,
                                        marginTop: 10,
                                        paddingHorizontal: 10,
                                        paddingVertical: 10,
                                    }}
                                >
                                    <View style={{ paddingBottom: 20 }}>
                                        <RegularText align="justify" fontSize={14}>
                                            {t("redeemables_warning", { type: this.props.type })}
                                        </RegularText>
                                    </View>
                                    <View style={{ position: "absolute", right: 10, bottom: 10 }}>
                                        <RadiusButton
                                            title={t("see_more")}
                                            style={{ paddingVertical: 2 }}
                                            fontSize={12}
                                            onPress={this.navigateToSection}
                                        />
                                    </View>
                                </View>
                            </View>
                        )}

                        <FlatListBase
                            data={this.filterAndSortData() || []}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                            initialNumToRender={2}
                            horizontal={true}
                            maxToRenderPerBatch={5}
                            showsHorizontalScrollIndicator={false}
                            //onEndReachedThreshold={1}
                            onEndReached={this.loadMore}
                        />
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const RedeemableTypeSection = connect(mapStateToProps, mapDispatchToProps)(_RedeemableTypeSection);

export default RedeemableTypeSection;
