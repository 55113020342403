import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { hidePopupMessage, showPopupMessage, showSnackbar } from "@store/actions/global";
import i18n from "@i18n/i18n";
import * as Updates from "expo-updates";
import { Platform } from "react-native";

const { t } = i18n;

export default class UpdateService {
    private static instance: UpdateService;

    constructor() {}

    static getInstance(): UpdateService {
        if (!UpdateService.instance) {
            UpdateService.instance = new UpdateService();
        }
        return UpdateService.instance;
    }

    async getUpdates(forceUpdate?) {
        try {
            if (Platform.OS !== "web" && forceUpdate) {
                const update = await Updates.checkForUpdateAsync();
                if (update.isAvailable) {
                    setTimeout(() => {
                        store.dispatch(
                            showPopupMessage({
                                type: "WARNING",
                                title: `${t("important")}!`,
                                message: t("app_updates"),
                                onPressAccept: () => this.updateApp(),
                            }),
                        );
                    }, 10000);
                }
            }
        } catch (error) {
            store.dispatch(hidePopupMessage());
            console.warn(error);
        }
    }

    async updateApp() {
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
        store.dispatch(hidePopupMessage());
    }
}
