import React, { Component } from "react";
import { FlatList, RefreshControl, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { colors } from "@styles/globalStyles";
import Card from "@base/Card";
import AvatarBase from "@components/avatar/AvatarBase";
import Row from "@base/Row";
import { nameSocialLengthHelper } from "@utils/helpers/social/social.helper";
import WalletConnectV2Service from "@core/services/WalletConnectV2Service";
import BoldText from "@base/BoldText";
import Wallet from "@core/wallet/Wallet";
import BotCard from "@base/BotCard";
import CircleButton from "@base/CircleButton";
import * as WebBrowser from "expo-web-browser";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    navigation: NavigationType;
    walletConnectSessions: Array<any>;
}

interface State {
    showBot: boolean;
}

const { t } = i18n;

export class _WalletConnectScreen extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);

        this.state = { showBot: true };
    }

    async componentDidMount() {
        setTimeout(() => {
            this.setState({ showBot: false });
        }, 10000);
    }

    openDapp(item) {
        WebBrowser.openBrowserAsync(item?.peer?.metadata?.url || "", { showInRecents: true });
    }

    renderItem(item) {
        return (
            <View key={item?.topic}>
                <Card
                    onPress={() => this.openDapp(item)}
                    onLongPress={() => this.disconnectDapp(item)}
                    left={
                        <View
                            style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Row>
                                <AvatarBase
                                    uri={item?.peer?.metadata?.icons[0]}
                                    alias={item?.peer?.metadata?.name}
                                    size={45}
                                    overlayContainerStyle={"transparent"}
                                />

                                <View style={{ marginLeft: 10 }}>
                                    <BoldText
                                        style={{
                                            textAlignVertical: "center",
                                        }}
                                    >
                                        {nameSocialLengthHelper(item?.peer?.metadata?.name, 25)}
                                    </BoldText>

                                    <Row style={{ paddingTop: 2 }}>
                                        {item?.namespaces?.eip155?.accounts.map((chain) => {
                                            const currency: Currency = Wallet.getInstance().findCurrencyByChainId(
                                                parseInt(chain.split(":")[1]),
                                            );
                                            return (
                                                <CurrencyIcon
                                                    styles={{ marginRight: 5 }}
                                                    size={18}
                                                    iconSize={10}
                                                    currency={currency}
                                                />
                                            );
                                        })}
                                    </Row>
                                </View>
                            </Row>
                        </View>
                    }
                    right={
                        <CircleButton
                            style={{ width: 30, height: 30, borderRadius: 15, marginBottom: 0 }}
                            iconSize={15}
                            icon={"trash"}
                            onPress={() => this.disconnectDapp(item)}
                        ></CircleButton>
                    }
                ></Card>
            </View>
        );
    }

    disconnectDapp(session) {
        WalletConnectV2Service.getInstance().disconnectDapp(session?.topic);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("wallet_connect")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    {this.state.showBot && (
                        <BotCard
                            title={t("wallet_connect_welcome")}
                            message={t("wallet_connect_welcome_message")}
                        ></BotCard>
                    )}
                    <FlatList
                        data={this.props.walletConnectSessions}
                        renderItem={({ item }) => this.renderItem(item)}
                        keyExtractor={({ item }) => `${Math.floor(Math.random() * 5000)}`}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        refreshControl={<RefreshControl tintColor={colors.text} refreshing={false} />}
                        initialNumToRender={10}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletConnectSessions: state.walletconnect.connections,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const WalletConnectScreen = connect(mapStateToProps, mapDispatchToProps)(_WalletConnectScreen);

export default WalletConnectScreen;

const styles = StyleSheet.create({});
