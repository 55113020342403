import React, { Component } from "react"
import { FlatList, StyleSheet,  View } from "react-native"
import { Header } from "@components/header/Header"
import Container from "@base/Container"
import { HeaderType } from "@custom-types/HeaderType"
import { NavigationType } from "@custom-types/NavigationType"
import { connect } from "react-redux"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator"
import { CoincaexBankAccount, CoincaexSell } from "../../helper/coincaex.helper"
import { Route } from "@react-navigation/native"
import BotCard from "@base/BotCard"
import CircleButton from "@base/CircleButton"
import { CoincaexService } from "@core/services/CoincaexService"
import store from "@store/index"
import { prePrepareTransaction } from "@store/actions/wallet"
import CardButton from "@base/CardButton"
import BankCardComponent from "../../components/BankCardComponent"
import PressableBase from "@base/PressableBase"


interface Props {
    navigation: NavigationType;
    route: Route<string, { data: CoincaexSell, fiatCurrency: string }>;
}

interface State {
    selectedBank: CoincaexBankAccount;
    banks: Array<CoincaexBankAccount>;
    fiatCurrency: string
    address: string
}

const { t } = i18n

export class _CoincaexSellBanksScreen extends Component<Props, State> {
    focusListener: any

    constructor(props: Props) {
        super(props)

        this.state = {
            selectedBank: {
                bankName: '',
                bankAccountCurrency: '',
                bankAccountType: '',
                bankAccountName: '',
                bankAccountNumber: '',
            },
            banks: [],
            fiatCurrency: this.props.route?.params?.fiatCurrency,
            address: this.props.route?.params?.data?.wallet
        }
    }

    async componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", this.onFocus)
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove()
        } catch (e) { }
    }

    onFocus = async () => {
        const banks = await CoincaexService.getInstance().getBankListUser();
        const bankList = banks.Banks.filter((b) => b.bankAccountCurrency == this.state.fiatCurrency)
        if (banks) {
            this.setState({ banks: [...bankList], });
        }
    }

    handleSubmit = async () => {
        let data: CoincaexSell = this.props.route.params?.data;

        if (data && this.state.selectedBank) {
            data.bankAccountCurrency = this.state.selectedBank?.bankAccountCurrency
            data.bankAccountName = this.state.selectedBank?.bankAccountName
            data.bankAccountNumber = this.state.selectedBank?.bankAccountNumber
            data.bankAccountType = this.state.selectedBank?.bankAccountType
            data.bankName = this.state.selectedBank?.bankName

            store.dispatch(
                prePrepareTransaction({
                    address: this.state.address || '',
                    amount: data.amount,
                }),
            );

            this.props.navigation.navigate(CoincaexNavigatorScreens.Send.routeName, { data: data });
        }
    };

    renderItem = ({ item }) => {
        return (
            <View style={{ flex: 1, marginBottom: 10 }}>
                <View key={item.Number}>
                    <PressableBase
                        style={{
                            opacity: item.bankAccountNumber == this.state.selectedBank?.bankAccountNumber ? 1 : 0.6,
                        }}
                        onPress={() => this.setState({ selectedBank: item })}
                    >
                        <BankCardComponent bank={item} />
                    </PressableBase>
                    {item.bankAccountNumber == this.state.selectedBank?.bankAccountNumber && (
                        <View style={styles.selectButton}>
                            <CircleButton
                                style={{ height: 40, width: 40, borderRadius: 20 }}
                                iconSize={15}
                                icon="right"
                                onPress={this.handleSubmit}
                            />
                        </View>
                    )}
                </View>
            </View>
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("banks")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <View style={{ flex: 1, marginBottom: 10 }}>
                        <BotCard message={this.state.banks.length ? t("select_receive_bank") : t("bank_message")} />
                        <FlatList
                            data={this.state.banks}
                            keyExtractor={(item) => item.bankAccountNumber}
                            renderItem={this.renderItem}
                            ListFooterComponent={
                                <CardButton
                                    title={t("add_bank")}
                                    style={{ marginBottom: 20 }}
                                    onPress={() =>
                                        this.props.navigation.navigate(CoincaexNavigatorScreens.AddBankSell.routeName)
                                    }
                                />
                            }
                        />
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    detailContainer: {
        justifyContent: "flex-start",
        width: "100%",
        marginBottom: 5
    },
    gapRow: {
        paddingRight: 5
    },
    selectButton: {
        position: "absolute",
        right: 5,
        bottom: 5,
        zIndex: 9999
    },
    titleRow: {
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        marginBottom: 10,
    }
})

const mapStateToProps = (state) => {
    return state.wallet
}

const mapDispatchToProps = (dispatch) => ({})

const CoincaexSellBanksScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexSellBanksScreen)

export default CoincaexSellBanksScreen
