import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import BoldText from "@base/BoldText";
import { HeaderType } from "@custom-types/HeaderType";
import { SimpleLineIcons } from "@expo/vector-icons";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import Logo from "@assets/images/Logo";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import Icon from "@components/icons";
import { connect } from "react-redux";
import { hideActions } from "@store/actions/header";
import store from "@store/index";
import Row from "@base/Row";

import AvatarBase from "@components/avatar/AvatarBase";
import Constants from "expo-constants";
import Badge from "@base/Badge";
import { NotificationModel } from "@custom-types/NotificationModel";
import { countUnreadNotification } from "@screens/notifications/notification.helper";
import Currency from "@core/currencies/Currency";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import PressableBase from "@base/PressableBase";

export interface IHeaderBtn {
    icon: string;
    onPress: () => void;
    iconType?: string;
    size?: number;
}

export interface IHeaderTitleBtn {
    title?: string;
    onPress?: () => void;
}

export interface IHeaderAvatar {
    uri: string;
    onPress?: () => void;
    alias?: string;
    size?: number;
}

interface Props {
    title?: string;
    label?: React.JSX.Element;
    connected?: boolean;
    titleBtn?: IHeaderTitleBtn;
    type?: HeaderType;
    navigation: NavigationType;
    rightBtn?: IHeaderBtn[] | IHeaderBtn;
    leftBtn?: IHeaderBtn;
    rigthAvatar?: IHeaderAvatar;
    leftAvatar?: IHeaderAvatar;
    titleAvatar?: IHeaderAvatar;
    showVersion?: boolean;
    subtitle?: string;
    showActions?: boolean;
    actions?: IHeaderBtn[];
    backTo?: string;
    hideReturn?: boolean;
    children?: any;
    currency?: Currency;
    onPressCurrency?: () => void;
    onPressCurreny?: () => void;
    hideActions: () => void;
    hideActionsCallback?: () => void;
    notifications?: NotificationModel[];
}

interface State {
    type: HeaderType;
    back: boolean;
    color: string;
    hideActionsCallback: () => void;
    rightButton: IHeaderBtn[];
    notificationCounter: number;
}

class _HeaderButtons extends Component<Props, State> {
    private deep = 0;
    private bottom = 4;

    constructor(props: Props) {
        super(props);
        const parent = this.props.navigation.getParent();
        const back = parent && parent.canGoBack();

        this.props.navigation.addListener('beforeRemove', (e) => {
            if (back) {
                if (this.props.backTo) {
                    e.preventDefault();
                    this.props.navigation.navigate(this.props.backTo)
                }
            } else {
                e.preventDefault();
            }

            return true
        });

        this.state = {
            type: this.props.type ? this.props.type : HeaderType.Normal,
            back: back,
            color: colors.text,
            hideActionsCallback: this.props.hideActionsCallback || (() => { }),
            rightButton: Array.isArray(this.props.rightBtn) ? this.props.rightBtn : [this.props.rightBtn],
            notificationCounter: 2,
        };
    }

    goBack() {
        if (this.props.showActions) {
            this.props.hideActions();
            this.state.hideActionsCallback();
        } else {
            this.props.navigation.goBack();
        }
    }

    render() {
        return (
            <Container style={{ paddingVertical: 0, marginVertical: 0 }}>
                <View style={styles.buttons}>
                    <View style={styles.left}>
                        {((this.state.back && !this.props.leftBtn) || this.props.showActions || this.props.backTo) &&
                            !this.props.leftAvatar &&
                            !this.props.hideReturn && (
                                <PressableBase
                                    onPress={
                                        this.props.backTo
                                            ? () => this.props.navigation.navigate(this.props.backTo)
                                            : () => this.goBack()
                                    }
                                    onPressPriority={"half"}
                                    style={styles.buttonContainer}
                                    accessibilityLabel="back-button"
                                >
                                    <Icon name="arrow-left" size={24} color={this.state.color} />
                                </PressableBase>
                            )}
                        {this.props.leftBtn &&
                            !this.props.showActions &&
                            !this.props.leftBtn.iconType &&
                            !this.props.leftAvatar && (
                                <PressableBase
                                    onPress={this.props.leftBtn.onPress}
                                    style={styles.buttonContainer}
                                    accessibilityLabel="header-left-button"
                                >
                                    <Icon
                                        name={this.props.leftBtn.icon}
                                        size={this.props.leftBtn.size ? this.props.leftBtn.size : 24}
                                        color={this.state.color}
                                        align="right"
                                    />
                                </PressableBase>
                            )}
                        {this.props.leftBtn &&
                            !this.props.showActions &&
                            !this.props.leftAvatar &&
                            this.props.leftBtn.iconType &&
                            this.props.leftBtn.iconType == "custom" && (
                                <PressableBase
                                    onPress={this.props.leftBtn.onPress}
                                    style={styles.buttonContainer}
                                    accessibilityLabel="header-left-button"
                                >
                                    <Icon
                                        name={this.props.leftBtn.icon}
                                        size={this.props.leftBtn.size ? this.props.leftBtn.size : 24}
                                        color={this.state.color}
                                        align="right"
                                    />
                                </PressableBase>
                            )}

                        {this.props.leftAvatar && (
                            <View style={styles.buttonContainer}>
                                <AvatarBase
                                    uri={this.props.leftAvatar.uri}
                                    alias={this.props.leftAvatar.alias}
                                    size={this.props.leftAvatar.size}
                                    isOwner={true}
                                    onPress={this.props.leftAvatar.onPress}
                                />
                            </View>
                        )}
                    </View>
                    <View style={styles.center}>
                        {this.props.title && !this.props.titleBtn && (
                            <View>
                                <BoldText
                                    align="center"
                                    numberOfLines={1}
                                    style={styles[this.state.type + "Title"]}
                                    fixedWidth={180}
                                >
                                    {this.props.title}

                                    {this.props.connected && (
                                        <View
                                            style={{
                                                width: 18,
                                                height: 18,
                                                alignContent: "flex-end",
                                                alignItems: "flex-end",
                                            }}
                                        >
                                            <View
                                                style={{
                                                    height: 12,
                                                    width: 12,
                                                    borderRadius: 6,
                                                    backgroundColor: colors.green,
                                                }}
                                            ></View>
                                        </View>
                                    )}
                                </BoldText>
                                {this.props.label && this.props?.label}
                            </View>
                        )}

                        {this.props.titleBtn && (
                            <PressableBase
                                onPress={this.props.titleBtn.onPress ? this.props.titleBtn.onPress : () => null}
                            >
                                <Row>
                                    {this.props.titleAvatar && (
                                        <View style={{ paddingRight: 5 }}>
                                            <AvatarBase
                                                uri={this.props.titleAvatar?.uri}
                                                alias={this.props.titleAvatar?.alias}
                                                size={this.props.titleAvatar?.size}
                                                onPress={this.props.titleAvatar?.onPress}
                                            />
                                        </View>
                                    )}
                                    <View>
                                        <BoldText align="center" numberOfLines={1} >
                                            {this.props.titleBtn.title}
                                        </BoldText>
                                    </View>
                                </Row>
                            </PressableBase>
                        )}

                        {!this.props.title && !this.props.titleBtn && (
                            <View style={{ justifyContent: "center", height: 50 }}>
                                <View>
                                    <Logo fill={colors.text} />
                                </View>
                            </View>
                        )}
                        {this.props.children}
                    </View>
                    <View style={[styles.right, { flex: this.state.rightButton?.length > 2 ? 0.4 : 0.2 }]}>
                        {/* {!this.props.showActions && this.props.rightBtn && !this.props.rigthAvatar && (
                            <PressableBase onPress={this.props.rightBtn.onPress} style={styles.buttonContainer}>
                                {this.props.rightBtn.iconType && this.props.rightBtn.iconType == "custom" && (
                                    <Icon
                                        name={this.props.rightBtn.icon}
                                        size={this.props.rightBtn.size ? this.props.rightBtn.size : 24}
                                        color={this.state.color}
                                        align="right"
                                    />
                                )}
                                {!this.props.rightBtn.iconType && (
                                    <Icon
                                        name={this.props.rightBtn.icon}
                                        size={this.props.rightBtn.size ? this.props.rightBtn.size : 24}
                                        color={this.state.color}
                                        align="right"
                                    />
                                )}
                            </PressableBase>
                        )} */}

                        {!this.props.showActions &&
                            !this.props.rightBtn &&
                            !this.props.rigthAvatar &&
                            this.props.currency && (
                                <View style={styles.buttonContainer}>
                                    <PressableBase
                                        onPress={this.props.onPressCurrency}
                                        style={styles.rightButtonContainer}
                                        accessibilityLabel="header-right-button"
                                    >
                                        <CurrencyIcon
                                            size={35}
                                            iconSize={20}
                                            currency={this.props.currency}
                                        ></CurrencyIcon>
                                    </PressableBase>
                                </View>
                            )}

                        {!this.props.showActions && !this.props.rightBtn && this.props.rigthAvatar && (
                            <View style={styles.buttonContainer}>
                                <AvatarBase
                                    uri={this.props.rigthAvatar.uri}
                                    alias={this.props.rigthAvatar.alias}
                                    size={this.props.rigthAvatar.size}
                                    isOwner={true}
                                    onPress={this.props.rigthAvatar.onPress}
                                />
                            </View>
                        )}

                        {this.state.rightButton &&
                            this.state.rightButton[0] &&
                            this.state.rightButton.map((btn) => {
                                return (
                                    <PressableBase
                                        key={btn.icon}
                                        onPress={btn.onPress}
                                        style={styles.rightButtonContainer}
                                        accessibilityLabel="header-right-button"
                                    >
                                        {btn.iconType && btn.iconType == "custom" && (
                                            <Icon
                                                name={btn.icon}
                                                size={btn.size ? btn.size : 22}
                                                color={this.state.color}
                                                align="right"
                                            />
                                        )}
                                        {!btn.iconType && (
                                            <View>
                                                <Icon
                                                    name={btn.icon}
                                                    size={btn.size ? btn.size : 22}
                                                    color={this.state.color}
                                                    align="right"
                                                />
                                                {this.state.rightButton &&
                                                    //this.state.rightButton[1] &&
                                                    btn.icon === "bell" && (
                                                        <Badge
                                                            size={18}
                                                            count={countUnreadNotification(
                                                                this.props.notifications || [],
                                                            )}
                                                            color={colors.complementary || colors.secondary}
                                                        />
                                                    )}
                                            </View>
                                        )}
                                    </PressableBase>
                                );
                            })}

                        {this.props.showActions && this.props.actions && this.props.actions[0] && (
                            <Row>
                                {this.props.actions.map((action) => (
                                    <PressableBase
                                        onPress={() => {
                                            store.dispatch(hideActions());
                                            action.onPress();
                                        }}
                                        style={styles.buttonContainer}
                                        key={action.icon}
                                    >
                                        {action.iconType && action.iconType == "custom" && (
                                            <Icon
                                                name={action.icon}
                                                size={action.size ? action.size : 24}
                                                color={this.state.color}
                                                align="right"
                                            />
                                        )}
                                        {/* {!action.iconType && (
                                            <Icon
                                                name={action.icon}
                                                size={action.size ? action.size : 20}
                                                color={this.state.color}
                                                align="right"
                                            />
                                        )} */}
                                    </PressableBase>
                                ))}
                            </Row>
                        )}
                    </View>
                </View>
            </Container>
        );
    }
}

const styles = StyleSheet.create({
    buttons: {
        marginLeft: -15,
        marginRight: -15,
        alignContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        minHeight: 90,
        paddingVertical: 20,
        flex: 1,
    },
    buttonContainer: {
        borderRadius: 25,
        width: 50,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
    },
    rightButtonContainer: {
        borderRadius: 25,
        width: 40,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
    },
    left: {
        flex: 0.2,
    },
    center: {
        //backgroundColor: colors.yellow,
        flex: 0.6,
        alignItems: "center",
        minHeight: 50,
        justifyContent: "center",
    },
    right: {
        flex: 0.2,
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
    },
    normalTitle: {
        display: "flex",
        color: "white",
        fontSize: 18,
        marginVertical: 10,
    },
    lightTitle: {
        display: "flex",
        alignItems: "center",
        fontSize: 20,
        flex: 1,
        flexWrap: "nowrap",
        marginVertical: 10,
    },
});

const mapStateToProps = (state) => {
    return {
        showActions: state.header.showActions,
        notifications: state.notificationCenter?.notifications,
    };
};

const mapDispatchToProps = (dispatch) => ({
    hideActions: () => dispatch(hideActions()),
});

const HeaderButtons = connect(mapStateToProps, mapDispatchToProps)(_HeaderButtons);

export default HeaderButtons;
