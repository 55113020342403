import React, { Component } from "react";
import { ActivityIndicator, Platform, StyleSheet, View } from "react-native";

import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import { ready } from "@store/actions/global";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import { BackHandler } from "react-native";
import {
    SkypeIndicator
} from "react-native-indicators";
import { connect } from "react-redux";

interface State {
    reload: boolean;
}

interface Props {
    loading?: boolean;
    currentTask?: string;
    progresive_loader?: boolean;
    progresive_loader_value?: number;
    messageLoader?: string;
    priorityLoading?: boolean;
}

export class _Loader extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            reload: true,
        };
        if (Platform.OS != "web") {
            BackHandler.addEventListener("hardwareBackPress", () => this.props.loading);
        }
    }

    hideLoader() {
        store.dispatch(ready());
    }

    render() {
        return (
            <View style={(this.props.loading || this.props.priorityLoading) ? styles.wrapper : styles.hidden}>
                <View style={styles.wrapper && styles.background}></View>

                <PressableBase
                    onLongPress={this.hideLoader}
                    style={{
                        flex: 1,
                        width: "100%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <View style={{ height: 150 }}>
                        {Platform.OS == "web" ? (
                            <View style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                                {this.props.progresive_loader && (
                                    <RegularText
                                        align="center"
                                        fontSize={10}
                                        color={colors.text}
                                        style={{ position: "absolute", top: 25 }}
                                    >
                                        {`${
                                            this.props.progresive_loader_value > 100
                                                ? 100
                                                : this.props.progresive_loader_value
                                        }%`}
                                    </RegularText>
                                )}
                                <ActivityIndicator size={60} color={colors.text} />
                            </View>
                        ) : (
                            <View style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                                {this.props.progresive_loader && (
                                    <RegularText
                                        align="center"
                                        fontSize={10}
                                        color={colors.text}
                                        style={{ position: "absolute", top: 68 }}
                                    >
                                        {`${
                                            this.props.progresive_loader_value > 100
                                                ? 100
                                                : this.props.progresive_loader_value
                                        }%`}
                                    </RegularText>
                                )}
                                <SkypeIndicator size={80} animationDuration={2000} color={colors.text} />
                            </View>
                        )}
                        {
                            <RegularText align="center" color={colors.text} style={{ marginTop: 20 }}>
                                {this.props.messageLoader}
                            </RegularText>
                        }
                    </View>
                    {this.props.progresive_loader && (
                        <View
                            style={{
                                width: "100%",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <View style={styles.progressBarWrapper}>
                                <View
                                    style={[styles.progressBar, { width: `${this.props.progresive_loader_value}%` }]}
                                ></View>
                            </View>
                        </View>
                    )}
                </PressableBase>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
    },
    background: {
        position: "absolute",
        backgroundColor: colors.background,
        opacity: 0.8,
        height: "100%",
        width: "100%",
    },
    progressBarWrapper: {
        height: 10,
        borderRadius: settings.cardRadius,
        width: "80%",
        maxWidth: 450,
        backgroundColor: colors.secondaryShadow,
        justifyContent: "center",
        overflow: "hidden",
    },
    progressBar: {
        height: 12,
        borderRadius: settings.cardRadius,
        backgroundColor: colors.text,
    },
});

const mapStateToProps = (state) => {
    return state.global;
};

const mapDispatchToProps = (dispatch) => ({});

const Loader = connect(mapStateToProps, mapDispatchToProps)(_Loader);

export default Loader;
