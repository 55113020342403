import React from "react";
import { _FooterButtons } from "./FooterButtons";
import Row from "@base/Row";
import { FooterButton } from "./FooterButton";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { FooterFavButton } from "./FooterFavButton";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import store from "@store/index";
import { hideSidebar, showSidebar } from "@store/actions/global";
import { connect } from "react-redux";


export class _FooterButtonCrypto extends _FooterButtons {
    render() {
        return (
            <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
                <FooterButton icon={"house1"} screen={TabsNavigatorScreens.Home.routeName} {...this.props} />

                {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                    <FooterButton
                        icon={"wallet"}
                        screen={TabsNavigatorScreens.Wallets.routeName}
                        {...this.props}
                    />
                )}

                <FooterFavButton icon={"qr"} {...this.props} />

                {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                    <FooterButton
                        unreadMessages={this.props.unread}
                        icon={"chat1"}
                        screen={TabsNavigatorScreens.Messages.routeName}
                        {...this.props}
                    />
                )}

                <FooterButton
                    icon={"menu"}
                    isCurrent={this.props.showSidebar}
                    onPress={() =>
                        this.props.showSidebar ? store.dispatch(hideSidebar()) : store.dispatch(showSidebar())
                    }
                    {...this.props}
                />
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unread: state.chat.unread,
        loading: state.global.loading,
        showSidebar: state.global.showSidebar,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const FooterButtonCrypto = connect(mapStateToProps, mapDispatchToProps)(_FooterButtonCrypto);

export default FooterButtonCrypto;