import Wallet from "@core/wallet/Wallet";
import { getEnv } from "@utils/helpers/global/global";
import { ApiService } from "./ApiService";
import axios from "axios";
import Currency from "@core/currencies/Currency";


export default class BalanceService {
    async syncBalance(wallet: Wallet) {
        try {
            const params = await getParams();
            const url = `${getEnv("API_URL")}digital-currencies/v2/balances/all${params}`;
            const result = await axios.get(url, { headers: await ApiService.getAuthHeaders() });
            const currenciesBalances = result?.data || [];

            currenciesBalances.map((c) => {
                let currency: Currency = Wallet.getInstance().findCurrencyById(c.digitalCurrencyId);
                if (currency) {
                    currency.setBalance(c.balance);
                }
            });
        } catch (e) {}
    }
}

const getParams = async () => {
    let params = "";
    try {
    
    let currencies: Array<Currency> = Wallet.getInstance().getCurrencies();
    currencies = currencies?.filter((c) => {
        return c.isMultiFormat();
    });

    if (currencies?.length) {
        params = "?";
        currencies?.map((c) => {
            params = `${params}blockchain=${c.getBlockchain()?.toLowerCase()}&symbol=${c.getSymbol()}&address=${
                c?.getFormats()?.map((f) => c.getAddress({ format: f }))?.join()}`;
        });
     
    }

   

    return params;
} catch {
    return params;
}
};

// ?blockchain=bitcoin&address=1PuJjnF476W3zXfVYmJfGnouzFDAXakkL4,bc1qh0ytheqaj2gg2mh7uwgm7fgl9ssluk0zgmfq79&symbol=btc
