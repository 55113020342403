import React, { Component } from "react";
import { StyleSheet, View, TextStyle, ViewStyle } from "react-native";
import RegularText from "./RegularText";
import { colors, settings } from "@styles/globalStyles";
import GradientWrapper from "@components/wrapper/GradientWrapper";
import Icon from "@components/icons";
import PressableBase from "./PressableBase";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";

interface Props {
    title: string;
    style?: TextStyle | ViewStyle | Array<ViewStyle>;
    align?: "auto" | "left" | "right" | "center" | "justify";
    fontSize?: number;
    color?: string;
    textColor?: string;
    icon?: string;
    iconSize?: number;
    onPressPriority?: "low" | "half" | "high";
    vibrate?: boolean;
    onPress: () => void;
    onPressIn?: () => void;
    onPressOut?: () => void;
}

interface State {
    inlineStyles: TextStyle;
}

export default class InlineButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            inlineStyles: {},
        };
    }

    addStyles: () => TextStyle = () => ({});

    render() {
        return (
            <GradientWrapper disable={true} style={[styles.defaultStyles, this.props.style, this.addStyles()]}>
                <PressableBase
                    onPressIn={this.props.onPressIn}
                    onPressOut={this.props.onPressOut}
                    onPress={this.props.onPress}
                    style={{ padding: 10 }}
                    accessibilityLabel="inline-button"
                    onPressPriority={this.props.onPressPriority}
                    vibrate={this.props.vibrate}
                >
                    <RegularText color={this.props.textColor || colors.inlineButtonText || colors.white} align="center">
                        {this.props.icon && (
                            <Icon name={this.props.icon} size={this.props.iconSize || 14} style={{ marginRight: 5 }} />
                        )}{" "}
                        {this.props.title}{" "}
                    </RegularText>
                </PressableBase>
            </GradientWrapper>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        backgroundColor: colors.inlineButtonBackground,
        height: 46,
        borderRadius: settings.cardRadius,
        marginHorizontal: 5,
        minWidth: 80,
        justifyContent: "center",
    },
});
