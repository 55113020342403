import BoldText from "@base/BoldText";
import Card from "@base/Card";
import Container from "@base/Container";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import { Header } from "@components/header/Header";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import ActivityService from "@core/services/ActivityService";
import { WalletService } from "@core/services/WalletService";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { Route } from "@react-navigation/native";
import { showPopup, showPopupMessage } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import BigNumber from "bignumber.js";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
    fiatCurrency: FiatCurrency;
    currentOperation: any;
    customMessage: CustomMessageModel;
    selectedChat: any;
    onSuccess: boolean;
    route: Route<string, { toClient?: Partial<Client> }>;
    sendingToClient: Partial<Client>;
}

interface State {
    amount: string;
    sendingTo: string;

    sendingFrom: string;
    fee: number;
    isVerified: boolean;
    underlyingCurrency: Currency;
}

const { t } = i18n;

export class _ConfirmSendScreen extends Component<Props, State> {
    private currency: Currency;
    private fiatCurrency: FiatCurrency;
    private swipeVerify: any;

    constructor(props: Props) {
        super(props);
        this.currency = Wallet.getInstance().findCurrencyById(props.selectedCurrency);

        this.fiatCurrency = props.fiatCurrency;
        const skeleton = this.currency.getImplementation().parseSkeleton(props.currentOperation.skeleton);

        this.state = {
            amount: new BigNumber(skeleton.amount).toString(),
            sendingTo: skeleton.sendingTo,

            sendingFrom: skeleton.sendingFrom,
            fee: skeleton.fee,
            isVerified: false,

            underlyingCurrency: this.currency.isSmart() ? this.currency : this.currency.getUnderlyingCurrency(),
        };

        this.onConfirm = this.onConfirm.bind(this);
    }

    onConfirm() {
        this.setState({ isVerified: true });

        WalletService.getInstance().confirmTransaction({
            currency: this.currency,
            skeleton: this.props.currentOperation.skeleton,
            onSuccess: (data) => {
                WalletService.getInstance().getTransactions(this.currency);

                store.dispatch(showPopup({ type: "SUCCESS", message: t("transaction_success") }));
                ActivityService.getInstance().getActivity(true);
                if (this.handleOnSuccessNavigate(data)) {
                    return;
                }

                this.props.navigation.navigate(WalletNavigatorScreens.Wallet.routeName);
            },
            onError: (error) => {
                console.warn(error);
                store.dispatch(
                    showPopupMessage({
                        type: "ERROR",
                        message: t("an_error_has_occurred"),
                    })
                );
                this.setState({ isVerified: false });
                try {
                    this.swipeVerify.reset();
                } catch (e) {
                    console.warn(e);
                }
            },
        });
    }

    handleOnSuccessNavigate = (data) => {
        return false;
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("confirm_transaction")} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    <Card>
                        <View
                            style={{
                                width: "100%",
                                borderBottomColor: colors.grey,
                                borderBottomWidth: 0.5,
                                paddingBottom: 20,
                                alignItems: "flex-start"
                            }}
                        >
                            <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                {t("amount")}
                            </RegularText>

                            <CurrencyBalanceIcon
                                amount={this.state.amount}
                                fontSize={16}
                                iconSize={20}
                                fontWeight={"bold"}
                                currency={this.currency}
                                style={{ paddingBottom: 10 }}
                            />

                            <View style={{ alignItems: "flex-start" }}>
                                <CurrencyBalanceIcon
                                    style={{
                                        opacity: 0.9,
                                        backgroundColor: colors.tertiaryShadow,
                                        paddingHorizontal: 6,
                                        paddingVertical: 4,
                                        borderRadius: 10,
                                        overflow: "hidden",
                                    }}
                                    fiatCurrency={this.fiatCurrency}
                                    amount={this.currency.toFiat(Number(this.state.amount))}
                                />
                            </View>
                        </View>

                        <View
                            style={{
                                width: "100%",
                                marginBottom: 20,
                                borderBottomColor: colors.grey,
                                borderBottomWidth: 0.5,
                                paddingVertical: 20,
                                alignItems: "flex-start",
                            }}
                        >
                            <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                {t("fee")}
                            </RegularText>
                            <CurrencyBalanceIcon
                                amount={new BigNumber(this.state.fee || 0).toString()}
                                fontSize={16}
                                iconSize={20}
                                fontWeight={"bold"}
                                currency={this.state.underlyingCurrency}
                                style={{ paddingBottom: 10 }}
                            />

                            <View style={{ alignItems: "flex-start" }}>
                                <CurrencyBalanceIcon
                                    style={{
                                        opacity: 0.9,
                                        backgroundColor: colors.tertiaryShadow,
                                        paddingHorizontal: 6,
                                        paddingVertical: 4,
                                        borderRadius: 10,
                                        overflow: "hidden",
                                    }}
                                    fiatCurrency={this.fiatCurrency}
                                    amount={this.state.underlyingCurrency.toFiat(Number(this.state.fee))}
                                />
                            </View>
                        </View>

                        <View style={{ width: "100%" }}>
                            <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                {t("target_wallet")}
                            </RegularText>
                            {this.props.route?.params?.toClient ? (
                                <Row style={{ width: "100%" }}>
                                    <AvatarBase
                                        alias={this.props.route?.params?.toClient?.alias}
                                        uri={this.props.route?.params?.toClient?.profileImagePath?.thumbnail}
                                        size={45}
                                    ></AvatarBase>

                                    <View style={{ paddingLeft: 10 }}>
                                        <BoldText color={colors.text}>
                                            {"@"}
                                            {trimHelper(this.props.route?.params?.toClient?.alias, 30, true)}
                                        </BoldText>
                                        <Row style={{ paddingTop: 5, alignItems: "center" }}>
                                            <CurrencyIcon
                                                size={16}
                                                iconSize={10}
                                                currency={this.currency}
                                            ></CurrencyIcon>
                                            <RegularText color={colors.text} align="left" selectable={true}>
                                                {" "}
                                                {trimHelper(this.state.sendingTo, 30, true)}
                                            </RegularText>
                                        </Row>
                                    </View>
                                </Row>
                            ) : (
                                <View
                                    style={{
                                        width: "100%",
                                        borderBottomColor: colors.grey,
                                        borderBottomWidth: 0.5,
                                        paddingBottom: 20,
                                    }}
                                >
                                    <RegularText color={colors.text} style={{ marginBottom: 10 }}>
                                        {t("sending_to")}
                                    </RegularText>
                                    <BoldText color={colors.text} style={{ marginBottom: 5 }} fixedWidth={250}>
                                        {this.state.sendingTo}
                                    </BoldText>
                                </View>
                            )}
                        </View>
                    </Card>

                    <Row>
                        <View style={styles.swipContainer}>
                            <InlineButton
                                onPressPriority={"low"}
                                onPress={this.onConfirm}
                                title={t("confirm_transaction")}
                                vibrate={true}
                            />
                            {/* {Platform.OS !== "web" ? (
                                <RNSwipeVerify
                                    ref={(ref) => (this.swipeVerify = ref)}
                                    width="100%"
                                    buttonSize={50}
                                    borderColor={colors.secondary}
                                    buttonColor={this.state.isVerified ? colors.secondary : colors.secondary}
                                    backgroundColor={colors.background}
                                    textColor={colors.white}
                                    borderRadius={50}
                                    okButton={{ visible: true, duration: 400 }}
                                    onVerified={this.onConfirm}
                                    icon={
                                        <Icon
                                            name={this.state.isVerified ? "check" : "right"}
                                            size={28}
                                            color={colors.white}
                                        
                                            style={{}}
                                        />
                                    }
                                >
                                    <RegularText color={colors.text}>
                                        {" "}
                                        {this.state.isVerified ? t("confirmed") : t("slide")}
                                    </RegularText>
                                </RNSwipeVerify>
                            ) : (
                                <InlineButton onPress={this.onConfirm} title={t("confirm_transaction")} />
                            )} */}
                        </View>
                    </Row>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    swipContainer: {
        flex: 1,
        height: 58,
    },
});

const mapStateToProps = (state) => {
    return {
        currencies: state.wallet.currencies,
        selectedCurrency: state.wallet.selectedCurrency,
        fiatCurrency: state.wallet.fiatCurrency,
        currentOperation: state.wallet.currentOperation,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmSendScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmSendScreen);

export default ConfirmSendScreen;
