import React, { Component } from "react";
import i18n from "@i18n/i18n";
import { importer } from "./helpers/importer";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import { FooterButton } from "@components/footer/FooterButton";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { NavigationType } from "@custom-types/NavigationType";

interface Props {
    navigation: NavigationType;
}

interface State { }

const { t } = i18n;

class DefaultCustomFotterButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                {ModuleControlService.getInstance().isModuleEnabled(Modules.socialNetworkModule) && (
                    <FooterButton
                        icon={"compass"}
                        screen={TabsNavigatorScreens.SocialNetwork.routeName}
                        {...this.props}
                    />
                )}
                {!ModuleControlService.getInstance().isModuleEnabled(Modules.socialNetworkModule) &&
                    ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                        <FooterButton
                            icon={"wallet"}
                            screen={TabsNavigatorScreens.Wallets.routeName}
                            {...this.props}
                        />
                    )}
            </>
        );
    }
}

const CustomFotterButton = importer(DefaultCustomFotterButton, "CustomFotterButton");

export { CustomFotterButton };
