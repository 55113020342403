import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import FlatListBase from "@base/FlatListBase";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import AssetCard from "./AssetCard";
import RegularText from "@base/RegularText";
import RadiusButton from "@base/RadiusButton";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    client: Client;
    experiences: ExperiencesRedux;
}

interface State {
    showMessage: boolean;
}

const { t } = i18n;

export class _AssetsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.filterAndSortData = this.filterAndSortData.bind(this);
        this.navigateToSection = this.navigateToSection.bind(this);

        this.state = {
            showMessage: false,
        };
    }

    async componentDidMount() {
        setTimeout(() => {
            this.setState({ showMessage: true });
        }, 2500);
    }

    renderItem = ({ item }) => {
        return <AssetCard asset={item} size={175} {...this.props} />;
    };

    navigateToSection() {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.SectionAsset.routeName,
        });
    }

    async loadMore() {}

    filterAndSortData() {
        return this.props.experiences?.asset?.docs.filter((m) => {
            return !m.archivedAt;
        });
    }

    render() {
        return (
            <View>
                <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                    <View style={{ paddingRight: 20 }}>
                        <SectionTitle
                            onPress={this.navigateToSection}
                            title={t("collectibles")}
                            icon={"🧩"}
                            counter={
                                this.props.experiences?.asset?.paginator?.totalDocs > 0
                                    ? this.props.experiences?.asset?.paginator?.totalDocs
                                    : null
                            }
                            seeMore={true}
                            {...this.props}
                        ></SectionTitle>
                    </View>

                    {this.filterAndSortData().length == 0 && this.state.showMessage && (
                        <View style={{ paddingRight: 20 }}>
                            <View
                                style={{
                                    backgroundColor: colors.tertiaryShadow,
                                    borderRadius: settings.cardRadius,
                                    marginTop: 10,
                                    paddingHorizontal: 10,
                                    paddingVertical: 10,
                                }}
                            >
                                <View style={{ paddingBottom: 20 }}>
                                    <RegularText align="justify" fontSize={14}>
                                        {t("empty_assets")}
                                    </RegularText>
                                </View>
                            </View>
                        </View>
                    )}

                    <FlatListBase
                        data={this.filterAndSortData()}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                        initialNumToRender={2}
                        horizontal={true}
                        maxToRenderPerBatch={5}
                        showsHorizontalScrollIndicator={false}
                        onEndReachedThreshold={1}
                        onEndReached={this.loadMore}
                    ></FlatListBase>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const AssetsSection = connect(mapStateToProps, mapDispatchToProps)(_AssetsSection);

export default AssetsSection;
