import CurrencyImplementation from '../../../CurrencyImplementation';
import { PathNode } from '@core/utils/PathNode';
import * as ethUtil from 'ethereumjs-util';
import HDNode from 'hdkey';
import Web3 from 'web3';
import { add, format } from 'date-fns';
import Wallet from '@core/wallet/Wallet';
import TronWeb from 'tronweb';

export default class TRXImplementation extends CurrencyImplementation {
  tronInstance: any;

  getTronInstance() {
    if(!this.tronInstance) {
      this.tronInstance = new TronWeb({
        fullHost: `https://api${this.currency.isTestnet()?'.shasta':''}.trongrid.io`,
      })
    }
    return this.tronInstance;
  }
  
  generateAddress(addressNode: PathNode) {
    const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
    const tron = this.getTronInstance();
    const address = tron.address.fromPrivateKey(privateKey.toString('hex'));
    return address;
  }

  async signTransaction(addressNode: PathNode, rawTx: any) {
    if (rawTx.extra) {
      delete (rawTx.extra);
    }

    if (rawTx.approved) {
      delete (rawTx.approved);
    }

    const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
    const tron = this.getTronInstance();

    return await tron.trx.sign(
      rawTx,
      privateKey.toString('hex')
    );
  }

  parseTransaction(tx) {
   
    return tx;
  }

  parseDate(time) {
    return format(new Date(time*1000), 'MMM dd, yyyy H:mma');
  }

  parseSkeleton(skeleton) {
    const value = skeleton.extra?skeleton.extra.value:skeleton.value;
    const to = skeleton.extra?skeleton.extra.to:skeleton.to;
    const wallet = Wallet.getInstance();
    const swapAmount = skeleton.extra?skeleton.extra.swapAmount:0;
    const tron = wallet.findCurrencyByName('tron');

    return {
      amount: this.currency.fromDecimals(parseInt(value)),
      sendingTo: to,
      sendingFrom: skeleton.from,
      fee: tron.fromDecimals(skeleton.fee),
      swapAmount:swapAmount
    }
  }

  isValidAddress(address:string){
      return this.getTronInstance().isAddress(address);
  }

}
