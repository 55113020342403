import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { StyleSheet, View, TextStyle, ViewStyle, Platform } from "react-native";
import QRCode from "react-native-qrcode-svg";

interface Props {
    value: string;
    size: number;
    logo?: string;
}

interface State {}

export default class QRBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }
  

    render() {
        return (
            <View style={{ borderWidth: 6, borderColor: "white" }}>
                {this.props.logo && Platform.OS !== "web" ? (
                    <QRCode
                        value={this.props.value}
                        size={this.props.size || 200}
                        backgroundColor={"white"}
                        color={"black"}
                        logo={{ uri: this.props.logo }}
                        logoSize={this.props.size ? this.props.size / 4 : 50}
                        logoBorderRadius={this.props.size ? this.props.size / 8 : 25}
                    />
                ) : (
                    <QRCode
                        value={this.props.value}
                        size={this.props.size || 200}
                        backgroundColor={"white"}
                        color={"black"}
                    />
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        flexDirection: "row",
    },
});
