import React, { Component } from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import { colors } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import Row from "@base/Row";
import InlineButton from "@base/InlineButton";
import { NavigationType } from "@custom-types/NavigationType";
import RegularText from "@base/RegularText";
import SwitchSelector from "react-native-switch-selector";
import ExchangeCard from "@components/exchange/ExchangeCard";
import Icon from "@components/icons";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { connect } from "react-redux";
import store from "@store/index";
import { selectExchangeFrom, selectExchangeTo, selectCurrency, onTransactionReady } from "@store/actions/wallet";
import { hideModalBottom, showModalBottom, showPopup, showPopupMessage } from "@store/actions/global";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { ExchangeNavigatorScreens } from "@navigation/ExchangeNavigator";
import { remove } from "fs-extra";
import PressableBase from "@base/PressableBase";
import SwapType from "@custom-types/SwapType";
import debounce from "lodash.debounce";
import BotCard from "@base/BotCard";
import SelectCurrencyComponent from "@screens/shared/SelectCurrencyComponent";
import { WalletService } from "@core/services/WalletService";

interface Props {
    navigation: NavigationType;
    from: Currency;
    to: Currency;
}

interface State {
    from: {
        currency: Currency;
        amount: number;
    };
    to: {
        currency: Currency;
        amount: number;
    };
    error: string;
    loading: boolean;
}

const { t } = i18n;

class _ExchangeScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressNext = this.onPressNext.bind(this);
        this.state = this.initState(props);
        this.getNewSwap = debounce(this.getNewSwap, 500);
    }

    isValidPair(currency: Currency, pairs: Array<string>) {
        if (!currency) return false;
        return pairs.find((p) => p?.toUpperCase() == currency.getId()?.toUpperCase());
    }

    initState(props) {
        const from: Currency = props.from ? props.from : null;
        if (!from) {
            return;
        }
        const pairs = from.getPairs();

        if (!pairs || pairs.length == 0) {
            return {
                from: {
                    currency: from,
                    amount: 0,
                },
                to: {
                    currency: from,
                    amount: 0,
                },
                error: t("pairs_errors"),
                loading: false,
            };
        }

        const to = this.isValidPair(props.to, pairs) ? props.to : this.getInstancedCurrency(pairs);
      
        store.dispatch(onTransactionReady("res?.data"));
        store.dispatch(selectExchangeFrom({currency: from}));
        store.dispatch(selectExchangeTo({currency: to}));
        return {
            from: {
                currency: from,
                amount: 0,
            },
            to: {
                currency: to,
                amount: 0,
            },
            error: null,
            loading: false,
        };
    }

    getInstancedCurrency(pairs) {
        return Wallet.getInstance()
            .getCurrencies()
            ?.find((c) => {
                return pairs.includes(c.getId());
            });
    }

    componentWillReceiveProps(props) {
        this.setState(this.initState(props));
    }

    componentDidMount() {
        this.setState(this.initState(this.props));
    }

    onPress = () => {
        const from = this.state.from;
        const to = this.state.to;
        store.dispatch(selectExchangeFrom(to));
        store.dispatch(selectExchangeTo(from));
        this.setState({ from: to, to: from });
    };

    getError = () => {
        const amount = this.state.from.amount;
        const symbol = this.state.from.currency.getSymbol();
        const balance = this.state.from.currency.getBalance();
        const formatBalance = this.state.from.currency.getFormatBalance();
        const minimum = this.getMinAmount();
        const dAmount = this.state.from.currency.toDecimals(amount);

        if (amount < minimum) return `${t("exchange_amount")} ${minimum} ${symbol}`;

        if (dAmount > balance) return `${t("exchange_funds")} ${formatBalance} ${symbol}`;
    };

    onAmountFromChange = async (amount: number) => {
        const from = this.state.from.currency;
        const to = this.state.to.currency;
        // const pair = this.state.from.currency.getPair(to.getId());
        this.setState({
            from: { currency: from, amount: amount },
        });
        this.getNewSwap(from, { from, to, amount });
    };

    async getNewSwap(currency: Currency, params: SwapType) {
        if (params?.amount == 0) {
            return;
        }
        if (params?.amount == this.getMaxAmount() && currency.getKind() == "NATIVE") {
            this.setState({
                error: t("fee_error"),
            });
        }
        if (params?.amount < this.getMinAmount() && params?.amount !== 0) {
            this.setState({ error: `${t("exchange_amount")} ${this.getMinAmount()} ${params.from.getSymbol()}` });
            return;
        }
        try {
            this.setState({ loading: true });
            const swap = await currency.newSwap({ from: params?.from, to: params?.to, amount: params?.amount });

            if (swap?.data && swap?.data?.approved == 1)
                this.setState({
                    error: null,
                    loading: false,
                    to: { currency: params?.to, amount: swap?.data?.extra?.swapAmount },
                });

            return swap?.data;
        } catch (e: any) {
            this.setState({
                loading: false,
                to: { currency: params?.to, amount: 0 },
                error: e?.response?.data?.message?.toString() || t("an_error_has_occurred"),
            });
        }
    }

    onPressNext() {
        const error = this.getError();
        if (error) {
            this.setState({ error: error });
            setTimeout(() => {
                this.setState({ error: null });
            }, 3000);
        } else {
          
            WalletService.getInstance().prepareExchange({
                swap: {
                    from: this.state.from.currency,
                    to: this.state.to.currency,
                    amount: this.state.from.amount,
                },
                onSuccess: (isApproved) => {
                    if (isApproved) {
                        this.props.navigation.navigate(ExchangeNavigatorScreens.ConfirmExchange.routeName);
                    } else {
                        if (this.state.from.currency.isApproving()) {
                            this.props.navigation.navigate(ExchangeNavigatorScreens.ApprovingExchange.routeName);
                        } else {
                            this.props.navigation.navigate(ExchangeNavigatorScreens.ApproveExchange.routeName);
                        }
                    }
                },
                onError: (error) => {
                    store.dispatch(showPopupMessage({ type: "ERROR", message: error }));
                },
            });
        }
    }

    onSelectFrom = () => {
        let currencies: Array<Currency> = Wallet.getInstance().getCurrencies() || [];

        currencies = currencies.filter((x: Currency) => {
            return x.isExchangeAvailable();
        });

        store.dispatch(
            showModalBottom({
                modalContent: (
                    <SelectCurrencyComponent
                        currencies={currencies}
                        fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                        onPress={(item) => {
                            const pairs = item.getPairs();

                            const to: Currency = this.isValidPair(this.state.to.currency, pairs)
                                ? this.state.to.currency
                                : this.getInstancedCurrency(pairs);

                            this.setState({
                                from: { currency: item, amount: 0 },
                                to: { currency: to, amount: 0 },
                                error: null,
                            });
                            store.dispatch(selectCurrency(item?.getId()));
                            store.dispatch(selectExchangeFrom({currency: item}));
                            store.dispatch(selectExchangeTo({currency: to}));
                            store.dispatch(hideModalBottom());
                        }}
                    ></SelectCurrencyComponent>
                ),
            }),
        );
    };

    onSelectTo = () => {
        const pairs = this.state.from.currency.getPairs();
        let pairsCurrencies: Array<Currency> = [];
        if (pairs?.length > 0) {
            pairs.map((p) => {
                const c = Wallet.getInstance().findCurrencyById(p);
                if (c) {
                    pairsCurrencies.push(c);
                }
            });
        }

        store.dispatch(
            showModalBottom({
                modalContent: (
                    <SelectCurrencyComponent
                        currencies={pairsCurrencies}
                        fiatCurrency={Wallet.getInstance().getFiatCurrency()}
                        onPress={(item) => {
                            this.setState({ to: { currency: item, amount: 0 } });
                            store.dispatch(selectExchangeTo({currency: item}));
                            store.dispatch(hideModalBottom());
                        }}
                    ></SelectCurrencyComponent>
                ),
            }),
        );
    };

    switchAmount = (value: number) => {
        this.onAmountFromChange(value);
    };

    getMinAmount = () => {
        return 0.00001;
    };

    getHalfAmount = () => {
        return this.getMaxAmount() / 2;
    };

    getMaxAmount = () => {
        const currency = this.state.from.currency;
        return currency.fromDecimals(currency.getBalance());
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    {...this.props}
                    title={t("exchange")}
                    type={HeaderType.Light}
                    rightBtn={{
                        onPress: () => {
                            this.props.navigation.navigate(ExchangeNavigatorScreens.ExchangeHistory.routeName);
                        },
                        icon: "clock-history",
                        iconType: "custom",
                    }}
                />
                <Container>
                    <View style={styles.container}>
                        <ExchangeCard
                            currency={this.state.from.currency}
                            amount={this.state.from.amount}
                            onAmountChange={this.onAmountFromChange}
                            onPress={this.onSelectFrom}
                        />

                        <Row style={{ alignItems: "center", marginVertical: 15 }}>
                            <View style={styles.divisor}></View>
                            <View style={styles.divisorIcon}>
                                {this.state.loading ? (
                                    <ActivityIndicator size={25} color={colors.text} />
                                ) : (
                                    <PressableBase onPress={this.onPress}>
                                        <Icon name="exchange" size={25} color={colors.white} align="center" />
                                    </PressableBase>
                                )}
                            </View>
                        </Row>
                        <View style={{ opacity: this.state.loading || this.state.error ? 0.65 : 1 }}>
                            <ExchangeCard
                                currency={this.state.to.currency}
                                amount={this.state.to.amount}
                                onPress={this.onSelectTo}
                            />
                        </View>
                    </View>

                    <Row style={{ justifyContent: "center" }}>
                        <SwitchSelector
                            options={[
                                { label: "MIN", value: this.getMinAmount() },
                                { label: "HALF", value: this.getHalfAmount() },
                                { label: "MAX", value: this.getMaxAmount() },
                            ]}
                            initial={0}
                            style={{ width: 250, marginHorizontal: "auto", alignSelf: "center", marginVertical: 20 }}
                            onPress={this.switchAmount}
                            textColor={colors.text}
                            selectedColor={colors.white}
                            buttonColor={colors.secondary}
                            borderColor={colors.secondary}
                            animationDuration={250}
                            backgroundColor={colors.shadow}
                        />
                    </Row>

                    {this.state.error && (
                        <BotCard
                            style={{ opacity: this.state.loading ? 0.7 : 1 }}
                            title={`${t("warning")}!`}
                            align={"left"}
                            message={this.state.error}
                        ></BotCard>
                    )}
                </Container>
                <Container style={{ flex: 1, flexDirection: "column-reverse" }}>
                    <View style={{ marginTop: 20 }}>
                        <InlineButton
                            title={t("next")}
                            onPress={this.onPressNext}
                            style={{ marginHorizontal: 0, marginBottom: 20 }}
                        />
                    </View>

                    <View style={{ flex: 1 }}></View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.shadow,
        borderRadius: 20,
        padding: 20,
    },
    divisor: {
        height: 1,
        backgroundColor: colors.background,
        flex: 1,
    },
    divisorIcon: {
        width: 40,
        height: 40,
        marginLeft: 10,
        backgroundColor: colors.secondary,
        borderRadius: 40,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
    },
});

const mapStateToProps = (state) => {
    return {
        from: state.wallet.selectedExchangeFrom,
        to: state.wallet.selectedExchangeTo,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExchangeScreen = connect(mapStateToProps, mapDispatchToProps)(_ExchangeScreen);

export default ExchangeScreen;
