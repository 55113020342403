import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import Label from "@base/Label";
import Card from "@base/Card";
import store from "@store/index";
import { loading, ready, showPopup } from "@store/actions/global";
import NFTService from "@core/services/NFTService";
import { NTFsNavigatorScreens } from "@navigation/NFTsNavigator";
import { Client } from "@custom-types/Client";
import NFT from "@custom-types/NFTModel";
import { setTemporalNFTs } from "@store/actions/nfts.action";
import BotCard from "@base/BotCard";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { ProfileNavigator, ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ProfileSections } from "@custom-types/ProfileType";

interface Props {
    navigation: NavigationType;
    route: any;
    client: Client;
    selectedNFT: NFT;
    temporalNfts: Array<NFT>;
}

interface State {
    tokenId: number;
    contractAddress: string;
    amount: number;
    fee: string;
    sendingTo: string;
    sendingFrom: string;
    skeleton: any;
}

const { t } = i18n;

export class _ConfirmBuyScreen extends Component<Props, State> {
    private currency: Currency;
    constructor(props: Props) {
        super(props);
        this.confirmBuy = this.confirmBuy.bind(this);
        this.currency = Wallet.getInstance().findCurrencyById(
            this.props.route.params?.currency,
        );

        this.state = {
            tokenId: parseInt(this.props.route.params?.tokenId) || 0,
            contractAddress: this.props.route.params?.contractAddress,
            amount: 0,
            sendingTo: "",
            sendingFrom: "",
            fee: "",
            skeleton: "",
        };
    }

    async componentDidMount() {
        const resp = await NFTService.getInstance().requestBuyNFT(
            this.currency,
            this.state.tokenId,
            this.state.contractAddress,
        );
        if (resp) {
            const skeleton = this.currency.getImplementation().parseSkeleton(resp);

            this.setState({
                skeleton: resp,
                amount: skeleton.amount ? skeleton.amount : 0,
                sendingTo: skeleton.sendingTo,
                sendingFrom: skeleton.sendingFrom,
                fee: skeleton.fee,
            });
        } else {
            this.props.navigation.goBack();
        }
    }

    async confirmBuy() {
        store.dispatch(loading());

        try {
            const res = await this.currency.sendTransaction(this.state.skeleton);
            store.dispatch(showPopup({ type: "SUCCESS" }));
            let temporalNFT: NFT = this.props.selectedNFT;
            (temporalNFT.type = "temporalBuy"),
                (temporalNFT.owner = this.props.client),
                (temporalNFT.isListed = false),
                (temporalNFT.isOwner = true),
                store.dispatch(setTemporalNFTs(this.props.temporalNfts.concat(temporalNFT)));
            this.props.navigation.navigate("Profile", {
                screen: ProfileNavigatorScreens.ProfileMain.routeName,
                params: {
                    clientID: this.props.client?._id,
                    profileSection: ProfileSections.nftProfile,
                },
            });

        } catch (e) {
            console.warn(e);
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
        store.dispatch(ready());
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("confirm_buy")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between", paddingVertical: 20 }}>
                    <View>
                        <BotCard title={t("important")} message={t("info_card_buy")}></BotCard>
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("amount")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.currency.fromDecimalsToString(this.currency.toDecimals(this.state.amount))}{" "}
                                {this.currency.getPName()}
                            </BoldText>
                            <Label text={`$ ${this.currency.toFiat(this.state.amount)}`} align="center" fontSize={12} />
                        </Card>
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("sending_from")}
                            </RegularText>
                            <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                {this.state.sendingFrom}
                            </BoldText>
                        </Card>
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("sending_to")}
                            </RegularText>
                            <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                {this.state.sendingTo}
                            </BoldText>
                        </Card>

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("fee")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.state.fee} {this.currency.getPName()}
                            </BoldText>
                            <Label
                                text={`$ ${this.currency.toFiat(Number(this.state.fee))}`}
                                align="center"
                                fontSize={12}
                            />
                        </Card>
                    </View>
                    <InlineButton style={{ marginHorizontal: 0 }} onPress={this.confirmBuy} title={t("confirm_buy")} />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        selectedNFT: state.nfts.selectedNFT,
        temporalNfts: state.nfts.temporalNfts,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmBuyScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmBuyScreen);

export default ConfirmBuyScreen;

const styles = StyleSheet.create({
    card: {},
    swipContainer: {
        flex: 1,
        height: 58,
    },
});
