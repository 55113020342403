import React, { Component } from "react";
import { View, StyleSheet, Image, Platform } from "react-native";
import Constants from "expo-constants";
import { colors } from "@styles/globalStyles";
import Client from "../../../clients/Client";
import { connect } from "react-redux";

interface Props {
    webSplash: boolean;
}

interface State {
    showWebSplash;
}

export class _WebSplash extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showWebSplash:
                (this.props.webSplash && Constants.expoConfig?.extra?.webSplash && Platform.OS == "web") || false,
        };
    }

    componentDidUpdate() {
        if (!this.props.webSplash && this.state.showWebSplash) {
            this.setState({ showWebSplash: false });
        }
    }

    render() {
        return (
            <View style={this.state.showWebSplash ? styles.wrapper : styles.hidden}>
                <View style={styles.wrapper && styles.background}></View>
                <Image style={{ width: "100%", height: 600 }} resizeMode={"contain"} source={Client.SplashImg} />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex:999999
    },
    background: {
        position: "absolute",
        backgroundColor: Constants.expoConfig?.extra?.backgroundColor || colors.primary,
        opacity: 0.9,
        height: "100%",
        width: "100%",
    },
    icon: {
        fontSize: 80,
        zIndex: 999,
    },
});

const mapStateToProps = (state) => {
    return { webSplash: state.global.webSplash };
};

const mapDispatchToProps = (dispatch) => ({});

const WebSplash = connect(mapStateToProps, mapDispatchToProps)(_WebSplash);

export default WebSplash;
