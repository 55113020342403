import { ActivityModel, ActivityTypes } from "@custom-types/ActivityModel";
import ActivityCardAsset from "@screens/activity/components/ActivityCardAsset";
import ActivityCardDigitalCurrency from "@screens/activity/components/ActivityCardDigitalCurrency";
import ActivityCardMembership from "@screens/activity/components/ActivityCardMembership";
import ActivityCardOrderPurchase from "@screens/activity/components/ActivityCardOrderPurchase";
import ActivityCardRedeemable from "@screens/activity/components/ActivityCardRedeemable";
import React from "react";
import { View } from "react-native";

export function getActivityCard(activity: ActivityModel, navigation) {
    switch (activity.type) {
        case ActivityTypes.DigitalCurrencyTransaction:
            return <ActivityCardDigitalCurrency activity={activity} navigation={navigation} />;
        case ActivityTypes.MembershipClaim:
            return <ActivityCardMembership activity={activity} navigation={navigation} />;
        case ActivityTypes.AssetClaim:
            return <ActivityCardAsset activity={activity} navigation={navigation} />;
        case ActivityTypes.AssetTransfer:
            return <ActivityCardAsset activity={activity} navigation={navigation} />;
        case ActivityTypes.AssetDrop:
            return <ActivityCardAsset activity={activity} navigation={navigation} />;
        case ActivityTypes.RedeemableTransfer:
            return <ActivityCardRedeemable activity={activity} navigation={navigation} />;
        case ActivityTypes.RedeemableDrop:
            return <ActivityCardRedeemable activity={activity} navigation={navigation} />;
        case ActivityTypes.RedeemableUse:
            return <ActivityCardRedeemable activity={activity} navigation={navigation} />;
        case ActivityTypes.OrderPurchase:
            return <ActivityCardOrderPurchase activity={activity} navigation={navigation} />;
        default:
            return <View></View>;
    }
}

const data = {
    _id: "66993f49c653b4fdfa506f13",
    type: "orderPurchase",
    client: "668ff2b458ee28a70c3f91f4",
    wallet: "com.beexo",
    data: {
        _id: "66993f12c653b4fdfa506d4f",
        amount: 15,
        gateway: {
            _id: "66465a42b66e171271606337",
            name: "Mercado Pago (AR)",
        },
        currency: {
            digitalCurrencyId: null,
            fiatCurrencyId: "66456e86ce7a60795a4eb9d9",
        },
        items: [
            {
                type: "REDEEMABLE",
                price: 5,
                quantity: 1,
                title: "Remera Gamer ",
                data: {
                    _id: "6699177b1b902e5e8c2cf1df",
                    redeemableOption: {
                        type: "ticket",
                    },
                    experience: {
                        _id: "669916e91b902e5e8c2cf0d7",
                    },
                },
            },
            {
                type: "REDEEMABLE",
                price: 5,
                quantity: 2,
                title: "Boleto",
                data: {
                    _id: "669917c01b902e5e8c2cf235",
                    redeemableOption: {
                        type: "ticket",
                    },
                    experience: {
                        _id: "669916e91b902e5e8c2cf0d7",
                    },
                },
            },
        ],
    },
    createdAt: "2024-07-18T16:14:01.737Z",
    updatedAt: "2024-07-18T16:14:01.737Z",
    __v: 0,
};
