import React, { Component } from "react";
import {
    View,
    StyleSheet,
    BackHandler,
    Keyboard,
    Platform,
    EmitterSubscription,
    TouchableWithoutFeedback,
} from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import RegularTextInput from "@base/RegularTextInput";
import RegularNumberInput from "@base/RegularNumberInput";
import SwitchSelector from "react-native-switch-selector";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import InlineButton from "@base/InlineButton";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import store from "@store/index";
import { cleanOperation, PRE_PREPARE_TRANSACTION } from "@store/actions/wallet";
import Wallet from "@core/wallet/Wallet";
import BigNumber from "bignumber.js";
import { Dimensions } from "react-native";
import { showPopupMessage } from "@store/actions/global";
import Icon from "@components/icons";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import BoldText from "@base/BoldText";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import Card from "@base/Card";
import ProfileService from "@core/services/ProfileService";
import { Client } from "@custom-types/Client";
import Row from "@base/Row";
import { AvatarBase } from "@components/avatar/AvatarBase";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import PressableBase from "@base/PressableBase";
import { Route } from "@react-navigation/native";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import CircleButton from "@base/CircleButton";
import { SendNavigatorScreens } from "@navigation/SendNavigator";
import { WalletService } from "@core/services/WalletService";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    route: Route<string, { address?: string; amount?: number; selectedCurrency?: string; showFindClient?: boolean }>;
    selectedCurrency: string;
    fiatCurrency: FiatCurrency;
    currentOperation: any;
    customMessage?: any;
    next?: (transaction: any) => void;
    onSuccessNavigate?: any;
}

interface State {
    address: string;
    amount: any;
    convertedAmount: BigNumber;
    switch: boolean;
    fiat: number;
    toClient: Partial<Client>;
    currency: Currency;
    showAmountError: boolean;
    showAddressError: boolean;
    screenHeight: any;
    next?: (transaction: any) => void;
    loading: boolean;
}
const { t } = i18n;

export class _SendScreen extends Component<Props, State> {
    private currency: Currency;
    private fiatCurrency: FiatCurrency;
    private keyboardWillShowSub: EmitterSubscription;
    private keyboardWillHideSub: EmitterSubscription;
    private title = t("send");
    focusListener: any;
    constructor(props: Props) {
        super(props);

        this.init = this.init.bind(this);
        this.state = this.init();
        this.onPressNext = this.onPressNext.bind(this);
        if (Platform.OS != "web")
            BackHandler.addEventListener("hardwareBackPress", () => {
                store.dispatch(cleanOperation());
                return false;
            });
        this.keyboardWillShow = this.keyboardWillShow.bind(this);
        this.keyboardWillHide = this.keyboardWillHide.bind(this);
        this.findClient = this.findClient.bind(this);
    }

    setTitle(title: string) {
        this.title = title;
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.init();
        });

        this.keyboardWillShowSub = Keyboard.addListener("keyboardWillShow", this.keyboardWillShow);
        this.keyboardWillHideSub = Keyboard.addListener("keyboardWillHide", this.keyboardWillHide);
    }

    init() {
        this.currency = Wallet.getInstance().findCurrencyById(
            this.props.route?.params?.selectedCurrency || this.props.selectedCurrency
        );
        this.fiatCurrency = this.props.fiatCurrency;
        const op = this.props.currentOperation;

        const fiat = this.fiatCurrency.fromDecimals(this.currency.getFiat());
        let amount = 0;
        let address =
            this.props.customMessage && this.props.customMessage?.address ? this.props.customMessage?.address : "";
        let showAddressError = false;

        if (this.props.route?.params?.address || this.props.route.params?.amount) {
            const isValid = this.currency.isValidAddress(this.props.route.params?.address);
            isValid ? (address = this.props.route.params.address) : (showAddressError = true);
        }

        let s = this.state ? this.state.switch : false;

        if (op && op.type && op.type === PRE_PREPARE_TRANSACTION) {
            amount = op.amount ? op.amount : 0;
            address = op?.address ? op?.address : "";
        }

        if (this.props.route?.params?.amount) {
            amount = this.props.route.params.amount;
        }

        const state = {
            amount: amount,
            convertedAmount: this.getConvertedAmount(amount, fiat, s),
            address: address,
            switch: s,
            fiat: fiat,
            toClient: null,
            currency: this.currency,
            showAmountError: false,
            showAddressError: showAddressError,
            screenHeight: "100%",
            next:
                this.props.next ||
                ((transaction: any) => {
                    WalletService.getInstance().prepareTransaction({
                        transaction,
                        onSuccess: () => {
                            if (this.generateOnSucces()) {
                                this.generateOnSucces();
                                return;
                            }
                            this.props.onSuccessNavigate
                                ? this.props.navigation.navigate(this.props.onSuccessNavigate, {
                                      toClient: this.state.toClient,
                                  })
                                : this.props.navigation.navigate("ConfirmSend", { toClient: this.state.toClient });
                        },

                        onError: (error) => {
                            store.dispatch(showPopupMessage({ type: "ERROR", message: error }));
                        },
                    });
                }),
            loading: false,
        };

        if (this.state) this.setState(state, () => this.getProfileByAddress());
        return state;
    }

    componentWillUnmount() {
        this.keyboardWillShowSub.remove();
        this.keyboardWillHideSub.remove();
    }

    keyboardWillShow = (event) => {
        if (Platform.OS == "ios") {
            this.setState({
                screenHeight: Math.round(Dimensions.get("window").height) - event.endCoordinates.height - 40,
            });
        }
    };

    keyboardWillHide = (event) => {
        if (Platform.OS == "ios") {
            this.setState({
                screenHeight: "100%",
            });
        }
    };

    validateAmount(): boolean {
        const amount = new BigNumber(this.state.switch ? this.state.convertedAmount : this.state.amount);
        const minAmount = new BigNumber(this.currency.fromDecimals(1));
        const isValid = amount.isGreaterThan(minAmount);
        this.setState({ showAmountError: !isValid });
        if (!isValid) {
            vibrate(VibrationType.ERROR);
        }
        return isValid;
    }

    validateAddress(): boolean {
        const isValid = this.currency.isValidAddress(this.state.address);
        this.setState({ showAddressError: !isValid });
        if (!isValid) {
            vibrate(VibrationType.ERROR);
        }
        return isValid;
    }

    validate() {
        return this.validateAmount() && this.validateAddress();
    }

    generateOnSucces() {
        return false;
    }

    onPressNext = () => {
        if (!this.validate() || this.state.loading) {
            return;
        }
        this.setState({ loading: true });

        let amount = this.state.switch ? this.state.convertedAmount : this.state.amount;
        amount = new BigNumber(amount).toString();

        this.state.next({
            currency: this.state.currency,
            amount: amount,
            addressFrom: this.state.currency.getAddressesForAllFormats(),
            addressTo: this.state.address,
            addressOwner: this.currency.isSmart() ? this.currency.getAddress({ owner: true }) : null,
        });

        if (this.props.next) {
            if (this.generateOnSucces()) {
                this.generateOnSucces();
                return;
            }

            this.props.navigation.navigate(this.props.onSuccessNavigate, { toClient: this.state.toClient });
        }

        this.setState({ loading: false });
    };

    getConvertedAmount = (value, fiat, s) => {
        this.fiatCurrency.getDecimals();
        BigNumber.config({ EXPONENTIAL_AT: this.currency.getDecimals() + 1 });
        const v = new BigNumber(value);
        const f = new BigNumber(fiat);
        return v.multipliedBy(f.exponentiatedBy(s ? -1 : 1)).decimalPlaces(s ? 8 : 2);
    };

    onChangeAmount = (value: number | string) => {
        const amount = value && !Number.isNaN(value) && value !== "NaN" ? value : 0;
        let convertedAmount = this.getConvertedAmount(amount, this.state.fiat, this.state.switch);

        this.setState({
            amount: amount,
            convertedAmount: convertedAmount,
        });
    };

    onChangeText = async (value: string) => {
        this.setState(
            {
                address: value,
            },
            () => this.getProfileByAddress()
        );
    };

    getProfileByAddress = async () => {
        if (this.state.address?.length > 12) {
            const isValid = this.currency.isValidAddress(this.state.address);
            if (!isValid) {
                this.setState({ toClient: null });
                return;
            }
            const c = await ProfileService.getInstance().getProfileByAddress(this.currency, this.state.address);
            if (c) {
                this.setState({ toClient: c });
            }
        } else {
            this.setState({ toClient: null });
        }
    };

    switchCurrency = () => {
        this.setState({
            amount: this.state.convertedAmount,
            convertedAmount: this.state.amount,
            switch: !this.state.switch,
        });
    };

    showConvertedAmount() {
        return (
            this.state.convertedAmount +
            " " +
            (!this.state.switch ? this.fiatCurrency.getName() : this.currency.getSymbol())
        );
    }

    onPress() {
        if (Platform.OS == "ios") Keyboard.dismiss();
    }

    findClient() {
        this.props.navigation.navigate(SendNavigatorScreens.FindClient.routeName, {
            currency: this.currency,
            amount: this.state.amount,
        });
    }

    render() {
        return (
            <ScreenWrapper>
                <View
                    style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        marginTop: 65,
                        zIndex: 999,
                        width: "100%",
                    }}
                >
                    <Container>
                        <NetworkCurrencySymbol
                            currency={this.currency}
                            styles={{ minWidth: 45, paddingHorizontal: 8, paddingVertical: 3 }}
                            containerStyles={{ alignItems: "flex-start", position: "absolute", left: 20, top: 0 }}
                            fontSize={10}
                        ></NetworkCurrencySymbol>
                        <View
                            style={{
                                width: "100%",
                                justifyContent: "flex-end",
                                alignContent: "flex-end",
                                alignItems: "flex-end",
                                top: Platform.OS == "web" ? 5 : 0,
                            }}
                        >
                            <CurrencyBalanceIcon
                                style={{
                                    opacity: 0.9,
                                    marginTop: 5,
                                    backgroundColor: colors.tertiaryShadow,
                                    paddingHorizontal: 6,
                                    paddingVertical: 4,
                                    borderRadius: settings.cardRadius,
                                    overflow: "hidden",
                                }}
                                currency={this.currency}
                                amount={this.currency.getFormatTotalBalance()}
                            />

                            {this.currency.getFiat() !== 0 && (
                                <CurrencyBalanceIcon
                                    style={{
                                        opacity: 0.9,
                                        marginTop: 5,
                                        backgroundColor: colors.tertiaryShadow,
                                        paddingHorizontal: 6,
                                        paddingVertical: 4,
                                        borderRadius: settings.cardRadius,
                                        overflow: "hidden",
                                    }}
                                    fiatCurrency={this.fiatCurrency}
                                    balance={this.currency.getFiatTotalBalance()}
                                />
                            )}
                        </View>
                    </Container>

                    <View style={{ position: "relative" }}>
                        <SwitchSelector
                            options={
                                this.currency.getFiat() !== 0
                                    ? [
                                          { label: this.currency.getSymbol(), value: 1 },
                                          {
                                              label: this.fiatCurrency.getSymbol(),
                                              value: this.currency.getFiat(),
                                          },
                                      ]
                                    : [{ label: this.currency.getSymbol(), value: 1 }]
                            }
                            initial={this.state.switch ? 1 : 0}
                            value={this.state.switch ? 1 : 0}
                            style={{
                                width: 250,
                                marginHorizontal: "auto",
                                alignSelf: "center",
                                marginVertical: 20,
                            }}
                            onPress={() => null}
                            textColor={colors.text}
                            selectedColor={colors.white}
                            buttonColor={colors.secondary}
                            borderColor={colors.secondary}
                            animationDuration={250}
                            backgroundColor={colors.shadow}
                        />
                        <PressableBase
                            onPress={this.switchCurrency}
                            style={{ position: "absolute", top: 10, bottom: 10, left: 0, right: 0 }}
                        ></PressableBase>
                    </View>
                </View>
                <TouchableWithoutFeedback onPress={this.onPress} style={{ zIndex: -1, height: "100%" }}>
                    <View style={{ height: this.state.screenHeight }}>
                        <Header
                            title={`${this.title} ${this.currency.getSymbol()}`}
                            type={HeaderType.Light}
                            {...this.props}
                        />
                        <Container style={{ marginTop: Platform.OS == "web" ? 115 : 115, paddingTop: 10 }}>
                            <RegularNumberInput
                                style={{ height: 45 }}
                                onChangeText={this.onChangeAmount}
                                value={this.state.amount ? this.state.amount : ""}
                                align="center"
                                fontSize={35}
                                placeholder="0"
                                editable
                                fontWeight="bold"
                            />
                            {this.currency.getFiat() !== 0 && (
                                <View style={{ alignSelf: "center", paddingTop: 5 }}>
                                    {!this.state.switch ? (
                                        <CurrencyBalanceIcon
                                            fiatCurrency={this.fiatCurrency}
                                            amount={this.state.convertedAmount.toString()}
                                        ></CurrencyBalanceIcon>
                                    ) : (
                                        <CurrencyBalanceIcon
                                            currency={this.currency}
                                            amount={this.state.convertedAmount.toString()}
                                        ></CurrencyBalanceIcon>
                                    )}
                                </View>
                            )}

                            {this.state.showAmountError && (
                                <RegularText
                                    style={{ marginTop: 10 }}
                                    align="center"
                                    fontSize={12}
                                    color={colors.secondary}
                                >
                                    {t("amount_small")}
                                </RegularText>
                            )}
                        </Container>
                        <Container style={{ flex: 1, flexDirection: "column-reverse" }}>
                            <InlineButton
                                title={t("next")}
                                onPress={this.onPressNext}
                                style={{ marginHorizontal: 0, marginBottom: 20 }}
                                vibrate={true}
                            />
                            {this.props.route.params?.showFindClient && (
                                <Card
                                    style={{
                                        paddingVertical: 8,
                                        marginTop: 0,
                                        marginBottom: 15,
                                        paddingHorizontal: 10,
                                    }}
                                    icon={
                                        <CircleButton
                                            icon="person-profile"
                                            iconSize={20}
                                            style={{
                                                marginRight: 10,
                                                height: 36,
                                                width: 36,
                                                borderRadius: 18,
                                                marginBottom: 0,
                                            }}
                                        />
                                    }
                                    left={<RegularText fontSize={14}>{t("find_address")}</RegularText>}
                                    right={<Icon color={colors.text} size={24} name="right"></Icon>}
                                    onPress={this.findClient}
                                />
                            )}

                            <View style={styles.addressWrapper}>
                                <View style={styles.addressContainer}>
                                    <RegularTextInput
                                        onChangeText={this.onChangeText}
                                        value={this.state.address}
                                        align="left"
                                        placeholder={t("address")}
                                        editable
                                        style={{ padding: 10, flex: 1 }}
                                        fontSize={14}
                                    />
                                </View>
                                <PressableBase
                                    onPress={() =>
                                        this.props.navigation.navigate("Scanner", {
                                            amount: this.state.amount,
                                        })
                                    }
                                    style={styles.button}
                                >
                                    <Icon name={"qr"} size={26} color={colors.text} align="center" />
                                </PressableBase>
                            </View>
                            {this.state.showAddressError && (
                                <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                    {t("valid_address")}
                                </RegularText>
                            )}
                            {this.state.toClient && (
                                <Card>
                                    <Row
                                        style={{
                                            width: "100%",

                                            alignItems: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        <AvatarBase
                                            alias={this.state.toClient?.alias}
                                            uri={this.state.toClient?.profileImagePath?.thumbnail}
                                            size={45}
                                        ></AvatarBase>

                                        <View style={{ paddingLeft: 10 }}>
                                            <BoldText align="left" color={colors.text}>
                                                {"@"}
                                                {trimHelper(this.state.toClient?.alias, 35, true)}
                                            </BoldText>
                                            <Row
                                                style={{ alignItems: "center", alignContent: "center", paddingTop: 5 }}
                                            >
                                                <CurrencyIcon
                                                    size={16}
                                                    iconSize={10}
                                                    currency={this.currency}
                                                ></CurrencyIcon>
                                                <RegularText
                                                    fixedWidth={300}
                                                    color={colors.text}
                                                    align="left"
                                                    selectable={true}
                                                >
                                                    {" "}
                                                    {trimHelper(this.state?.address, 30, true)}
                                                </RegularText>
                                            </Row>
                                        </View>
                                    </Row>
                                </Card>
                            )}
                            <View style={{ flex: 1 }}></View>
                        </Container>
                    </View>
                </TouchableWithoutFeedback>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    button: {
        width: 45,
        height: 45,
        alignItems: "center",
        alignContent: "center",
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        justifyContent: "center",
        padding: 0,
        marginLeft: 10,
    },
    addressWrapper: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: 15,
        width: "100%",
    },
    addressContainer: {
        flex: 1,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        paddingLeft: 5,
        flexDirection: "row",
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const SendScreen = connect(mapStateToProps, mapDispatchToProps)(_SendScreen);

export default SendScreen;
