import React, { Component } from "react";
import { View, StyleSheet, Animated, Keyboard } from "react-native";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import { connect } from "react-redux";
import CircleButton from "@base/CircleButton";
import { getColorOpacity } from "@utils/helpers/global/global";
import { LinearGradient } from "expo-linear-gradient";
import BoldText from "@base/BoldText";
import store from "@store/index";
import { hideModal, showModal } from "@store/actions/global";
import Row from "@base/Row";
import AvatarBase from "@components/avatar/AvatarBase";
import { nameSocialLengthHelper } from "@utils/helpers/social/social.helper";
import Icon from "@components/icons";
import Currency from "@core/currencies/Currency";
import { selectCurrency } from "@store/actions/wallet";
import { ModalOptions } from "./ModalOptions";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import RadiusButton from "@base/RadiusButton";
import PressableBase from "@base/PressableBase";

interface State {
    fadeAnimHeight: any;
    textOpacity: any;
    showModal: boolean;
    hidden: boolean;
}

interface Props {
    showModal?: boolean;
    modalOptions?: ModalOptions;
    selectedCurrency: string;
}

export class _Modal extends Component<Props, State> {
    protected fadeAnimHeightStart = -900;
    protected textOpacityStart = 0;
    focusListener: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            fadeAnimHeight: new Animated.Value(this.fadeAnimHeightStart),
            textOpacity: new Animated.Value(this.textOpacityStart),
            showModal: false,
            hidden: true,
        };
        this.onPress = this.onPress.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.animate = this.animate.bind(this);
    }

    componentDidUpdate(props) {
        if (this.props.showModal !== props.showModal) {
            if (this.props.showModal) {
                this.showModal();
            } else {
                this.hideModal();
            }
        }
    }

    onPress() {
        this.props.modalOptions.onPressClose
            ? this.props.modalOptions.onPressClose()
            : this.setState({ showModal: false }, this.hideModal);
    }

    showModal() {
        setTimeout(() => {
            this.setState({ hidden: false });
            Keyboard.dismiss();
            this.animate([
                {
                    value: this.state.fadeAnimHeight,
                    config: {
                        toValue: 0,
                        duration: 400,
                    },
                },
                {
                    value: this.state.textOpacity,
                    config: {
                        toValue: 1,
                        duration: 900,
                    },
                },
            ]);
        }, 100);
    }

    hideModal() {
        store.dispatch(hideModal());
        setTimeout(() => {
            this.setState({ hidden: true });
        }, 900);
        this.animate([
            {
                value: this.state.fadeAnimHeight,
                config: {
                    toValue: -900,
                    duration: 400,
                },
            },
            {
                value: this.state.textOpacity,
                config: {
                    toValue: 0,
                    duration: 200,
                },
            },
        ]);
    }

    animate(
        animations: {
            value: Animated.Value;
            config: Partial<Animated.TimingAnimationConfig>;
        }[],
    ) {
        animations.map((animation) => {
            Animated.timing(animation.value, {
                ...animation.config,
                toValue: animation.config.toValue,
                useNativeDriver: false,
            }).start();
        });
    }

    render() {
        return (
            <View style={!this.state.hidden ? styles.wrapper : styles.hidden}>
                <View style={styles.wrapper && styles.background}></View>
                <Animated.View
                    style={{
                        alignSelf: "center",
                        overflow: "hidden",
                        maxWidth: 600,
                        marginTop: this.state.fadeAnimHeight,
                        height: "90%",
                        width: "95%",
                        borderBottomRightRadius: 25,
                        borderBottomLeftRadius: 25,
                        backgroundColor: colors.modalBackground,
                    }}
                >
                    <LinearGradient
                        style={{
                            flex: 1,
                            paddingBottom: 20,
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            paddingHorizontal: 10,

                            overflow: "hidden",
                        }}
                        colors={[getColorOpacity(colors.modalBackgroundFrom, 0.9), getColorOpacity(colors.modalBackgroundTo, 0.5)]}
                    >
                        <Animated.View
                            style={{
                                flex: 1,
                                opacity: this.state.textOpacity,
                                paddingHorizontal: 10,
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {this.props.modalOptions && (
                                <View>
                                    {this.props.modalOptions.avatarIcon?.length > 0 && (
                                        <View
                                            style={{
                                                backgroundColor: colors.secondary,
                                                height: 100,
                                                width: 100,
                                                borderRadius: 50,
                                                alignSelf: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Icon
                                                name={this.props.modalOptions.avatarIcon}
                                                size={50}
                                                color={colors.white}
                                            ></Icon>
                                        </View>
                                    )}
                                    {this.props.modalOptions.avatar && (
                                        <View
                                            style={{
                                                width: 90,
                                                alignSelf: "center",
                                            }}
                                        >
                                            {this.props.modalOptions.currency && (
                                                <View
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        borderRadius: 14,
                                                        width: 28,
                                                        height: 28,
                                                        zIndex: 9999,
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        backgroundColor:
                                                            this.props.modalOptions.currency.backgroundColor,
                                                    }}
                                                >
                                                    <Icon
                                                        name={this.props.modalOptions.currency.icon}
                                                        size={18}
                                                        color="white"
                                                    />
                                                </View>
                                            )}
                                            <AvatarBase size={90} alias="" uri={this.props.modalOptions.avatar} />
                                        </View>
                                    )}

                                    {!this.props.modalOptions.avatar && this.props.modalOptions.currency && (

                                        <View
                                            style={{
                                                alignSelf: "center",
                                                borderRadius: 25,
                                                width: 50,
                                                height: 50,
                                                zIndex: 9999,
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                                backgroundColor:
                                                    this.props.modalOptions.currency.backgroundColor,
                                            }}
                                        >
                                            <Icon
                                                name={this.props.modalOptions.currency.icon}
                                                size={26}
                                                color="white"
                                            />
                                        </View>
                                            
                                            
                                    )}

                                    {this.props.modalOptions.title?.length > 0 && (
                                        <BoldText
                                            style={{
                                                marginTop:
                                                    this.props.modalOptions.avatar ||
                                                        this.props.modalOptions.avatarIcon?.length > 0
                                                        ? 25
                                                        : 100,
                                            }}
                                            fontSize={26}
                                            align="center"
                                        >
                                            {nameSocialLengthHelper(this.props.modalOptions.title, 20)}
                                        </BoldText>
                                    )}
                                    {this.props.modalOptions.subtitle?.length > 0 && (
                                        <BoldText style={{ marginTop: 30 }} fontSize={20} align="center">
                                            {this.props.modalOptions.subtitle}
                                        </BoldText>
                                    )}
                                    {this.props.modalOptions.message?.length > 0 && (
                                        <RegularText style={{ marginTop: 30 }} align="center" fontSize={18}>
                                            {this.props.modalOptions.message}
                                        </RegularText>
                                    )}
                                    {this.props.modalOptions.description?.length > 0 && (
                                        <RegularText style={{ marginTop: 20 }} align="center" fontSize={14}>
                                            {trimHelper(this.props.modalOptions.description, 200)}
                                        </RegularText>
                                    )}
                                    {this.props.modalOptions.question?.length > 0 && (
                                        <BoldText style={{ paddingTop: 30 }} fontSize={18} align="center">
                                            {this.props.modalOptions.question}
                                        </BoldText>
                                    )}
                                    {this.props.modalOptions.selectCurrency && this.props.modalOptions.currencies && (
                                        <Row
                                            style={{
                                                flexWrap: "wrap",
                                                paddingTop: 25,
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {this.props.modalOptions.currencies.map((c: Currency) => {
                                                return (
                                                    <PressableBase
                                                        key={c.getId()}
                                                        onPress={() => {
                                                            store.dispatch(selectCurrency(c.getId()));
                                                        }}
                                                        style={{
                                                            justifyContent: "center",
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            paddingTop: 10,
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                opacity:
                                                                    this.props.selectedCurrency == c.getId() ? 1 : 0.5,
                                                                backgroundColor: c.getColor(),
                                                                height: 56,
                                                                width: 56,
                                                                borderRadius: 28,
                                                                marginHorizontal: 8,
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Icon name={c.getIcon()} size={28} color={"white"} />
                                                        </View>
                                                        <BoldText fontSize={12} style={{ marginTop: 5 }} align="center">
                                                            {c.getPName()}
                                                        </BoldText>
                                                    </PressableBase>
                                                );
                                            })}
                                        </Row>
                                    )}
                                </View>
                            )}
                        </Animated.View>
                        <Row>
                            {this.props.modalOptions?.btnTitle && (
                                <RadiusButton
                                    title={this.props.modalOptions.btnTitle}
                                    onPress={this.props.modalOptions.onPress}
                                    disableGradient={true}
                                    style={{
                                        paddingHorizontal: 25,
                                        paddingVertical: 14,
                                        marginRight: 20,
                                        backgroundColor: getColorOpacity(colors.mainButtonsBackground , 1),
                                    }}
                                />
                            )}
                            <CircleButton
                                style={{
                                    height: 45,
                                    width: 45,
                                    backgroundColor: getColorOpacity(colors.mainButtonsBackground, 1),
                                    marginBottom: 0,
                                }}
                                //color={colors.white}
                                iconColor={colors.white}
                                icon={"x-lg"}
                                iconSize={17}
                                onPress={this.onPress}
                            ></CircleButton>
                        </Row>

                        {this.props.modalOptions?.subButtonTitle && (
                            <View style={{ paddingTop: 20 }} accessibilityLabel="modal-button">
                                <RadiusButton
                                    title={this.props.modalOptions.subButtonTitle}
                                    onPress={this.props.modalOptions.onPressSubButton}
                                    disableGradient={true}
                                    style={{
                                        paddingHorizontal: 25,
                                        paddingVertical: 12,
                                        backgroundColor: getColorOpacity(colors.grey, 0.3),
                                    }}
                                />
                            </View>
                        )}
                    </LinearGradient>
                </Animated.View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    background: {
        position: "absolute",

        opacity: 0.8,
        height: "100%",
        width: "100%",
    },
});

const mapStateToProps = (state) => {
    return {
        showModal: state.global.showModal,
        modalOptions: state.global.modalOptions,
        selectedCurrency: state.wallet.selectedCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const Modal = connect(mapStateToProps, mapDispatchToProps)(_Modal);

export default Modal;
