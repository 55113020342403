import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { View, StyleSheet } from "react-native";
import Row from "@base/Row";
import { colors } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import Icon from "@components/icons";
import SemiBoldText from "@base/SemiBold";

interface Props {
    navigation: NavigationType;
    onPress: () => void;
    icon?: string;
    title?: string;
    counter?: string | number;
    seeMore?: boolean;
}

interface State {}

const { t } = i18n;

export default class SectionTitle extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <PressableBase onPress={this.props.onPress}>
                <Row
                    style={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    {this.props.icon?.length > 0 && (
                        <View style={styles.circle}>
                            <RegularText fontSize={14}>{this.props.icon}</RegularText>
                        </View>
                    )}
                    <BoldText fontSize={18} style={{ position: "relative" }}>
                        {this.props.title?.slice(0, 1)}
                        {this.props.title?.toLowerCase()?.slice(1, this.props.title?.length)}
                    </BoldText>
                    <View style={{ flex: 1, justifyContent: "center" }}>
                        {this.props.counter && (
                            <View style={styles.bubble}>
                                <BoldText align="center" fontSize={10} color={colors.labelText || colors.white}>
                                    {this.props.counter}
                                </BoldText>
                            </View>
                        )}
                    </View>
                    {this.props.seeMore && (
                        <SemiBoldText style={{ marginRight: 12 }} color={colors.text} fontSize={14}>
                            {t("see_more")}
                        </SemiBoldText>
                    )}
                </Row>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    bubble: {
        position: "absolute",
        marginLeft: 5,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
    },
    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.white,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // marginTop: -5,
        marginRight: 5,
    },
});
