import CircleButton from "@base/CircleButton";
import { showScanner } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { Image } from "react-native";

interface Props {
    icon: string;
    iconSize?: number;
    navigation: any;
}

export class FooterFavButton extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
    }
    onPress() {
        store.dispatch(showScanner());
    }
    render() {
        return (
            <View style={{ paddingHorizontal: 10 }}>
                
               
                <Image
                    resizeMode="cover"
                    source={require("../assets/images/qr-scan-shadow.png")}
                    style={{position: "absolute", top: -6,  width: 66, height: 66, zIndex: 0 }}
                />
                 <CircleButton
                    style={{ backgroundColor: colors.white, width: 54, height: 54, zIndex: 99999}}
                    iconColor={colors.grey}
                    icon={this.props.icon}
                    onPress={this.onPress}
                ></CircleButton>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    shadow: {
        left: 23,
        top: 7,
        position: "absolute",
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: colors.white,

        shadowOpacity: 1,
        shadowRadius: 8,
    },
});
