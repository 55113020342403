import Card from "@base/Card";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import ImageBase from "@components/image/ImageBase";
import { ActivityModel, ActivityTypes, ReddeemableActivityData } from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { getRedeemable } from "@screens/scanner/implementations/ExperiencesImplementation";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    activity: ActivityModel;
    client: Client;
}

interface State {
    activityData: ReddeemableActivityData;
    isOwner: boolean;
}

const { t } = i18n;

export class _ActivityCardRedeemable extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressCard = this.onPressCard.bind(this);
        this.state = {
            activityData: this.props?.activity?.data,
            isOwner: this.props?.client?._id == this.props.activity?.data?.clientFrom?._id,
        };
    }

    onPressCard() {
        getRedeemable(this.props.navigation, this.state?.activityData?.redeemable?._id);
    }

    render() {
        return (
            <>
                {this.state.activityData && (
                    <>
                        {this.props.activity?.type == ActivityTypes.RedeemableDrop && (
                            <Card
                                onPress={this.onPressCard}
                                icon={
                                    <View>
                                        <AvatarBase
                                            size={45}
                                            uri={this.state.activityData?.redeemable?.merchant?.image?.thumbnail || ""}
                                            alias={this.state.activityData?.redeemable?.merchant?.name || ""}
                                        ></AvatarBase>
                                        <View style={styles.secondaryAvatar}>
                                            <AvatarBase
                                                size={20}
                                                uri={this.props.client?.profileImagePath?.thumbnail}
                                                alias={this.props.client?.alias || ""}
                                            ></AvatarBase>
                                        </View>
                                    </View>
                                }
                                left={
                                    <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                        <RegularText numberOfLines={2} fontSize={14} style={{ marginBottom: 2 }}>
                                            {t("incredible")}{" "}
                                            <SemiBoldText fontSize={14}>
                                                {trimHelper(this.state.activityData?.redeemable?.merchant?.name, 10)}
                                            </SemiBoldText>{" "}
                                            {t("asigned_you")}{" "}
                                            <SemiBoldText fontSize={14}>
                                                {trimHelper(
                                                    this.state.activityData?.redeemable?.redeemableOption?.name,
                                                    16
                                                )}{" "}
                                            </SemiBoldText>
                                            <RegularText fontSize={11}>
                                                <View style={{ width: 28, height: 15 }}>
                                                    <View style={{ position: "absolute", top: -4 }}>
                                                        <LottieBase
                                                            height={24}
                                                            width={24}
                                                            loop={
                                                                moment().diff(
                                                                    moment(this.props.activity?.createdAt),
                                                                    "minutes"
                                                                ) < 5
                                                            }
                                                            source={require("../../../assets/animations/ticket.json")}
                                                        />
                                                    </View>
                                                </View>
                                                {this.state.activityData?.redeemable?.redeemableOption?.type?.toUpperCase()}
                                            </RegularText>
                                            <RegularText fontSize={11} color={colors.grey}>
                                                {"  ("}
                                                {moment(this.props.activity?.createdAt).fromNow()}
                                                {")"}
                                            </RegularText>
                                        </RegularText>
                                    </View>
                                }
                                right={
                                    <View>
                                        <ImageBase
                                            style={{ height: 50, width: 50, borderRadius: 10 }}
                                            uri={
                                                this.state.activityData?.redeemable?.redeemableOption?.image?.thumbnail
                                            }
                                        ></ImageBase>
                                    </View>
                                }
                            ></Card>
                        )}

                        {this.props.activity?.type == ActivityTypes.RedeemableTransfer && (
                            <Card
                                onPress={this.onPressCard}
                                icon={
                                    <View>
                                        <AvatarBase
                                            size={45}
                                            uri={this.state.activityData.clientFrom?.profileImagePath?.thumbnail || ""}
                                            alias={this.state.activityData.clientFrom?.alias || ""}
                                        ></AvatarBase>
                                        <View style={styles.secondaryAvatar}>
                                            <AvatarBase
                                                size={20}
                                                uri={
                                                    this.state.activityData.clientTo?.profileImagePath?.thumbnail || ""
                                                }
                                                alias={this.state.activityData.clientTo?.alias || ""}
                                            ></AvatarBase>
                                        </View>
                                    </View>
                                }
                                left={
                                    <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                        {this.state.isOwner ? (
                                            <RegularText numberOfLines={1} fontSize={14} style={{ marginBottom: 2 }}>
                                                {t("you_send")}
                                                <SemiBoldText fontSize={14}>
                                                    {trimHelper(" @" + this.state.activityData?.clientTo?.alias, 15)}
                                                </SemiBoldText>{" "}
                                            </RegularText>
                                        ) : (
                                            <RegularText numberOfLines={1} fontSize={14} style={{ marginBottom: 2 }}>
                                                {t("you_receive")}
                                                <SemiBoldText fontSize={14}>
                                                    {trimHelper(" @" + this.state.activityData?.clientFrom?.alias, 15)}
                                                </SemiBoldText>{" "}
                                            </RegularText>
                                        )}

                                        <SemiBoldText numberOfLines={1} fontSize={14}>
                                            {trimHelper(
                                                this.state.activityData?.redeemable?.redeemableOption?.name,
                                                15
                                            )}
                                            <View style={{ width: 28, height: 15 }}>
                                                <View style={{ position: "absolute", top: -4 }}>
                                                    <LottieBase
                                                        height={24}
                                                        width={24}
                                                        loop={
                                                            moment().diff(
                                                                moment(this.props.activity?.createdAt),
                                                                "minutes"
                                                            ) < 5
                                                        }
                                                        source={require("../../../assets/animations/ticket.json")}
                                                    />
                                                </View>
                                            </View>
                                            <RegularText fontSize={11}>
                                                {this.state.activityData?.redeemable?.redeemableOption?.type?.toUpperCase()}
                                            </RegularText>
                                            <RegularText fontSize={11} color={colors.grey}>
                                                {"  ("}
                                                {moment(this.props.activity?.createdAt).fromNow()}
                                                {")"}
                                            </RegularText>
                                        </SemiBoldText>
                                    </View>
                                }
                                right={
                                    <View>
                                        <ImageBase
                                            style={{ height: 50, width: 50, borderRadius: 10 }}
                                            uri={
                                                this.state.activityData?.redeemable?.redeemableOption?.image?.thumbnail
                                            }
                                        ></ImageBase>
                                    </View>
                                }
                            ></Card>
                        )}

                        {this.props.activity?.type == ActivityTypes.RedeemableUse && (
                            <Card
                                onPress={this.onPressCard}
                                icon={
                                    <View>
                                        <AvatarBase
                                            size={45}
                                            uri={this.props.client?.profileImagePath?.thumbnail}
                                            alias={this.props.client?.alias || ""}
                                        ></AvatarBase>
                                        <View style={styles.secondaryAvatar}>
                                            <AvatarBase
                                                size={20}
                                                uri={this.state.activityData?.gate?.images[0]?.thumbnail || ""}
                                                alias={this.state.activityData?.gate?.name || ""}
                                            ></AvatarBase>
                                        </View>
                                    </View>
                                }
                                left={
                                    <View style={{ marginLeft: 10, justifyContent: "center", paddingRight: 20 }}>
                                        <RegularText numberOfLines={2} fontSize={14} style={{ marginBottom: 2 }}>
                                            {t("you_used")}
                                            <SemiBoldText fontSize={14}>
                                                {" "}
                                                {trimHelper(
                                                    this.state.activityData?.redeemable?.redeemableOption?.name,
                                                    15
                                                )}{" "}
                                                <RegularText color={colors.grey} fontSize={12}>
                                                    {"("}
                                                    {this.state.activityData?.redeemable?.redeemableOption?.type?.toUpperCase()}
                                                    {")"}
                                                </RegularText>
                                            </SemiBoldText>{" "}
                                            {t("in")}{" "}
                                            <SemiBoldText fontSize={14}>
                                                {trimHelper(this.state.activityData?.gate?.name, 15)}
                                            </SemiBoldText>
                                            <RegularText numberOfLines={1} fontSize={11}>
                                                <View style={{ width: 20, height: 15 }}>
                                                    <View style={{ position: "absolute", top: -7 }}>
                                                        <LottieBase
                                                            height={24}
                                                            width={24}
                                                            loop={
                                                                moment().diff(
                                                                    moment(this.props.activity?.createdAt),
                                                                    "minutes"
                                                                ) < 8
                                                            }
                                                            source={require("../../../assets/animations/fire.json")}
                                                        />
                                                    </View>
                                                </View>

                                                <RegularText fontSize={11} color={colors.grey}>
                                                    {"  ("}
                                                    {moment(this.props.activity?.createdAt).fromNow()}
                                                    {")"}
                                                </RegularText>
                                            </RegularText>
                                        </RegularText>
                                    </View>
                                }
                                right={
                                    <View>
                                        <ImageBase
                                            style={{ height: 50, width: 50, borderRadius: 10 }}
                                            uri={
                                                this.state.activityData?.redeemable?.redeemableOption?.image?.thumbnail
                                            }
                                        ></ImageBase>
                                    </View>
                                }
                            ></Card>
                        )}
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    secondaryAvatar: {
        position: "absolute",
        bottom: -2,
        right: -4,
        padding: 2,
        borderRadius: 20,
        backgroundColor: colors.gradientFrom,
        zIndex: 999999
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityCardRedeemable = connect(mapStateToProps, mapDispatchToProps)(_ActivityCardRedeemable);

export default ActivityCardRedeemable;
