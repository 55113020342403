import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import Wallet from "@core/wallet/Wallet";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "../../i18n/i18n";
import BotCard from "@base/BotCard";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
    fiatCurrency: FiatCurrency;
    currentOperation: any;
}

interface State {
    amount: number;
    sendingTo: string;
    sendingFrom: string;
    fee: number;
    isVerified: boolean;
}

const { t } = i18n;

export class _ApprovingExchangeScreen extends Component<Props, State> {
    private currency: Currency;
    private fiatCurrency: FiatCurrency;
    private swipeVerify: any;

    constructor(props: Props) {
        super(props);

        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.fiatCurrency = this.props.fiatCurrency;
        const skeleton = this.currency.getImplementation().parseSkeleton(this.props.currentOperation.skeleton);
        this.state = {
            amount: skeleton.amount,
            sendingTo: skeleton.sendingTo,
            sendingFrom: skeleton.sendingFrom,
            fee: skeleton.fee,
            isVerified: false,
        };
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("approving")} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    <View>
                        <BotCard
                            style={{ marginTop: 10 }}
                            title={t("warning")}
                            message={t("approving_message")}
                        ></BotCard>
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    card: {},
    swipContainer: {
        flex: 1,
        height: 58,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const ApprovingExchangeScreen = connect(mapStateToProps, mapDispatchToProps)(_ApprovingExchangeScreen);

export default ApprovingExchangeScreen;
