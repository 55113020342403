import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { FlatList, StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { Client } from "@custom-types/Client";
import AvatarBase from "@components/avatar/AvatarBase";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ProfileSections } from "@custom-types/ProfileType";
import { POAPsNavigatorScreens } from "@navigation/POAPsNavigator";
import { SectionTitle } from "../components/SectionTitle";
import { connect } from "react-redux";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    profiles: any;
    containerStyle?: ViewStyle
}

interface State {}

const { t } = i18n;

export class _POAPsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToProfilePOAPs = this.navigateToProfilePOAPs.bind(this);
        this.state = {};
    }

    renderItem = ({ item }) => {
        return (
            <PressableBase onPress={() => this.navigateToPOAP(item.tokenId)} style={{ paddingRight: 10 }}>
                <AvatarBase uri={item?.event?.imageUrl || ""} alias={item?.event?.name} size={80}></AvatarBase>
            </PressableBase>
        );
    };

    navigateToPOAP(id) {
        this.props.navigation.navigate(HomeNavigatorScreens.POAPs.routeName, {
            screen: POAPsNavigatorScreens.POAP.routeName,
            params: { poapID: id },
        });
    }

    navigateToProfilePOAPs() {
        this.props.navigation.navigate(HomeNavigatorScreens.Profile.routeName, {
            screen: ProfileNavigatorScreens.ProfileMain.routeName,
            params: {
                clientID: this.props.client?._id,
                profileSection: ProfileSections.poapsProfile,
            },
        });
    }

    render() {
        //@TODO: Si el cliente no esta seteado no renderizo, revisar
        if (!this.props.client?._id) {
            return <></>;
        }
        return (
            <View>
                {this.props.profiles[this.props.client?._id]?.POAPs?.length > 0 && (
                    <View style={this.props?.containerStyle}>
                        <SectionTitle
                            onPress={this.navigateToProfilePOAPs}
                            title={"POAPs"}
                            icon={"🔖"}
                            counter={this.props.profiles[this.props.client?._id]?.POAPs?.length}
                            {...this.props}
                        ></SectionTitle>

                        <FlatList
                            data={this.props.profiles[this.props.client?._id]?.POAPs}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item.tokenId}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                            initialNumToRender={2}
                            horizontal={true}
                            maxToRenderPerBatch={10}
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        profiles: state.profile.profiles,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const POAPsSection = connect(mapStateToProps, mapDispatchToProps)(_POAPsSection);

export default POAPsSection;
