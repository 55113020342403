import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";

import i18n from "@i18n/i18n";
import { MerchantModel, ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { FlatList, Platform, StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import BoldText from "@base/BoldText";
import Row from "@base/Row";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import AvatarBase from "@components/avatar/AvatarBase";
import ImageBase from "@components/image/ImageBase";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import Icon from "@components/icons";

import { trimHelper } from "@utils/helpers/chat/chat.helper";
import FlatListBase from "@base/FlatListBase";
import ExperienceService from "@core/services/ExperienceService";
import moment from "moment";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { ExperienceModuleType, ModuleControlService } from "@core/services/ModuleControlService";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    client: Client;
    experiences: ExperiencesRedux;
}

interface State {
    merchant: MerchantModel;
}

const { t } = i18n;

export class _MerchantSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.selectMerchant = this.selectMerchant.bind(this);
        this.getData = this.getData.bind(this);
        this.state = {
            merchant: null,
        };
    }

    async componentDidMount() {
        this.setState({ merchant: ExperienceService.getInstance().getMerchant() });
        //this.getData();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (
            this.props.experiences !== prevProps.experiences ||
            this.props.experiences.merchants?.docs?.length !== prevProps.experiences.merchants?.docs?.length
        ) {
            this.setState({ merchant: ExperienceService.getInstance().getMerchant() });
        }
    }

    async getData() {
        if (
            !ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive) &&
            !this.state.merchant
        )
            return;
        ExperienceService.getInstance().getAssets();
        ExperienceService.getInstance().getRedeemables();
        ExperienceService.getInstance().getMemberships();
    }

    selectMerchant(merchant: MerchantModel) {
        if (merchant._id == this.state.merchant?._id) return;
        const m = ExperienceService.getInstance().setMerchant(merchant);
        this.setState({ merchant: m });
        this.getData();
    }

    renderItem = ({ item }) => {
        return (
            <PressableBase
                onPress={() => this.selectMerchant(item)}
                style={{
                    flex: 1,
                    paddingRight: 10,
                    justifyContent: "center",
                    opacity: this.state.merchant?._id == item?._id ? 1 : 0.5,
                }}
            >
                <AvatarBase size={120} uri={item.image?.thumbnail} alias={item.name}></AvatarBase>
                <Row style={{ justifyContent: "center" }}>
                    <BoldText style={{ paddingLeft: 5, paddingTop: 5 }} align="center">
                        {trimHelper(item.name, 16)}
                    </BoldText>
                    <Icon
                        style={{ paddingTop: 5, paddingLeft: 4 }}
                        name={"uniE924"}
                        size={14}
                        color={
                            this.state.merchant?._id == item?._id
                                ? colors.complementary || colors.secondary
                                : colors.text
                        }
                    ></Icon>
                </Row>
            </PressableBase>
        );
    };

    async loadMore() { }

    render() {
        return (
            <View>
                {this.props.experiences?.merchants?.docs?.length > 0 && (
                    <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                        <SectionTitle
                            onPress={null}
                            title={"BRANDs"}
                            icon={"📢"}
                            counter={"YOUR"}
                            {...this.props}
                        ></SectionTitle>

                        <FlatListBase
                            data={this.props.experiences?.merchants?.docs}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                            initialNumToRender={2}
                            horizontal={true}
                            maxToRenderPerBatch={5}
                            showsHorizontalScrollIndicator={false}
                            onEndReachedThreshold={1}
                            onEndReached={this.loadMore}
                        ></FlatListBase>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const MerchantSection = connect(mapStateToProps, mapDispatchToProps)(_MerchantSection);

export default MerchantSection;
