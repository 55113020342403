import CurrencyImplementation from '../../CurrencyImplementation';
import { PathNode } from '@core/utils/PathNode';
import * as bitcoin from 'bitcoinjs-lib';
import { format } from 'date-fns';
import HDNode from 'hdkey';


const BIP84 = require('bip84');
const b58 = require('bs58check');
const bitcoinNetworks = { mainnet:  bitcoin.networks.bitcoin, testnet: bitcoin.networks.testnet }
const bitcoinPubTypes = { mainnet: { zprv: '04b2430c', zpub: '04b24746'}, testnet: { vprv: '045f18bc', vpub: '045f1cf6'} }

function b58Encode(pub, data) {
    let payload = b58.decode(pub)
      , key = payload.slice(4)
  
    return b58.encode(Buffer.concat([Buffer.from(data,'hex'), key]))
}

export default class BTCImplementation extends CurrencyImplementation {

    getSegWitChild(){
        const path = this.currency.getCurrentPath('84');
        const keypath = path.split('/').slice(0,4).join('/').replace("''", "'");
        const account = bitcoin.bip32.fromSeed(this.currency.getSeed(), this.currency.getNetwork()).derivePath(keypath).toBase58()
        return this.currency.isTestnet() ?
                            b58Encode(account, bitcoinPubTypes.testnet.vprv) :
                            b58Encode(account, bitcoinPubTypes.mainnet.zprv)
                            
    }

    generateAddress(addressNode: PathNode, options?: {format?: string}) {
        if(options && options.format == 'SEGWIT') {
            const child0 = this.getSegWitChild();
            const account0 = new BIP84.fromZPrv(child0)
            return account0.getAddress(Number(this.currency.getIndex()));
        }

        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        const keyPair = bitcoin.ECPair.fromPrivateKey(privateKey, {network: this.currency.getNetwork()})
        const {address} =  bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: this.currency.getNetwork() });
        return address;

    }

    getKeys(addressNode: PathNode) {
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        const keyPair = bitcoin.ECPair.fromPrivateKey(privateKey, {network: this.currency.getNetwork()})
        const { pubkey } = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: this.currency.getNetwork() });

        return {publicKey: pubkey.toString('hex'), privateKey: privateKey.toString('hex')};
    }

    async signTransaction(addressNode: PathNode, skeleton: any) {
        const network =  this.currency.isTestnet()?bitcoin.networks.testnet:bitcoin.networks.bitcoin;
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, network.bip32).privateKey;
        const keyPair = bitcoin.ECPair.fromPrivateKey(privateKey, {network:network})

        const child0 = this.getSegWitChild();
        const account0 = new BIP84.fromZPrv(child0);
        const segWitKeyPair = account0.getKeypair(Number(this.currency.getIndex()));
        const p2wpkh = bitcoin.payments.p2wpkh({pubkey: segWitKeyPair.publicKey, network: network})
        const p2sh = bitcoin.payments.p2sh({ redeem: p2wpkh, network });
        const redeemScript = p2sh.redeem.output;
        
        const tx = new bitcoin.TransactionBuilder( network);

        for (let key in skeleton.inputs) {
            const input = skeleton.inputs[key];
            const address = input.address || '';
            if(address.startsWith('bc') || address.startsWith('tb')) {
                tx.addInput(input.txid, input.vout, null, redeemScript);    
            } else {
                tx.addInput(input.txid, input.vout);    
            }
        }
        for (let key in skeleton.outputs) {
            const output = skeleton.outputs[key];
            if(output.address){
                tx.addOutput(output.address,  Math.round(output.value));    
            } else {
                tx.addOutput(skeleton.inputs[0].address, Math.round(output.value));    
            }
        }
        for (let key in skeleton.inputs) {
            const address = skeleton.inputs[key].address || '';
            if(address.startsWith('bc') || address.startsWith('tb')) {
                tx.sign(Number(key),segWitKeyPair,  null, null, skeleton.inputs[key].value); 
            } else {
                tx.sign(Number(key),keyPair); 
            }
        }
        return tx.build().toHex();

    }


    parseTransaction(tx) {
        let ret;
        if(tx.vin[0].addr == this.currency.getAddress()){ //Sent
            ret = {
                type: 1,
                to: tx.vout[0].scriptPubKey.addresses[0],
                amount: tx.vout[0].value
            }
        } else { //Received
            const transactions = tx.vout.filter(t => t.scriptPubKey && t.scriptPubKey.addresses && t.scriptPubKey.addresses.includes(this.currency.getAddress())).map(x => parseFloat(x.value));
            ret = {
                type: 0,
                to: this.currency.getAddress(),
                amount: transactions.reduce((x,y) => x+y)
            }
        }

        return {
            ...ret,
            from: tx.vin[0].addr,
            id: tx.txid,
            confirmations: tx.confirmations,
            fee: tx.fees,
            date: this.parseDate(tx.time),
        }
    }

    parseDate(time) {
        return format(new Date(time*1000), 'MMM dd, yyyy H:mma');
    }

    parseSkeleton(skeleton: any): any {
        const swapAmount = skeleton.extra?skeleton.extra.swapAmount:0;
        return {
            amount: skeleton.inputs[0].amount,
            sendingTo: skeleton.outputs[0].address,
            sendingFrom: skeleton.address,
            fee: this.currency.fromDecimals(skeleton.fee),
            swapAmount
        }
    }

    isValidAddress(address:string){
        try{
            bitcoin.address.toOutputScript(address, this.currency.getNetwork());
            return true;
        } catch(err){
            return false;
        }
    }

    getFormats() {
        return ['SEGWIT','LEGACY'];
    }
}


