import CurrencyImplementation from "../../CurrencyImplementation";
import { PathNode } from "@core/utils/PathNode";
import * as ethUtil from "ethereumjs-util";
import HDNode from "hdkey";
import Web3 from "web3";
import rsk3 from "@rsksmart/rsk3";
import { format } from "date-fns";
import Wallet from "@core/wallet/Wallet";
import Common from "ethereumjs-common";
import store from "@store/index";
const EthereumTx = require("ethereumjs-tx").Transaction;
const ethers = require("ethers");

export default class XOImplementation extends CurrencyImplementation {
    async signMessage(addressNode: PathNode, hexMessage: string) {
        //@TODO assert >> this.currency.getAddress() == account
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let wallet = new ethers.Wallet(privateKey);
        return await wallet.signMessage(hexMessage);
    }

    generateAddress(addressNode: PathNode, options?: { chainId?: number }) {
        // const client = store.getState().auth.client
        // return client.id || '';

        const chainId = options.chainId;
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let address = "0x" + ethUtil.privateToAddress(privateKey).toString("hex");

        return Web3.utils.toChecksumAddress(address);
    }

    async signTransaction(addressNode: PathNode, rawTx: any) {
        return this.signMessage(addressNode, JSON.stringify(rawTx));
    }

    parseTransaction(tx) {
        return {
            type: tx.txfrom == this.currency.getAddress() ? 1 : 0,
            to: tx.txto,
            amount: this.currency.fromDecimals(tx.value),
            from: tx.txfrom,
            id: tx.txhash,
            confirmations: tx.confirmations ? tx.confirmations : 0,
            fee: 0,
            date: this.parseDate(tx.time),
        };
    }

    parseDate(time) {
        return format(new Date(time), "MMM dd, yyyy H:mma");
    }

    parseSkeleton(skeleton) {
        const value = skeleton.value;
        const to = skeleton.to;
        const swapAmount = skeleton.extra ? skeleton.extra.swapAmount : 0;
        return {
            amount: this.currency.fromDecimals(parseInt(value)),
            sendingTo: to,
            sendingFrom: skeleton.from,
            fee: 0,
            swapAmount: swapAmount,
        };
    }

    isValidAddress(address: string) {
        return Web3.utils.isAddress(address.toLowerCase());
    }
}
