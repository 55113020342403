import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready, showPopup, showSnackbar } from "@store/actions/global";
import i18n from "@i18n/i18n";
import {
    AssetModel,
    ExperienceSearchParams,
    MembershipModel,
    MerchantModel,
    RedeemableModel,
    RedeemableType,
} from "@custom-types/ExpercienceModel";
import {
    setAssets,
    setExperiences,
    setMemberships,
    setMerchants,
    setRedeemables,
} from "@store/actions/experiences.actions";
import Wallet from "@core/wallet/Wallet";
import { PathNode } from "@core/utils/PathNode";
import HDNode from "hdkey";
import { IRedeemableSkeleton } from "@screens/experiences/ConfirmTransferRedeemableScreen";
const ethers = require("ethers");

import moment from "moment";
import { ExperienceModuleType, ModuleControlService } from "./ModuleControlService";

const { t } = i18n;

export default class ExperienceService {
    private static instance: ExperienceService;
    private merchant: MembershipModel = null;

    constructor() {}

    static getInstance(): ExperienceService {
        if (!ExperienceService.instance) {
            ExperienceService.instance = new ExperienceService();
        }
        return ExperienceService.instance;
    }

    async getRedeemables(type?: RedeemableType, loadMore?: boolean) {
        try {
            const merchantFilter =
                ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive) &&
                this.merchant !== null
                    ? `&merchant=${this.merchant._id}`
                    : "";
            if (type && loadMore) {
                let data: any = store.getState().experiences[type];

                if (data.paginator?.page < data.paginator?.totalPages) {
                    const url = `${getEnv(
                        "API_URL"
                    )}client/redeemables?redeemableOption.type=${type}${merchantFilter}&__skip=${
                        data.paginator?.page * 10
                    }`;
                    const response = await axios.get(url, {
                        headers: await ApiService.getAuthHeaders(),
                    });

                    store.dispatch(
                        setRedeemables(type, data.docs.concat(response?.data?.docs) || data.docs, {
                            page: response?.data?.page,
                            totalDocs: response?.data?.totalDocs,
                            totalPages: response?.data?.totalPages,
                        })
                    );

                    return response?.data?.docs;
                }

                return;
            }

            if (type) {
                const url = `${getEnv("API_URL")}client/redeemables?redeemableOption.type=${type}${merchantFilter}`;
                const response = await axios.get(url, {
                    headers: await ApiService.getAuthHeaders(),
                });

                store.dispatch(
                    setRedeemables(type, response?.data?.docs || [], {
                        page: response?.data?.page,
                        totalDocs: response?.data?.totalDocs,
                        totalPages: response?.data?.totalPages,
                    })
                );

                return response?.data?.docs;
            }

            // GET FULL

            const redeemableTypes = Object.values(RedeemableType);
            const promise = redeemableTypes?.map(async (rType) => {
                const url = `${getEnv("API_URL")}client/redeemables?redeemableOption.type=${rType}${merchantFilter}`;
                const response = await axios.get(url, {
                    headers: await ApiService.getAuthHeaders(),
                });

                store.dispatch(
                    setRedeemables(rType, response?.data?.docs || [], {
                        page: response?.data?.page,
                        totalDocs: response?.data?.totalDocs,
                        totalPages: response?.data?.totalPages,
                    })
                );
            });
            await Promise.all(promise);
            store.dispatch(ready());
            return;
        } catch (e) {
            store.dispatch(ready());

            console.warn(e);
        }
    }

    async getArchivedRedeemables(type?: RedeemableType) {
        store.dispatch(loading());
        try {
            if (type) {
                const url = `${getEnv(
                    "API_URL"
                )}client/redeemables?redeemableOption.type=${type}&archivedAt__exists=true`;
                const response = await axios.get(url, {
                    headers: await ApiService.getAuthHeaders(),
                });

                store.dispatch(ready());
                return response?.data;
            }
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async getRedeemable(id: string) {
        try {
            const url = `${getEnv("API_URL")}client/redeemables/${id}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data;
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async getGate(id: string) {
        try {
            const url = `${getEnv("API_URL")}client/redeemables/gate/${id}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async useRedeemable(redeemable_id: string, gate_id: string) {
        try {
            const url = `${getEnv("API_URL")}client/redeemables/${redeemable_id}/use/${gate_id}`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );

            return response.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async setFavorite(redeemable: RedeemableModel) {
        try {
            const url = `${getEnv("API_URL")}client/redeemables/${redeemable._id}/favorite`;
            if (redeemable.favorite) {
                const response = await axios.delete(url, {
                    headers: await ApiService.getAuthHeaders(),
                });
                return response.data;
            }
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async setMembershipFavorite(membership: MembershipModel) {
        try {
            const url = `${getEnv("API_URL")}client/memberships/${membership._id}/favorite`;
            if (membership.favorite) {
                const response = await axios.delete(url, {
                    headers: await ApiService.getAuthHeaders(),
                });
                return response.data;
            }
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async archiveRedeemable(redeemable: RedeemableModel) {
        try {
            const url = `${getEnv("API_URL")}client/redeemables/${redeemable._id}/archive`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async unarchiveRedeemable(redeemable: RedeemableModel) {
        try {
            const url = `${getEnv("API_URL")}client/redeemables/${redeemable._id}/archive`;
            const response = await axios.delete(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getTransferDataRedeemable(redeemableId: string, clientId: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}client/redeemables/${redeemableId}/transfer`;
            const body = {
                to: clientId,
            };
            const response = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
            return response.data;
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async confirmRedeemableTransfer(skeleton: IRedeemableSkeleton) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}client/redeemables/${skeleton.id}/transfer/confirm`;
            const sign = await this.signTransaction(skeleton);
            const body = {
                skeleton,
                sign,
            };
            const response = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
            return response.data;
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async signTransaction(skeleton: IRedeemableSkeleton) {
        const seed = Wallet.getInstance().getSeed();
        //const path = `m/44'/60'/0'/0/0`;
        const path = Wallet.getInstance().findCurrencyById("ethereum.mainnet.native.eth").getDefaultPath();
        const bip32 = {
            public: 0x0488b21e,
            private: 0x0488ade4,
        };
        const hdNode = HDNode.fromMasterSeed(seed, bip32);
        const addressNode = new PathNode(path, hdNode.derive(path).toJSON());
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, bip32).privateKey;
        let wallet = new ethers.Wallet(privateKey);
        return await wallet.signMessage(JSON.stringify(skeleton));
    }

    async claimMembership(id?: string) {
        try {
            const url = `${getEnv("API_URL")}client/memberships/${id}/claim`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );
            return response.data;
        } catch (e: any) {
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message:
                        e?.response?.data?.message == "You already have a membership like this"
                            ? t("membership_claim_error")
                            : t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async getMemberships() {
        store.dispatch(loading());
        try {
            const merchantFilter =
                ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive) &&
                this.merchant !== null
                    ? `?merchant=${this.merchant._id}`
                    : "";
            const url = `${getEnv("API_URL")}client/memberships${merchantFilter}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(
                setMemberships(response?.data?.docs || [], {
                    page: response?.data?.page,
                    totalDocs: response?.data?.totalDocs,
                    totalPages: response?.data?.totalPages,
                })
            );
            store.dispatch(ready());
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async getMembership(id: string) {
        try {
            const url = `${getEnv("API_URL")}client/memberships/${id}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async archiveMembership(membership_id: string) {
        try {
            const url = `${getEnv("API_URL")}client/memberships/${membership_id}/archive`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async unarchiveMembeship(membership_id: string) {
        try {
            const url = `${getEnv("API_URL")}client/memberships/${membership_id}/archive`;
            const response = await axios.delete(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getExperiences(loadMore?: boolean) {
        try {
            if (ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive))
                return;
            const today = moment().startOf("day").toISOString();
            const merchantFilter =
                ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive) &&
                this.merchant !== null
                    ? `&merchant=${this.merchant._id}`
                    : "";
            if (loadMore) {
                //@ts-ignore
                let data = store.getState().experiences.experiences;

                if (data.paginator?.page < data.paginator?.totalPages) {
                    const url = `${getEnv(
                        "API_URL"
                    )}experiences?endDate__gte=${today}${merchantFilter}&__sort=-_id&__skip=${
                        data.paginator?.page * 10
                    }`;
                    const response = await axios.get(url, {
                        headers: await ApiService.getAuthHeaders(),
                    });
                    store.dispatch(
                        setExperiences(data.docs.concat(response?.data?.docs), {
                            page: response?.data?.page,
                            totalDocs: response?.data?.totalDocs,
                            totalPages: response?.data?.totalPages,
                        })
                    );
                }
                return;
            }

            const url = `${getEnv("API_URL")}experiences?endDate__gte=${today}${merchantFilter}&__sort=-_id`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(
                setExperiences(response?.data?.docs || [], {
                    page: response?.data?.page,
                    totalDocs: response?.data?.totalDocs,
                    totalPages: response?.data?.totalPages,
                })
            );

            return response.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    searchExperiences = async (params: ExperienceSearchParams) => {
        try {
            const { name, startDate, endDate, merchant } = params;

            let queryParams = "";

            if (name) {
                queryParams += `name__ico=${name}&`;
            }
            if (startDate) {
                queryParams += `startDate__gte=${startDate}&`;
            }
            if (endDate) {
                queryParams += `endDate__gte=${endDate}&`;
            } else {
                const today = moment().startOf("day").toISOString();
                queryParams += `endDate__gte=${today}&`;
            }
            if (merchant) {
                queryParams += `merchant=${merchant}&`;
            }

            const url = `${getEnv("API_URL")}experiences?${queryParams}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    };

    async getExperience(id: string) {
        try {
            const url = `${getEnv("API_URL")}experiences/${id}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async getMerchants(): Promise<MerchantModel[]> {
        try {
            if (!ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive)) {
                return;
            }
            const url = `${getEnv("API_URL")}client/merchants`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            if (response?.data) {
                store.dispatch(
                    setMerchants(response?.data || [], {
                        page: 1,
                        totalDocs: response?.data?.length,
                        totalPages: 1,
                    })
                );
                if (response?.data?.length > 0) {
                    this.setMerchant(response?.data?.[0]);
                }
            }

            return response?.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    setMerchant(merchant) {
        this.merchant = merchant;
        return this.merchant;
    }

    getMerchant() {
        return this.merchant;
    }

    async claimAsset(id?: string) {
        try {
            const url = `${getEnv("API_URL")}client/assets/${id}/claim`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );
            return response.data;
        } catch (e: any) {
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: e?.response?.data?.message || t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async getAssets() {
        try {
            const merchantFilter =
                ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive) &&
                this.merchant !== null
                    ? `?merchant=${this.merchant._id}`
                    : "";
            const url = `${getEnv("API_URL")}client/assets${merchantFilter}`;

            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(
                setAssets(response?.data?.docs || [], {
                    page: response?.data?.page,
                    totalDocs: response?.data?.totalDocs,
                    totalPages: response?.data?.totalPages,
                })
            );
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async getAsset(id: string) {
        try {
            const url = `${getEnv("API_URL")}client/assets/${id}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
        }
    }

    async setAssetFavorite(asset: AssetModel) {
        try {
            const url = `${getEnv("API_URL")}client/assets/${asset._id}/favorite`;
            if (asset.favorite) {
                const response = await axios.delete(url, {
                    headers: await ApiService.getAuthHeaders(),
                });
                return response.data;
            }
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async archiveAsset(asset_id: string) {
        try {
            const url = `${getEnv("API_URL")}client/assets/${asset_id}/archive`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async unarchiveAsset(asset_id: string) {
        try {
            const url = `${getEnv("API_URL")}client/assets/${asset_id}/archive`;
            const response = await axios.delete(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getTransferDataAsset(asset_id: string, clientId: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}client/assets/${asset_id}/transfer`;
            const body = {
                to: clientId,
            };
            const response = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
            return response.data;
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async confirmAssetTransfer(skeleton: IRedeemableSkeleton) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}client/assets/${skeleton.id}/transfer/confirm`;
            const sign = await this.signTransaction(skeleton);
            const body = {
                skeleton,
                sign,
            };
            const response = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
            return response.data;
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async checkout(data: {
        currency: { id: string; type: "fiat" | "digital" };
        gateway: string;
        items: Array<{ id: string; quantity: number }>;
        backUrl?: string;
    }) {
        try {
            const url = `${getEnv("API_URL")}client/orders/redeemables/checkout`;
            const response = await axios.post(
                url,
                {
                    ...data,
                },
                {
                    headers: await ApiService.getAuthHeaders(),
                }
            );

            return response.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }

    async getOrder(orderId: string) {
        try {
            const url = `${getEnv("API_URL")}client/orders/redeemables/${orderId}`;

            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response?.data;
        } catch (e) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
            console.warn(e);
        }
    }
}
