import React, { Component } from "react";
import Row from "@base/Row";
// @ts-ignore
import { NavigationParams, NavigationScreenProp, NavigationState } from "react-navigation";
import { FooterButton } from "@components/footer/FooterButton";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { FooterFavButton } from "./FooterFavButton";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import store from "@store/index";
import { hideSidebar, showSidebar } from "@store/actions/global";
import { CustomFotterButton } from "@custom/CustomFotterButton";

interface Props {
    navigation: NavigationScreenProp<NavigationState, NavigationParams>;
    showSidebar: boolean;
    unread: number;
}

const { t } = i18n;

export class _FooterButtons extends Component<Props> {
    render() {
        return (
            <Row style={{ justifyContent: "space-between", alignItems: "center"}}>
                <FooterButton icon={"house1"} screen={TabsNavigatorScreens.Home.routeName} {...this.props} />

                <CustomFotterButton {...this.props}></CustomFotterButton>

                <FooterFavButton icon={"qr"} {...this.props} />

                {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                    <FooterButton
                        unreadMessages={this.props.unread}
                        icon={"chat1"}
                        screen={TabsNavigatorScreens.Messages.routeName}
                        {...this.props}
                    />
                )}

                <FooterButton
                    icon={"menu"}
                    isCurrent={this.props.showSidebar}
                    onPress={() =>
                        this.props.showSidebar ? store.dispatch(hideSidebar()) : store.dispatch(showSidebar())
                    }
                    {...this.props}
                />
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unread: state.chat.unread,
        loading: state.global.loading,
        showSidebar: state.global.showSidebar,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const FooterButtons = connect(mapStateToProps, mapDispatchToProps)(_FooterButtons);

export default FooterButtons;
