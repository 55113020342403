import Container from "@base/Container";
import FloatingTextInput from "@base/FloatingTextInput";
import InlineButton from "@base/InlineButton";
import PressableBase from "@base/PressableBase";
import AvatarBase from "@components/avatar/AvatarBase";
import { Header } from "@components/header/Header";
import Icon from "@components/icons";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import ProfileService from "@core/services/ProfileService";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { Client } from "@custom-types/Client";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { Route } from "@react-navigation/native";
import { loading, ready, showSnackbar } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import { Camera } from "expo-camera";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import Initializer from "../initializer/Initializer";

interface Props {
    navigation: NavigationType;
    route: Route<string, { alias?: string; avatar?: string; email?: string; handleBackButton?: boolean }>;
}

interface State {
    cloudClient: Client;
    alias: string;
    avatar: string;
    saveImage: boolean;
    email: string;
    options: boolean;
    init: boolean;
}

const { t } = i18n;

export default class SetProfileScreen extends Component<Props, State> {
    oAuthService: OAuthService;

    constructor(props: Props) {
        super(props);
        this.state = {
            cloudClient: null,
            alias: "",
            avatar: "",
            email: "",
            init: false,
            options: false,
            saveImage: false,
        };
        this.oAuthService = OAuthService.getInstance();
        this.onPress = this.onPress.bind(this);
        this.init = this.init.bind(this);
        this.signIn = this.signIn.bind(this);
        this.init();
    }

    async init() {
        store.dispatch(loading());
        const client = await this.oAuthService.getCloudClient();

        this.setState({
            cloudClient: client,
            alias: client.alias,
            avatar: client.avatar,
            email: client.email,
            init: true,
        });
   
        if (client.avatar && !client?.profileImagePath) {
            this.resizeImage(client.avatar);
        }
        store.dispatch(ready());
    }

    selectImage = async (camera?: boolean) => {
        if (Platform.OS !== "web") {
            await Camera.requestCameraPermissionsAsync();
            await ImagePicker.requestMediaLibraryPermissionsAsync();

            let permissionCamera = await Camera.getCameraPermissionsAsync();
            let permissionLibrary = await ImagePicker.getMediaLibraryPermissionsAsync();

            if (
                (permissionCamera?.status !== "granted" && permissionCamera.canAskAgain) ||
                (permissionLibrary.status !== "granted" && permissionLibrary.canAskAgain)
            ) {
                store.dispatch(showSnackbar({ type: "SUCCESS", message: t("camera_permission") }));
                await Camera.requestCameraPermissionsAsync();
            }

            if (
                (permissionCamera?.status !== "granted" && !permissionCamera.canAskAgain) ||
                (permissionLibrary.status !== "granted" && !permissionLibrary.canAskAgain)
            ) {
                store.dispatch(showSnackbar({ type: "SUCCESS", message: t("camera_permission_null") }));
            }
        }

        let pickerResult: any = camera
            ? await ImagePicker.launchCameraAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  allowsEditing: true,
                  base64: false,
                  aspect: [4, 4],
                  quality: 1,
              })
            : await ImagePicker.launchImageLibraryAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  allowsEditing: true,
                  base64: false,
                  aspect: [4, 4],
                  quality: 1,
              });

        this.resizeImage(pickerResult?.assets[0]?.uri);
    };

    async resizeImage(image) {
     
        if (!image) return;

        try {
            store.dispatch(loading());
            const resizeResult = await ImageManipulator.manipulateAsync(image, [{ resize: { width: 480 } }], {
                compress: 0.8,
                format: ImageManipulator.SaveFormat.JPEG,
            });
     

            const cropResult = await ImageManipulator.manipulateAsync(resizeResult.uri, [
                {
                    crop: {
                        height: 480,
                        originX: resizeResult.width !== 480 ? (resizeResult.width - 480) / 2 : 0,
                        originY: resizeResult.height !== 480 ? (resizeResult.width - 480) / 2 : 0,
                        width: 480,
                    },
                },
            ]);

         

            this.setState({ avatar: cropResult.uri, saveImage: true });
            store.dispatch(ready());
        } catch (e) {
            store.dispatch(ready());
        }
    }

    changeInputValue = (value: string, field: string) => {
        if (field === "alias") {
            value = value.replace(" ", "");
        }

        this.setState({
            ...this.state,
            [field]: value,
        });
    };

    async signIn() {
        try {
            if (this.state.cloudClient.getId()) {
                await this.oAuthService.setClient(this.state.cloudClient);
                await this.oAuthService.syncClientOnCloud();
            } else {
                await this.oAuthService.register({
                    alias: this.state.alias,
                    avatar: this.state.avatar,
                });
            }

            if (this.state.avatar?.length > 0 && this.state.saveImage) {
             
                await ProfileService.getInstance().setProfileImage({
                    image: { uri: this.state.avatar },
                });
                this.setState({ saveImage: false });
            }
            Initializer.redirect(this.props.navigation);
        } catch (e: any) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message:
                        e?.response?.data?.message || t("an_error_has_occurred"),
                })
            );
        }
    }

    async onPress() {
        store.dispatch(loading());
        setTimeout(() => {
            this.signIn();
        }, 250);
    }

    async handleBackButton() {
        if (this.props.route?.params?.handleBackButton) {
            await this.oAuthService.signout();
        }
        Initializer.redirect(this.props.navigation);
    }

    render() {
        if (!this.state.init) {
            return <></>;
        }
        return (
            <ScreenWrapper>
                <Header
                    title={t("set_profile")}
                    type={HeaderType.Light}
                    {...this.props}
                    leftBtn={{
                        onPress: () => {
                            this.handleBackButton();
                        },
                        icon: "arrow-left",
                        size: 24,
                    }}
                />

                <Container style={styles.container}>
                    <View style={{ width: "100%" }}>
                        <View style={{ alignSelf: "center" }}>
                            <AvatarBase
                                uri={this.state.avatar}
                                alias={this.state.alias}
                                size={120}
                                accesory={!this.state.options}
                                accesoryIconSize={22}
                                accesoryIiconName={"edit"}
                                onPress={() => this.setState({ options: !this.state.options })}
                            />

                            {this.state.options && (
                                <View
                                    style={{
                                        position: "absolute",
                                        justifyContent: "center",
                                        zIndex: 9999,
                                        bottom: 0,
                                        left: 0,
                                    }}
                                >
                                    <PressableBase onPress={() => this.selectImage(false)} style={{ marginRight: 10 }}>
                                        <Icon
                                            name="images"
                                            size={16}
                                            color={colors.white}
                                            style={[
                                                styles.icon,
                                                {
                                                    backgroundColor:
                                                        this.state.avatar?.length > 0
                                                            ? colors.secondary
                                                            : getColorOpacity(colors.grey, 0.7),
                                                },
                                            ]}
                                        />
                                    </PressableBase>
                                </View>
                            )}
                            {this.state.options && Platform.OS !== "web" && (
                                <View
                                    style={{
                                        position: "absolute",
                                        justifyContent: "center",
                                        zIndex: 9999,
                                        bottom: 0,
                                        right: -5,
                                    }}
                                >
                                    <PressableBase onPress={() => this.selectImage(true)} style={{ marginRight: 10 }}>
                                        <Icon
                                            name="camera"
                                            size={16}
                                            color={colors.white}
                                            style={[
                                                styles.icon,
                                                {
                                                    backgroundColor:
                                                        this.state.avatar?.length > 0
                                                            ? colors.secondary
                                                            : getColorOpacity(colors.grey, 0.7),
                                                },
                                            ]}
                                        />
                                    </PressableBase>
                                </View>
                            )}
                        </View>

                        <View style={{ flex: 1, width: "100%", paddingTop: 30 }}>
                            <FloatingTextInput
                                onChangeText={(text) => this.changeInputValue(text, "alias")}
                                value={this.state.alias}
                                label={t("alias")}
                                multiline={false}
                            />
                            <View style={{ height: 20 }}></View>
                            {this.state.email?.length > 0 && (
                                <FloatingTextInput
                                    onChangeText={(text) => this.changeInputValue(text, "email")}
                                    value={this.state.email}
                                    label={"Email"}
                                    multiline={false}
                                    disable={true}
                                />
                            )}
                        </View>
                    </View>
                    <View style={{ width: "100%" }}>
                        <InlineButton vibrate={true} title={t("next")} onPress={this.onPress}></InlineButton>
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

        alignItems: "center",
        alignContent: "center",
        justifyContent: "space-between",
        paddingBottom: 40,
        paddingTop: 20,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.shadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
    icon: {
        height: 36,
        width: 36,
        overflow: "hidden",
        borderRadius: 18,
        padding: 10,
    },
});
